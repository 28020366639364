import {
  GlobalConfigType,
  setApplicationStep,
  setBorrowerAllData,
  setBorrowerGroupData,
  setGlobalConfig,
  setLoanAllData,
  setLoanDetail,
  setLoanLtvMaxLimit,
  setOverviewStep,
} from 'actions'
import cloneDeep from 'clone-deep'
import { AccountType, LoanFieldProps, SettingKey } from 'config'
import { additionalMemberValidate } from 'pages/LoanApplication/AdditionalMember/logic'
import { assetInfoValidate } from 'pages/LoanApplication/AssetInformation/logics'
import { borrowerInfoValidate } from 'pages/LoanApplication/BorrowerInformation/logic'
import { BorrowerSeperator } from 'pages/LoanApplication/CreditScore/constants'
import { creditValidate } from 'pages/LoanApplication/CreditScore/logic'
import { declarationsHmdaValidate } from 'pages/LoanApplication/DeclarationsHmda/logic'
import { propertyInfoValidate } from 'pages/LoanApplication/PropertyInformation/logic'
import { signAppValidate } from 'pages/LoanApplication/SignApplication/logic'
import { trackRecordValidate } from 'pages/LoanApplication/TrackRecord/logic'
import { getLockExpired, validateLoanStructureData } from 'pages/LoanStructure/Logic'
import { store } from 'reducers'
import { borrowerInitialState } from 'reducers/borrower.reducer'
import { getloanDetailInitialState, LoanType } from 'reducers/loanDetail.reducer'
import { stepInitialState } from 'reducers/step.reducer'
import Api from 'services/api'
import { getAdminConfig, getLoanOverview, getSetting, isVendorEnabled } from 'services/apis'
import { formatDate } from 'utils'
import { setBorrowerDefaultData, setLoanDefaultData } from 'utils/loan'

const checkStatus = (data: any) => {
  let allPass = true
  Object.keys(data).map((key) => {
    if (data[key] === -1) allPass = false
  })
  return allPass
}

export const stepUpgradeWhenRestructureReleaseNoToYes = async () => {
  await store.dispatch(
    setBorrowerGroupData('borrower', {
      signedDate: '',
      agreeToSign: false,
    }),
  )
  const { step } = await store.getState()
  let overview = cloneDeep(step.overview)

  overview.application.detail.signApplication = 0
  if (overview.application.status === 1) {
    overview.application.status = 0
  }

  await store.dispatch(setOverviewStep(overview))

  let application = cloneDeep(step.application)
  application.sign = -1
  await store.dispatch(setApplicationStep(application))

  return true
}

export const loanOverviewAndStepLogic = async (loanNumber: string, menu: string) => {
  return new Promise(async (resolve) => {
    try {
      Api.setLoanNumber(loanNumber)
      let { overview, application } = stepInitialState()

      const loanFieldProperties: LoanFieldProps = await getAdminConfig('loanFieldProperties')
      await store.dispatch(setGlobalConfig(GlobalConfigType.LoanFields, loanFieldProperties))

      if (loanNumber === 'new') {
        let [{ value: loanGlobalSettings }, pricingEngine] = await Promise.all([
          getSetting(SettingKey.GLOBAL_SETTINGS),
          isVendorEnabled('Rtltrader'),
        ])

        loanGlobalSettings = JSON.parse(loanGlobalSettings || '{}')

        await store.dispatch(setOverviewStep(overview))
        await store.dispatch(setApplicationStep(application))

        let loanDetailData = getloanDetailInitialState()
        loanDetailData.loanNumber = 'New'
        loanDetailData.borrowerName = '-'
        loanDetailData.propertyAddress = '-'
        loanDetailData.loanGlobalSettings = loanGlobalSettings
        loanDetailData.pricingEngine = pricingEngine
        await store.dispatch(setLoanDetail(loanDetailData))

        const borrowerData = borrowerInitialState()
        await store.dispatch(setBorrowerAllData(setBorrowerDefaultData(borrowerData, loanFieldProperties)))
        await store.dispatch(setLoanAllData(setLoanDefaultData({}, loanFieldProperties)))
        await store.dispatch(setLoanLtvMaxLimit({}))
        return resolve(true)
      }

      const res = await getLoanOverview()

      const loanData = res.loan
      await store.dispatch(setLoanAllData(setLoanDefaultData(loanData, loanFieldProperties)))
      await store.dispatch(setLoanLtvMaxLimit({}))

      let loanDetailData = getloanDetailInitialState()

      loanDetailData.loanNumber = loanData.no
      loanDetailData.byteproFileName = loanData.byteproFileName
      loanDetailData.entityTitle = res.entityTitle
      loanDetailData.borrowerName = res.borrowerNames
      loanDetailData.propertyAddress = loanData.subjectPropertyAddress
      loanDetailData.includeCoborrower = loanData.includeCoborrower
      loanDetailData.loanType = loanData.type
      loanDetailData.restructureApplicationRelease = res.process.restructureApplicationRelease
      loanDetailData.closingDate = res.process.closingDate
      loanDetailData.emailContacts = res.process.emailContacts
      loanDetailData.loanStatus = res.process.loanStatus
      loanDetailData.servicingStatus = res.process.servicingStatus
      loanDetailData.servicingPipeline = res.process.servicingPipeline
      loanDetailData.submitToAE = res.process.submitToAE
      loanDetailData.submitToLoanSetup = res.process.submitToLoanSetup
      loanDetailData.submitConditions = res.process.submitConditions
      loanDetailData.submitToUnderwriter = res.process.submitToUnderwriter
      loanDetailData.loanProcessDate = formatDate(res.process.loanProcessDate)
      loanDetailData.parties = res.parties
      loanDetailData.closeParties = res.closeParties
      loanDetailData.rateData = res.process.rateData
      loanDetailData.rush = res.process.rush
      loanDetailData.priceAdjustments = res.process.priceAdjustments
      loanDetailData.pushedToYieldeasyAt = res.process.pushedToYieldeasyAt
      loanDetailData.crossCollateralBlanketLimit = res.assetTrack.crossCollateralLimit
      loanDetailData.allocatedLoanAmount = res.assetTrack.allocatedLoanAmount
      loanDetailData.loanGlobalSettings = res.globalSetting
      loanDetailData.pricingEngine = res.pricingEngine
      loanDetailData.isCreatedByBorrower = res.isCreatedByBorrower
      loanDetailData.servicerLoanNumber = res.process.servicerLoanNumber
      loanDetailData.templateNumber = res.templateNumber

      await store.dispatch(setLoanDetail(loanDetailData))

      let borrowerData = res.borrower
      borrowerData.borrower.assets = res.assetTrack.assets
      borrowerData.borrower.liabilities = res.assetTrack.liabilities
      borrowerData.borrower.tracks = res.assetTrack.tracks
      borrowerData.borrower.additionalMembers = res.assetTrack.additionalMembers
      borrowerData.borrower.additionalPropertyInfo = res.assetTrack.additionalPropertyInfo
      borrowerData.borrower.earnestMoney = res.assetTrack.earnestMoney
      borrowerData.borrower.sellerCredit = res.assetTrack.sellerCredit
      borrowerData.borrower.otherCredit = res.assetTrack.otherCredit
      borrowerData.borrower.purchaseCredits = res.assetTrack.purchaseCredits
      await store.dispatch(setBorrowerAllData(setBorrowerDefaultData(borrowerData, loanFieldProperties)))

      // Overview Step Update
      const { auth } = await store.getState()
      // Structure Step
      if (res.process.rateData.rate > 0) {
        overview.structure.detail.rateAndPrice = 1
        overview.structure.detail.lockRate = 0
      }
      if (res.process.rateData.locked) {
        overview.structure.detail.lockRate = 1
        if (getLockExpired({ rateLocked: true, expireDate: res.process.rateData.expireDate })) {
          overview.structure.detail.lockRate = 2
        }
      }
      let overview_structure_status = 1
      Object.keys(overview.structure.detail).map((key) => {
        const detail: any = overview.structure.detail
        if (detail[key] !== 1) {
          overview_structure_status = 0
        }
      })
      overview.structure.status = overview_structure_status

      const anyTimeCanSeeApplicationSubmission = auth.profile.accountType === AccountType.ADMIN

      // Application Step

      // Each Application Steps Logic
      // - Property Info
      application.property = propertyInfoValidate(true) ? 1 : -1
      // - Borrower Info
      application.borrower = borrowerInfoValidate(BorrowerSeperator.BORROWER, [], {}, true) ? 1 : -1
      application.borrower2 = borrowerInfoValidate(BorrowerSeperator.CO_BORROWER, [], {}, true) ? 1 : -1
      application.borrower3 = borrowerInfoValidate(BorrowerSeperator.CO_BORROWER2, [], {}, true) ? 1 : -1
      application.borrower4 = borrowerInfoValidate(BorrowerSeperator.CO_BORROWER3, [], {}, true) ? 1 : -1
      // - Asset Info
      const assetInfoValidation = await assetInfoValidate(false)
      application.asset = assetInfoValidation ? 1 : -1 // res.assetTrack.assets.length > 0 ? 1 : -1
      // - Track Record
      application.track = trackRecordValidate(borrowerData.borrower.hasTrackRecord, borrowerData.borrower.tracks)
        ? 1
        : -1
      // - HMDA Info
      application.hmda = declarationsHmdaValidate(BorrowerSeperator.BORROWER, true, false) ? 1 : -1
      application.hmda2 = declarationsHmdaValidate(BorrowerSeperator.CO_BORROWER, true, false) ? 1 : -1
      application.hmda3 = declarationsHmdaValidate(BorrowerSeperator.CO_BORROWER2, true, false) ? 1 : -1
      application.hmda4 = declarationsHmdaValidate(BorrowerSeperator.CO_BORROWER3, true, false) ? 1 : -1
      // - Credit Info
      application.credit = creditValidate(BorrowerSeperator.BORROWER, false) ? 1 : -1
      application.credit2 = creditValidate(BorrowerSeperator.CO_BORROWER, false) ? 1 : -1
      application.credit3 = creditValidate(BorrowerSeperator.CO_BORROWER2, false) ? 1 : -1
      application.credit4 = creditValidate(BorrowerSeperator.CO_BORROWER3, false) ? 1 : -1
      // - Sign App
      application.sign = signAppValidate(BorrowerSeperator.BORROWER, false) ? 1 : -1
      application.sign2 = signAppValidate(BorrowerSeperator.CO_BORROWER, false) ? 1 : -1
      application.sign3 = signAppValidate(BorrowerSeperator.CO_BORROWER2, false) ? 1 : -1
      application.sign4 = signAppValidate(BorrowerSeperator.CO_BORROWER3, false) ? 1 : -1
      // - Additional Member
      application.additionalMember = additionalMemberValidate(
        borrowerData.borrower.hasAdditionalMember,
        borrowerData.borrower.additionalMembers.length,
      )
        ? 1
        : -1

      // Application Step

      let isCorrespondentLoan = loanData.type == LoanType.CORRESPONDENT

      let applicationDateComplete =
        application.property === 1 &&
        application.borrower === 1 &&
        // application.asset === 1 &&
        application.track === 1 &&
        application.hmda === 1 &&
        application.additionalMember === 1

      let applicationCreditScorePull = application.credit === 1

      let applicationSignApp = application.sign === 1

      if (loanDetailData.includeCoborrower) {
        applicationDateComplete = applicationDateComplete && application.borrower2 === 1 && application.hmda2 === 1
        applicationCreditScorePull = applicationCreditScorePull && application.credit2 === 1
        applicationSignApp = applicationSignApp && application.sign2 === 1
      }

      overview.application.detail.dataComplete = applicationDateComplete ? 1 : -1
      overview.application.detail.creditScorePull = applicationCreditScorePull ? 1 : -1
      overview.application.detail.signApplication = applicationSignApp ? 1 : -1
      if (isCorrespondentLoan) {
        // overview.application.status = -1
        overview.application.detail.creditScorePull = -2
        // overview.application.detail.signApplication = -2
      }

      if (((loanFieldProperties as any)['visibility'] || {})['sign'] === false)
        delete (overview.application.detail as any).signApplication
      if (((loanFieldProperties as any)['visibility'] || {})['credit'] === false)
        delete (overview.application.detail as any).creditScorePull

      const loanStructureErrors: any = validateLoanStructureData(loanData, loanDetailData.rateData)
      overview.structure.errors = loanStructureErrors

      if (loanStructureErrors.length === 0 || application.credit === 1 || anyTimeCanSeeApplicationSubmission) {
        overview.application.status = checkStatus(overview.application.detail) ? 1 : 0
      }

      await store.dispatch(setApplicationStep(application))

      // Submission Step
      // loanSubmission

      overview.submit.detail.submitToAE = res.workflow.submit_loan.enabled
        ? loanDetailData.submitToAE === 1
          ? 1
          : -1
        : -2
      overview.submit.detail.submitSetup = res.workflow.submit_to_loan_setup.enabled
        ? loanDetailData.submitToLoanSetup === 1
          ? 1
          : -1
        : -2
      overview.submit.detail.conditionSubmit = res.workflow.submit_condition.enabled
        ? loanDetailData.submitConditions === 1
          ? 1
          : -1
        : -2
      overview.submit.detail.submitUnderwriter = res.workflow.submit_to_underwriter.enabled
        ? loanDetailData.submitToUnderwriter === 1
          ? 1
          : -1
        : -2

      if (loanStructureErrors.length === 0 || anyTimeCanSeeApplicationSubmission) {
        overview.submit.status = checkStatus(overview.submit.detail) ? 1 : 0
      }

      await store.dispatch(setOverviewStep(overview))

      if (menu === 'application') {
        if (overview.application.status === -1) {
          return resolve({
            success: false,
            message: 'Redirect Overview',
          })
        }
      }

      if (menu === 'submit') {
        if (overview.submit.status === -1) {
          return resolve({
            success: false,
            message: 'Redirect Overview',
          })
        }
      }

      resolve({
        success: true,
      })
    } catch (error) {
      console.log('track loanOverviewAndStepLogic Error', error)
      resolve({
        success: false,
        message: 'Redirect Pipeline',
      })
    }
  })
}

export const loanStructureStepUpgrade = async (key: string, status: number) => {
  const { step } = await store.getState()
  let overview = cloneDeep(step.overview)
  overview.structure.detail[key] = status
  if (key === 'rateAndPrice') {
    if (status === 1) {
      overview.structure.detail.lockRate = 0
    }
    if (status === 0) {
      overview.structure.detail.lockRate = -1
    }
  }
  if (key === 'lockRate') {
    if (status === 1) {
      overview.structure.status = 1
    } else {
      overview.structure.status = 0
    }
  }
  await store.dispatch(setOverviewStep(overview))
  return true
}
