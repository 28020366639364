import {
  ArrowDownTrayIcon,
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
  BarsArrowDownIcon,
  BarsArrowUpIcon,
  BookOpenIcon,
  CloudArrowUpIcon,
  EyeIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { SourceBox, TargetBox } from 'components/DragDrop'
import { LayoutLoading } from 'components/LayoutLoading'
import { usePermissions } from 'hooks/usePermissions'
import { NewDocumentsTable } from 'pages/LoanSubmission/NewDocumentsTable'
import type { LoanSubmissionDocument } from 'pages/LoanSubmission/types'
import { useEffect, useMemo, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend, NativeTypes } from 'react-dnd-html5-backend'
import { useSelector } from 'react-redux'
import {
  downloadS3Document,
  getLoanSubmissionDocuments,
  openS3Document,
  openS3Documents,
  updateLoanSubmission,
  uploadFiles,
} from 'services'
import { Checkbox, DocumentFile, Input2 } from 'stories/components'
import type { CategoryDocument } from 'stories/components/CategoryFileTable/CategoryFileTable'
import { formatTime } from 'utils'
import { setLoanNumber } from 'utils/setLoanNumber'

export const DocumentsSlideNameType = ({
  loading: _loading,
  documents: _documents,
  defaultShow = false,
  position = 'left',
  loadDocs = false,
}: {
  loading: boolean
  documents?: CategoryDocument[]
  defaultShow?: boolean
  position?: 'left' | 'right'
  loadDocs?: boolean
}) => {
  const [documents, setDocuments] = useState<CategoryDocument[]>([])
  const [isShowDocuments, setShowDocuments] = useState(defaultShow)
  const [searchQuery, setSearchQuery] = useState('')
  const [selected, setSelected] = useState<Record<string, boolean>>({})
  const [sortKey, setSortKey] = useState('')
  const [sortDir, setSortDir] = useState(true)
  const [loading, setLoading] = useState(false)
  const [uploadingFiles, setUploadingFiles] = useState<DocumentFile[]>([])

  const { hasPermission } = usePermissions()
  const profile = useSelector((state: any) => state.auth.profile)
  setLoanNumber()

  useEffect(() => {
    if (loadDocs) {
      getLoanSubmissionDocuments().then((res) => {
        if (res.success) setDocuments(res.data)
      })
    } else {
      if (_documents) setDocuments(_documents)
    }
  }, [_documents, loadDocs])

  const sortedDocuments = useMemo(() => {
    const query = searchQuery.trim().toLowerCase()
    let docs = documents
      .filter((doc: any) => {
        if (!query) return true
        return (
          doc.name.toLowerCase().includes(query) ||
          doc.category.toLowerCase().includes(query) ||
          doc.status.toLowerCase().includes(query)
        )
      })
      .sort((a: any, b: any) => {
        const aV = a[sortKey]
        const bV = b[sortKey]
        if (aV > bV) return 1
        if (aV == bV) return 0
        return -1
      })
    if (sortDir) docs = docs.reverse()
    return docs
  }, [documents, sortDir, sortKey, searchQuery])

  const isSelectedBtnDisabled = useMemo(() => Object.keys(selected).length == 0, [selected])

  const onShowDocuments = () => {
    setShowDocuments(!isShowDocuments)
  }

  const onSelected = (key: string, value: boolean) => {
    const newData = cloneDeep(selected)
    if (!value) delete newData[key]
    else newData[key] = value
    setSelected(newData)
  }

  const onSelectAll = () => {
    const count = documents.length
    const selCount = Object.keys(selected).length
    let newData = cloneDeep(selected)
    if (count != selCount) documents.forEach((doc: CategoryDocument) => (newData[doc.fileKey] = true))
    else newData = {}
    setSelected(newData)
  }

  const onSort = (key: string) => {
    setSortKey(key)
    if (sortKey == key) setSortDir(!sortDir)
  }

  const onDownload = () => {
    const params = location.pathname.split('/')
    const loanNumber = Number(params[2])

    const filename = `Documents-${loanNumber}`
    const fileKeys = Object.keys(selected).filter((fileKey) => documents.find((item) => item.fileKey === fileKey))
    openS3Documents(loanNumber, filename, fileKeys)
  }

  const onUploadFiles = async (files: File[]) => {
    setLoading(true)
    const loanNumber = setLoanNumber()
    const uploadData = {
      path: `loan/${loanNumber}/document`,
    }
    const keys: string[] = await uploadFiles(uploadData, files)
    const now = Date.now()

    setLoading(false)
    const newFiles = cloneDeep(uploadingFiles)
    newFiles.push(
      ...files.map((file, index) => ({
        id: now + index,
        name: file.name,
        fileKey: keys[index],
        status: 'Not Reviewed',
        category: '',
        createdAt: now,
      })),
    )
    setUploadingFiles(newFiles)
  }

  const onAddDocuments = async (docs: DocumentFile[]) => {
    const newDocs = cloneDeep(documents)
    const addingDocs: LoanSubmissionDocument[] = docs.map((doc) => ({
      conditionNo: 0,
      name: doc.name,
      key: doc.fileKey,
      status: doc.status,
      category: doc.category,
      createdAt: doc.createdAt,
      generated: false,
      createdBy: profile.email,
    }))

    newDocs.push(...docs)
    setDocuments(newDocs)
    setLoading(true)
    await updateLoanSubmission({}, { update: addingDocs })
    setLoading(false)
  }

  const renderHeader = (key: string, title: any) => {
    return (
      <div className="flex flex-wrap font-normal items-center justify-center">
        <p>{title}</p>

        <span className="ml-2">
          {sortKey == key &&
            (sortDir ? <BarsArrowUpIcon className="w-3 h-3" /> : <BarsArrowDownIcon className="w-3 h-3" />)}
        </span>
      </div>
    )
  }

  const renderRow = (document: CategoryDocument, index: number) => {
    return [
      <SourceBox item={document} type="document" key={document.fileKey}>
        <tr className={`border-b ${index % 2 ? 'bg-gray-50' : ''}`} key={`first-${document.fileKey}`}>
          <td className="px-3 py-2">
            <Checkbox
              id={`doc-no-${document.fileKey}`}
              title={`${document.name}`}
              checked={!!selected[document.fileKey]}
              onChange={(value) => onSelected(document.fileKey, value)}
            />
            <span className="italic text-[13px]">- {document.category || 'n/a'}</span>
            <span className="text-[13px] ml-2">({document.status || ''})</span>
          </td>
          <td className="px-2 py-1">
            <span className="italic text-[13.5px]">{formatTime(document.createdAt)}</span>
          </td>
          <td className="px-2 py-1 text-center">
            <button
              className="p-1 cursor-pointer hover-shadow1 rounded text-shade-blue"
              onClick={() => openS3Document(document.fileKey)}
            >
              <EyeIcon className="w-4 h-4" />
            </button>
            <button
              className="p-1 cursor-pointer hover-shadow1 rounded"
              onClick={() => downloadS3Document(document.fileKey, document.name)}
            >
              <ArrowDownTrayIcon className="w-4 h-4" />
            </button>
          </td>
        </tr>
      </SourceBox>,
    ]
  }

  const hasAdminPermission = hasPermission('ADMIN_TO_AE_PROFILE_PERMISSION')

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={`fixed ${position}-0 top-[calc(20%+65px)] z-40`}>
        <button
          className={`p-3 rounded-${
            position == 'left' ? 'l' : 'r'
          }-none ring-[1px] ring-shade-blue bg-shade-blue text-white border hover:bg-white border-shade-blue hover:text-shade-blue rounded flex items-center gap-2 border-l-0`}
          onClick={onShowDocuments}
        >
          <BookOpenIcon className="w-6 h-6" /> Documents
        </button>
      </div>
      {isShowDocuments && (
        <div
          className={`shadow1 fixed bg-white border rounded-${
            position == 'left' ? 'r' : 'l'
          } z-50 ${position}-0 top-[5vh] bottom-0 transition-[${position}] w-[30rem] h-[90vh] overflow-auto ease-in-out ${
            isShowDocuments ? `${position}-0` : `-${position}-[30rem]`
          }`}
        >
          <div className="w-[30rem] fixed z-20 rounded-t flex justify-between p-3 font-bold text-sm place-items-center items-center text-shade-blue border-b bg-white gap-4">
            <p className="text-lg text-gray-800 flex-1">Documents</p>

            <div className="flex items-center">
              <button
                className={`${isSelectedBtnDisabled ? 'text-blue-400' : 'hover:underline'} mr-4`}
                onClick={() => onDownload()}
                disabled={isSelectedBtnDisabled}
              >
                <div className="flex">
                  <p className="mr-1 font-bold">Selected</p> <ArrowDownTrayIcon className="w-5 h-5" />
                </div>
              </button>
              <Checkbox
                title={`${Object.keys(selected).length}/${documents.length}`}
                id="documentSelected"
                checked={Object.keys(selected).length == documents.length}
                onClick={onSelectAll}
                disabled={documents.length == 0}
              />
            </div>

            <button className="hover:underline p-1 hover-shadow1 rounded" onClick={onShowDocuments}>
              {position === 'right' ? (
                <ArrowRightCircleIcon className="w-5 h-5" />
              ) : (
                <ArrowLeftCircleIcon className="w-5 h-5" />
              )}
            </button>
          </div>
          <div className="relative w-full pt-14 h-fit overflow-auto">
            <LayoutLoading show={loading || _loading} />
            <NewDocumentsTable
              files={uploadingFiles}
              setFiles={setUploadingFiles}
              onSubmit={async (files: DocumentFile[]) => {
                await onAddDocuments(files)
                setUploadingFiles([])
              }}
              onCancel={() => setUploadingFiles([])}
            />

            {!uploadingFiles.length && (
              <div className="p-1 relative">
                {hasAdminPermission && (
                  <div className="absolute top-0 right-[calc(50%-135px)] w-full h-fit">
                    <div className="flex flex-wrap items-center absolute top-0 right-0 border-dashed border-2 border-gray-300 px-4 py-8 text-gray-300 w-[270px] text-center">
                      <CloudArrowUpIcon className="w-10 h-10 mx-auto" />
                      <span>Drag and drop files.</span>
                    </div>
                  </div>
                )}
                <TargetBox
                  canDrop={(data: any) => {
                    return hasAdminPermission && !!data.files
                  }}
                  types={['document', NativeTypes.FILE]}
                  onDrop={({ files }: { files: File[] }) => onUploadFiles(files)}
                >
                  <div className="w-full p-3">
                    <Input2
                      type="search"
                      title="Search"
                      hasIcon
                      transparent
                      icon={<MagnifyingGlassIcon className="w-5 h-5 text-gray-500 dark:text-gray-400" />}
                      value={searchQuery}
                      onChange={(value) => setSearchQuery(value)}
                    />
                  </div>
                  <div className="relative overflow-y-auto h-[calc(90vh-150px)]">
                    <table className="w-full font-normal text-sm text-left text-black relative">
                      <thead className="border-b text-center bg-black/5">
                        <tr>
                          <th
                            className="px-2 py-1 border-r border-slate-300 cursor-pointer text-center"
                            onClick={() => onSort('name')}
                          >
                            {renderHeader('name', 'Name')}
                          </th>
                          <th
                            className="px-2 py-1 border-r border-slate-300 cursor-pointer w-32"
                            onClick={() => onSort('createdAt')}
                          >
                            {renderHeader('createdAt', 'Uploaded Date')}
                          </th>
                          <th className="px-2 py-1 font-normal">Action</th>
                        </tr>
                      </thead>

                      <tbody className="text-gray-900">
                        {sortedDocuments.map((document: any, index: number) => renderRow(document, index))}
                      </tbody>
                    </table>
                  </div>
                </TargetBox>
              </div>
            )}
          </div>
        </div>
      )}
    </DndProvider>
  )
}
