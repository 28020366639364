import { useSelector } from 'react-redux'
import { BorrowerHeader, Footer, Header } from 'stories/layouts'
import { InvestorHeader } from 'stories/layouts/InvestorHeader'

export const BaseLayout = (props: any) => {
  const { children } = props
  const auth = useSelector((state: any) => state.auth)
  const { isBorrower, isInvestor } = auth.profile
  return (
    <div className="relative bg-white overflow-hidden">
      <div className={`relative lg:w-full lg:px-2 sm:px-1 bg-white flex flex-col min-h-screen`}>
        {isBorrower ? <BorrowerHeader /> : isInvestor ? <InvestorHeader /> : <Header />}
        <main className="flex-1 w-full relative mx-auto main">{children}</main>
        <Footer />
      </div>
    </div>
  )
}
