import { ArrowDownTrayIcon, ArrowLeftIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { InputType } from 'config'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { getTrustLedgerAfx, openS3Document, submitTrustLedgerAfx } from 'services'
import { Button, ButtonGroup, Modal, PlainTable } from 'stories/components'
import { formatTime, InputConvert, InputValidate, thousandSeperatorWithPrefix } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { afxDefaultInputs } from './constants'
import { AfxStatus, IAfxData } from './type'

export const AfxItem = ({
  data: _data,
  setLoading,
  onBack,
}: {
  data: IAfxData
  setLoading: Function
  onBack: Function
}) => {
  const { loan, loanDetail, borrower } = useSelector((state: any) => ({
    loan: state.loan,
    loanDetail: state.loanDetail,
    borrower: state.borrower.borrower,
  }))

  const [data, setData] = useState<IAfxData>(_data)
  const [inputs, setInputs] = useState<Record<string, InputType>>(afxDefaultInputs())
  const [fieldValueChanged, setFieldValueChanged] = useState(false)
  const [propertyType, setPropertyType] = useState('')
  const { status, history } = data
  const [needReload, setNeedReload] = useState(false)

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)

    newInputs[key].value = value
    newInputs[key].error = InputValidate(newInputs[key])

    setInputs(newInputs)
    setFieldValueChanged(true)
  }

  const onSubmit = () => {
    if (!data.status && !propertyType) {
      toast('Please select Commercial/Residential', { type: 'warning' })
      return
    }

    setLoading(true)
    setNeedReload(true)
    if (!data.status) {
      submitTrustLedgerAfx(0, { propertyType })
        .then((data) => {
          if (data.error) {
            toast(data.error, { type: 'error' })
            return
          }
          setData(data)
        })
        .finally(() => setLoading(false))
      return
    }

    const submitData = {
      message: inputs['message'].value,
      files: inputs['files'].value,
    }
    submitTrustLedgerAfx(data.id, submitData)
      .then((data) => {
        if (data.error) {
          toast(data.error, { type: 'error' })
          return
        }
        setData(data)
        setInputs(afxDefaultInputs())
        setFieldValueChanged(false)
      })
      .finally(() => setLoading(false))
  }

  const renderInitialForm = () => {
    const tableData = [
      ['Loan Number', loanDetail.byteproFileName || loanDetail.loanNumber],
      ['Borrower Name', `${borrower.firstName} ${borrower.lastName}`],
      ['Email', borrower.email],
      ['Phone', borrower.phone],
      ['Address', loanDetail.propertyAddress],
      [
        'Property Type',
        <div className="flex items-center gap-2">
          {loan.propertyType}
          <ButtonGroup title={['Commercial', 'Residential']} value={propertyType} onChange={setPropertyType} />
        </div>,
      ],
      ['Number of Units', loan.numberOfUnits],
    ]
    return (
      <>
        <PlainTable header={['Type', 'Value']} data={tableData} />
        <div className="input md:col-span-1 mt-2 text-center">
          <Button onClick={onSubmit}>Submit</Button>
        </div>
      </>
    )
  }

  const renderHistory = () => {
    const historyData: any[] = history
      .map((item) => {
        switch (item.event_name) {
          case 'property.add':
            return ['Submit Order', '', '', formatTime(item.webhook_sent_at)]

          case 'property.status.update':
            if ('afx_property_id' in item.data)
              return [
                'Status Updates',
                'Afx',
                `${item.data.status} [${thousandSeperatorWithPrefix(item.data.price, '$')}]`,
                formatTime(item.webhook_sent_at),
              ]
            else return ['Status Updates', '', item.data.status, formatTime(item.webhook_sent_at)]

          case 'property.document.upload':
            if ('afx_property_id' in item.data)
              return [
                'Document Upload',
                'Afx',
                <a href={item.data.public_url_to_file} target="_blank" className="btn-link flex items-center gap-2">
                  <ArrowDownTrayIcon className="w-4 h-4" />
                  {item.data.file_name}
                </a>,
                formatTime(item.webhook_sent_at),
              ]
            else {
              const { file_key } = item.data
              return [
                'Document Upload',
                '',
                <span onClick={() => openS3Document(file_key)} className="btn-link flex items-center gap-2">
                  <ArrowDownTrayIcon className="w-4 h-4" />
                  {item.data.file_name}
                </span>,
                formatTime(item.webhook_sent_at),
              ]
            }

          case 'property.message.add':
            if ('afx_property_id' in item.data)
              return [
                'New Message',
                'Afx',
                <p className="w-80 break-words">{item.data.message}</p>,
                formatTime(item.webhook_sent_at),
              ]
            else
              return [
                'New Message',
                '',
                <p className="w-80 break-words">{item.data.message}</p>,
                formatTime(item.webhook_sent_at),
              ]

          case 'property.product.update':
            return [
              'Product Updates',
              'Afx',
              `${item.data.property_type} [${thousandSeperatorWithPrefix(item.data.price, '$')}]`,
              formatTime(item.webhook_sent_at),
            ]
        }
      })
      .filter((v) => !!v)
      .map((row, index) => [index + 1, ...(row || [])])

    return (
      <div>
        <span className="font-bold text-sm">Histories</span>
        <PlainTable header={['No', 'Type', 'By', 'Value', 'At']} data={historyData} tdClass="px-3 py-2 text-sm" />
      </div>
    )
  }

  const renderSubmitForm = () => {
    return (
      <div className="mb-4">
        <p className="text-sm font-bold mb-2">Order Id: {data.id}</p>
        <p className="text-sm font-bold mb-2">Status: {data.status}</p>
        {Object.keys(inputs).map((key, index) => {
          const input: any = cloneDeep(inputs[key])
          if (input.visible === false) return null
          return (
            <div className={`input md:col-span-${input.span || 1}`} key={index}>
              <RenderInput input={input} Key={key} onChange={onChange} />
            </div>
          )
        })}
        <div className="input md:col-span-1 mt-2 text-center">
          <Button disabled={!fieldValueChanged} onClick={onSubmit}>
            Submit
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div>
      <p
        className="text-shade-blue hover:underline cursor-pointer flex items-center gap-2 mb-4"
        onClick={() => onBack(needReload)}
      >
        <ArrowLeftIcon className="w-4 h-4" />
        Back
      </p>
      {!status && renderInitialForm()}
      {status && (
        <>
          {![AfxStatus.Cancelled, AfxStatus.OrderComplete].includes(status) && renderSubmitForm()}
          {renderHistory()}
        </>
      )}
    </div>
  )
}

export const AfxModal = ({ onClose }: { onClose: Function }) => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<IAfxData[]>([])
  const [orderIndex, setOrderIndex] = useState<number | null>(null)

  useEffect(() => {
    reloadData()
  }, [])

  const reloadData = () => {
    setLoading(true)
    getTrustLedgerAfx()
      .then(setData)
      .finally(() => setLoading(false))
  }

  const onSubmit = () => {
    setOrderIndex(-1)
  }

  const renderList = () => {
    const tableData = data.map((item, index) => [
      index + 1,
      item.id,
      item.status,
      formatTime(item.createdAt),
      <div>
        <span className="btn-link" onClick={() => setOrderIndex(index)}>
          Details
        </span>
      </div>,
    ])

    return (
      <div>
        <PlainTable header={['No', 'Order Id', 'Status', 'Created At', 'Action']} data={tableData} />
        <div className="input md:col-span-1 mt-2 text-center">
          <Button onClick={onSubmit}>Submit New Order</Button>
        </div>
      </div>
    )
  }

  return (
    <Modal title="AFX Research" isOpen titleOkay="" loading={loading} onClose={() => onClose(data)}>
      <div className="w-196">
        {orderIndex === null && renderList()}
        {orderIndex !== null && (
          <AfxItem
            data={orderIndex == -1 ? ({} as any) : data[orderIndex]}
            setLoading={setLoading}
            onBack={(needReload: boolean) => {
              needReload && reloadData()
              setOrderIndex(null)
            }}
          />
        )}
      </div>
    </Modal>
  )
}
