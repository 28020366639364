import { useState } from 'react'

import { TemporaryForgetPwd } from './ForgetPassword'
import { TemporaryLogin } from './Login'
import { TemporaryResetPwd } from './ResetPassword'

enum ScreenType {
  Login,
  ForgetPassword,
  ResetPassword,
}

export function TemporaryAuth({
  token,
  onSigned: _onSigned,
  isModal = false,
  temporaryUserEmail = '',
}: {
  token: string
  onSigned: Function
  isModal?: boolean
  temporaryUserEmail?: string
}) {
  const [screenType, setScreenType] = useState<ScreenType>(ScreenType.Login)
  const [newToken, setNewToken] = useState('')

  const onSigned = (newToken: string, isRequireResetPwd: boolean) => {
    if (!isRequireResetPwd) {
      _onSigned(newToken)
      return
    }
    setNewToken(newToken)
    setScreenType(ScreenType.ResetPassword)
  }

  if (screenType == ScreenType.Login)
    return (
      <TemporaryLogin
        token={token}
        onSigned={onSigned}
        onForgotPwd={() => setScreenType(ScreenType.ForgetPassword)}
        isModal={isModal}
        temporaryUserEmail={temporaryUserEmail}
      />
    )

  if (screenType == ScreenType.ResetPassword)
    return <TemporaryResetPwd token={newToken} onSubmit={() => _onSigned(newToken)} isModal={isModal} />

  return <TemporaryForgetPwd token={token} onSubmit={() => setScreenType(ScreenType.Login)} isModal={isModal} />
}
