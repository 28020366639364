import { companyName } from 'config'
import { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { getCharFromIndex } from 'utils'
import { useTitle } from 'utils/pageTitle'

import { CredentialConfig } from './CredentialConfig'
import { DataVerifySSA } from './DataVerifySSA'
import { NationalFloodTab } from './NationalFlood'

type menuType = {
  [key: string]: string //fix this
}

const leftMenuItems: menuType = {
  creditReports: 'Credit Report (CIC Credit)',
  xactus: 'Credit Report (Xactus360)',
  dataverifySSA: 'Dataverify SSA',
  dataverifyTAX: 'Dataverify 4506',
  nationalFlood: 'ServiceLink National Flood',
  rtltrader: 'RTL Trader',
  bytepro: 'Bytepro',
  appraisalNation: 'Appraisal Nation',
  vsamc: 'Valuation Services AMC (VSAMC)',
  nationWide: 'Nationwide Appraisal Network (NAN)',
  naan: 'Nationwide AMC (NAAN)',
  valutrac: 'Elite Valuation',
  dataverify: 'Data Verify',
  serviceLink: 'Service Link',
  docgen: 'Docgen',
  trinity: 'Trinity',
  stripe: 'Stripe',
  plaid: 'Plaid',
  snappt: 'Snappt',
  usps: 'USPS',
  lexisnexis: 'SmartLinx Person Report',
  obie: 'Obie',
  sitexPro: 'SitexPro',
  fci: 'FCI',
  afx: 'Afx',
}

export * from './NationalFlood/NationalFlood'

export default function Vendors() {
  useTitle(`Vendors - ${companyName}`)

  const navigate = useHistory()
  const [selectedMenu, setSelectedMenu] = useState('')

  const setMenu = (menuItem: string) => {
    setSelectedMenu(menuItem)
    navigate.push(`/vendors/${menuItem}`)
  }

  const renderFragment = useMemo(() => {
    switch (selectedMenu) {
      case 'creditReports':
        return <CredentialConfig type="creditReport" title={leftMenuItems['creditReports']} key="creditReport" />
      case 'dataverifySSA':
        return <DataVerifySSA type="ssa" key="ssa" />
      case 'dataverifyTAX':
        return <DataVerifySSA type="tax" key="tax" />
      case 'nationalFlood':
        return <NationalFloodTab />
      case 'rtltrader':
        return <CredentialConfig type="rtltrader" title="RTLTrader" key="rtltrader" />
      case 'bytepro':
        return <CredentialConfig type="bytepro" title="Bytepro" key="bytepro" />
      case 'appraisalNation':
        return <CredentialConfig type="appraisalNation" title="Appraisal Nation" key="appraisalNation" />
      case 'vsamc':
        return <CredentialConfig type="vsamc" title={leftMenuItems['vsamc']} key="vsamc" />
      case 'nationWide':
        return <CredentialConfig type="nationWide" title="Nationwide Appraisal Network (NAN)" key="nationWide" />
      case 'naan':
        return <CredentialConfig type="naan" title={leftMenuItems['naan']} key="naan" />
      case 'valutrac':
        return <CredentialConfig type="valutrac" title="Elite Valuation" key="valutrac" />
      case 'dataverify':
        return <CredentialConfig type="dataverify" title="Data Verify" key="dataverify" />
      case 'serviceLink':
        return <CredentialConfig type="serviceLink" title="Service Link" key="serviceLink" />
      case 'docgen':
        return <CredentialConfig type="docgen" title="Docgen" key="docgen" />
      case 'trinity':
        return <CredentialConfig type="trinity" title="Trinity" key="trinity" />
      case 'stripe':
        return <CredentialConfig type="stripe" title="Stripe" key="stripe" />
      case 'plaid':
        return <CredentialConfig type="plaid" title="Plaid" key="plaid" />
      case 'snappt':
        return <CredentialConfig type="snappt" title="Snappt for Bank Statement" key="snappt" />
      case 'usps':
        return <CredentialConfig type="usps" title="USPS" key="usps" />
      case 'xactus':
        return <CredentialConfig type="xactus" title={leftMenuItems['xactus']} key="xactus" />
      case 'lexisnexis':
        return <CredentialConfig type="lexisnexis" title={leftMenuItems['lexisnexis']} key="lexisnexis" />
      case 'obie':
        return <CredentialConfig type="obie" title="Obie" key="obie" />
      case 'sitexPro':
        return <CredentialConfig type="sitexPro" title="SitexPro" key="sitexPro" />
      case 'fci':
        return <CredentialConfig type="fci" title={leftMenuItems['fci']} key="fci" />
      case 'afx':
        return <CredentialConfig type="afx" title={leftMenuItems['afx']} key="afx" />
      default: {
        return <div>Comming Soon...</div>
      }
    }
  }, [selectedMenu])

  useEffect(() => {
    setMenu(Object.keys(leftMenuItems)[0])
  }, [])

  return (
    <div className="vendors-container py-6">
      <div className="vendors-wrapper max-w-screen-2xl m-auto grid grid-cols-12 gap-6 px-2">
        <div className="sidebar-left col-span-12 md:col-span-3 shrink-0 bg-white shadow1 rounded">
          <ul className="sidebar-items flex flex-col p-4 pb-20">
            {Object.keys(leftMenuItems).map((item: string, index) => {
              return (
                <li
                  key={index}
                  onClick={() => {
                    setMenu(item)
                  }}
                  className="border-b py-2"
                >
                  <p
                    className={`hover:underline cursor-pointer ${
                      selectedMenu === item ? 'border px-4 py-1 bg-zinc-100' : 'py-1'
                    }`}
                  >
                    {getCharFromIndex(index)}. {leftMenuItems[item as keyof typeof leftMenuItems]}
                  </p>
                </li>
              )
            })}
          </ul>
        </div>
        <div className="content-right col-span-12 md:col-span-9 bg-white p-4 rounded shadow1">{renderFragment}</div>
      </div>
    </div>
  )
}
