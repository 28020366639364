import { LayoutLoading } from 'components/LayoutLoading'
import { cloneDeep } from 'lodash'
import { useEffect, useState } from 'react'
import { getAdminConfig, setAdminConfig } from 'services'
import { convertToTitleCase } from 'utils'
import { RenderInput } from 'utils/RenderInput'

export const Interface = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<Record<string, any>>({})

  const initData = async () => {
    setIsLoading(true)
    const res = await getAdminConfig('interface')
    setData(res)
    setIsLoading(false)
  }
  useEffect(() => {
    initData()
  }, [])

  const onChange = async (key: string, subjKey: string, value: boolean) => {
    console.log(key, subjKey, value)
    const temp = cloneDeep(data)
    temp[key][subjKey].value = value
    setIsLoading(true)
    await setAdminConfig('interface', temp)
    setIsLoading(false)
    setData(temp)
  }
  return (
    <div className="relative px-3 py-1.5">
      <LayoutLoading show={isLoading} />
      {Object.keys(data).map((section) => {
        const sectionTitle = convertToTitleCase(section)
        const item = data[section]
        return (
          <div className="mb-4">
            <RenderInput input={{ inputType: 'section', title: sectionTitle }} Key={sectionTitle} onChange={() => {}} />
            {Object.keys(item).map((subKey: string, index: number) => {
              return (
                <div className={`flex items-center justify-between p-4 ${index % 2 && 'bg-slate-100'}`}>
                  <p>
                    {index + 1}. {item[subKey].title}
                  </p>
                  <RenderInput
                    input={{ inputType: 'toggle', title: '', value: item[subKey].value }}
                    key={`${section}-${subKey}`}
                    Key={`${section}-${subKey}`}
                    onChange={(key: string, value: boolean) => onChange(section, subKey, value)}
                  />
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}
