import {
  BarsArrowDownIcon,
  BarsArrowUpIcon,
  MinusCircleIcon,
  PencilSquareIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { Overview } from 'components/Overview'
import { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { addParty, deleteParty, getParties, getPartyIds, updateParties } from 'services'
import svgSearch from 'stories/assets/search.svg'
import { Button, Input2 } from 'stories/components'
import { confirm, phoneConvertor, prompt } from 'utils'
import { setLoanNumber } from 'utils/setLoanNumber'

import { defaultInputs } from './Modal/config'
import { PartyModal } from './Modal/PartyModal'

export default function Parties() {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<Record<string, any>[]>([])
  const [partyIds, setPartyIds] = useState<Record<string, any>>({})
  const [selectedItem, setSelectedItem] = useState<Record<string, any> | null>(null)
  const [filters, setFilters] = useState<Record<string, any>>({
    query: '',
    orderBy: 'Type',
    orderDir: '1',
  })
  const [redirectTo, setRedirectTo] = useState('')

  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    const loanNumber = setLoanNumber()
    setLoading(true)
    getParties(Number(loanNumber)).then((data) => {
      setData(data)
      setLoading(false)
      const params = new URLSearchParams(location.search)

      const redirectTo = decodeURI(params.get('redirectTo') || '')
      setRedirectTo(redirectTo)

      const edit = decodeURI(params.get('edit') || '')
      if (edit.length) {
        data.map((item: any) => {
          if (item.Type === edit) {
            setSelectedItem(item)
          }
        })
      }
    })
    getPartyIds().then(({ PartyIDs }) => {
      setPartyIds(PartyIDs)
    })
  }, [])

  const parties = useMemo(() => {
    const { query, orderBy, orderDir } = filters
    return data
      .filter((item) => (!query ? true : Object.values(item).join(' ').toLowerCase().includes(query.toLowerCase())))
      .sort((item1, item2) => {
        let a = item1[orderBy]
        let b = item2[orderBy]
        if (orderBy === 'Type') {
          a = a.toLowerCase()
          b = b.toLowerCase()
        }
        if (a > b) return Number(orderDir)
        if (a == b) return 0
        return -Number(orderDir)
      })
  }, [filters, data])

  const onChangeFilter = (key: 'query' | 'orderBy' | 'orderDir', value: string) => {
    if (loading) return
    const newFilters = Object.assign({}, filters)
    newFilters[key] = value
    setFilters(newFilters)
  }

  const onEdit = (item: Record<string, any>) => {
    setSelectedItem(item)
  }

  const onCloseModal = (result: boolean, item: Record<string, any>) => {
    setSelectedItem(null)
    if (result) {
      const index = data.findIndex((v) => v.Type === item.Type)

      if (index !== -1) {
        const newData = cloneDeep(data)
        newData[index] = item
        setData(newData)
      }
    }
    if (redirectTo) history.push(decodeURI(redirectTo))
  }

  const sortableHeaders = [
    { title: 'Type', key: 'Type' },
    { title: 'Name', key: 'Name' },
    { title: 'Company', key: 'Company' },
    { title: 'Email', key: 'EMail' },
    { title: 'Phone', key: 'WorkPhone' },
  ]

  const renderHeader = (
    title: string,
    sortable: boolean = false,
    key: string,
    sortOrder: number = 1,
    index: number,
  ) => {
    if (!sortable)
      return (
        <th scope="col" className="py-3 px-2" key={title}>
          {title}
        </th>
      )

    const onSort = () => {
      if (sortOrder == 0) sortOrder = -1
      const newFilters = Object.assign({}, filters)
      newFilters['orderBy'] = key
      newFilters['orderDir'] = `${0 - sortOrder}`
      setFilters(newFilters)
    }

    return (
      <th scope="col" className={`py-3 px-2  border ${index == 0 ? '' : ''}`} key={title}>
        <button className="flex uppercase bg-transparent font-bold" onClick={() => onSort()}>
          {title}
          {sortOrder !== 0 ? (
            sortOrder == 1 ? (
              <BarsArrowUpIcon className="w-3 h-3 ml-2" />
            ) : (
              <BarsArrowDownIcon className="w-3 h-3 ml-2" />
            )
          ) : (
            <div className="w-3 h-3 ml-2" />
          )}
        </button>
      </th>
    )
  }

  const onClear = async (item: any) => {
    const content = (
      <div className="mb-6">
        Are you sure want to Clear <span className="font-semibold">{item.Type}</span>?
      </div>
    )
    const rlt = await confirm(content)
    if (!rlt) return
    let data = cloneDeep(item)
    const inputs = defaultInputs()
    Object.keys(inputs).map((key) => {
      if (data[key] !== undefined) data[key] = ''
    })
    data['Name'] = ''
    setLoading(true)
    const loanNumber = setLoanNumber()
    updateParties(Number(loanNumber), item.PartyID || 0, data)
      .then(() => {
        toast('Party is updated', { type: 'info' })
        onCloseModal(true, data)
        setLoading(false)
      })
      .catch(() => setLoading(false))
  }

  const onAddParty = async () => {
    let type = await prompt('Add New Party Type', {
      placeholder: 'New Party Type',
    })
    if (!type) return
    setLoading(true)
    const res = await addParty(type)
    setLoading(false)
    if (res.success) {
      const temp = cloneDeep(data)
      temp.push({ Type: type })
      setData(temp)
      toast(`New Party Type "${type}" is successfully added`, { type: 'success' })
    }
  }

  const onRemove = async (item: any) => {
    const type = item.Type
    let confirmed = await confirm('Are you sure you want to remove this party?')
    if (!confirmed) return
    setLoading(true)
    const res = await deleteParty(type)
    setLoading(false)
    if (res.success) {
      const temp = data.filter((item: Record<string, any>) => item.Type != type)
      setData(temp)
      toast(`Party Type "${type}" is successfully removed`, { type: 'success' })
    } else toast(`Party Type "${type}" does not exist`, { type: 'warning' })
  }

  return (
    <div className="PostClosing-container py-6 px-2">
      <Overview title="Parties" />
      <div className="shadow1 mb-6 max-w-screen-2xl m-auto bg-white rounded p-3 md:p-7 text-left w-full">
        <div className="flex flex-wrap gap-2 items-center justify-between">
          <div className="h-fit mb-3">
            <div className="w-96">
              <Input2
                type="search"
                title="Search Parties"
                hasIcon
                icon={svgSearch}
                value={filters.query}
                onChange={(value) => onChangeFilter('query', value)}
              />
            </div>
          </div>
          <Button onClick={onAddParty}>Add</Button>
        </div>

        <div className="relative overflow-auto shadow-md sm:rounded-lg">
          <LayoutLoading show={loading} />

          <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6 overflow-auto">
            <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="pl-4 px-2 border">
                  No
                </th>
                {sortableHeaders.map(({ title, key }, index) =>
                  renderHeader(title, true, key, filters.orderBy == key ? parseInt(filters.orderDir) : 0, index),
                )}
                <th scope="col" className="px-2 border text-center">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="">
              {parties &&
                parties.map((item: Record<string, any>, index: number) => {
                  return (
                    <tr
                      className={`bg-white border-b dark:bg-gray-800 dark:border-gray-700 ${
                        index % 2 && 'bg-slate-50'
                      }`}
                      key={`${item.PartyID}-${item.CategoryID}-${item.Type}`}
                    >
                      <td className="px-2 pl-4 py-2 border">{index + 1}</td>
                      <td className="px-2 border">{item.Type}</td>
                      <td className="px-2 border">
                        {!item.Name ? `${item.FirstName || ''} ${item.LastName || ''}` : item.Name}
                      </td>
                      <td className="px-2 border">{item.Company}</td>
                      <td className="px-2 border">{item.EMail}</td>
                      <td className="px-2 border">{phoneConvertor(item.WorkPhone)}</td>
                      <td className="px-2 border">
                        <span className="flex flex-wrap justify-center gap-2">
                          <span
                            className="text-shade-blue p-1 hover-shadow1 rounded cursor-pointer"
                            onClick={() => onEdit(item)}
                          >
                            <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
                          </span>
                          <span
                            className="text-red-700 p-1 hover-shadow1 rounded cursor-pointer"
                            onClick={() => onClear(item)}
                          >
                            <MinusCircleIcon className="w-4 h-4"></MinusCircleIcon>
                          </span>
                          {![...Object.values(partyIds), 'Owner'].includes(item.Type) && (
                            <span
                              className="text-red-700 p-1 hover-shadow1 rounded cursor-pointer"
                              onClick={() => onRemove(item)}
                            >
                              <TrashIcon className="w-4 h-4"></TrashIcon>
                            </span>
                          )}
                        </span>
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
        </div>
        {selectedItem && <PartyModal item={selectedItem} onClose={onCloseModal} />}
      </div>
    </div>
  )
}
