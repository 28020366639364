import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { useEffect, useState } from 'react'
import { partiesMap } from 'reducers/loanDetail.reducer'
import { getBorrowerInfo } from 'services'

import { EmailParty, EmailTo, EmailValue } from './EmailTo'

export const LoanPartiesEmailTo = ({
  loanNumber = 0,
  value,
  data: _data,
  loadParties = false,
  hasAddEmail = true,
  hasUniqueToEmail = false,
  onChange,
}: {
  loanNumber?: number
  value: EmailValue
  data: EmailParty[]
  loadParties?: boolean
  hasAddEmail?: boolean
  hasUniqueToEmail?: boolean
  onChange: (data: EmailValue) => void
}) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<EmailParty[]>(_data)
  const [isLoaded, setLoaded] = useState(false)

  useEffect(() => {
    setLoaded(false)
  }, [loanNumber])

  useEffect(() => {
    if (isLoaded || loading) return
    if (!loadParties) {
      setData(_data)
      return
    }

    setLoading(true)
    getBorrowerInfo(loanNumber)
      .then(async ({ parties, borrowerEmail, coBorrowerEmail }) => {
        const emails: EmailParty[] = cloneDeep(_data)
        if (borrowerEmail)
          emails.push({
            party: 'Borrower',
            email: borrowerEmail,
          })
        if (coBorrowerEmail)
          emails.push({
            party: 'CoBorrower',
            email: coBorrowerEmail,
          })
        Object.keys(parties).forEach((key) => {
          if (key == 'accountManager') return
          let email = parties[key]
          if (!email) return

          emails.push({
            party: partiesMap[key],
            email,
          })
        })
        setData(emails)
      })
      .finally(() => {
        setLoading(false)
        setLoaded(true)
      })
  }, [loadParties, _data])

  return (
    <div>
      <LayoutLoading show={loading} />
      <div className="mb-5"></div>
      <EmailTo
        Emails={value}
        Data={data}
        onChange={(data: EmailValue) => onChange(data)}
        hasAddEmail={hasAddEmail}
        hasUniqueToEmail={hasUniqueToEmail}
      />
    </div>
  )
}
