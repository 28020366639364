import { TrashIcon } from '@heroicons/react/24/outline'
import { svgLoading } from 'stories/assets'
import { Button } from 'stories/components'
import { convertNegative2Parentheses, getPrice2decimal, Num } from 'utils'

export function HUD1_1300({
  inputs,
  data,
  ignoreEror,
  isNewFeeLoading,
  renderSectionHeader,
  renderHUD1Input,
  calculateValue,
  setIsNewFeeLoading,
  setIsAddCCModalOpen,
  onFeeDelete,
  isHud1a,
}: {
  inputs: Record<string, any>
  data: Record<string, any>
  ignoreEror: boolean
  isNewFeeLoading: boolean
  renderSectionHeader: Function
  renderHUD1Input: Function
  calculateValue: Function
  setIsNewFeeLoading: Function
  setIsAddCCModalOpen: Function
  onFeeDelete: Function
  isHud1a: boolean
}) {
  return (
    <>
      {renderSectionHeader('1300. Addtional Settlement Charges')}
      {inputs['requiredService']?.map((item: any) => {
        const { key, title, valueKey } = item
        return (
          <tr>
            <td className="p-2">
              {key}. {title}
            </td>
            <td className="p-2">
              {convertNegative2Parentheses(getPrice2decimal(calculateValue(valueKey), false, true))}
            </td>
            <td></td>
          </tr>
        )
      })}
      {inputs['1302-1400']?.map((item: any) => {
        const { key, error, ccid, title, lineIdKey } = item
        if (error && !ignoreEror) return
        return (
          <tr>
            <td className="p-2 flex flex-wrap justify-between gap-1" key={key}>
              {renderHUD1Input(item, key)}
            </td>
            <td className="p-2">
              {!data[item['pocKey']] && [3, 5, 99].includes(Number(data[item['GFEBlockKey']]))
                ? convertNegative2Parentheses(
                    getPrice2decimal(
                      Num(data[item['feeBKey']]) + (data[item['GFEBlockKey']] != 99 ? Num(data[item['feeSKey']]) : 0),
                      false,
                      true,
                    ),
                  )
                : ''}
            </td>
            {!isHud1a && (
              <td className="p-2 flex items-center justify-end">
                {!data[item['pocKey']] && data[item['GFEBlockKey']] == 99
                  ? convertNegative2Parentheses(getPrice2decimal(data[item['feeSKey']], false, true))
                  : ''}
                <span
                  className="text-red-800 cursor-pointer flex items-center justify-end"
                  onClick={() => onFeeDelete(ccid, data[lineIdKey], title)}
                >
                  <TrashIcon className="w-4 h-4" />
                </span>
              </td>
            )}
          </tr>
        )
      })}
      <tr>
        <td className="flex items-center">
          <Button
            link
            color="blue"
            onClick={() => {
              setIsNewFeeLoading(true), setIsAddCCModalOpen(true)
            }}
            disabled={isNewFeeLoading}
          >
            Add new fee
          </Button>
          {isNewFeeLoading && (
            <span className="ml-3">
              <img src={svgLoading} className="inline w-4 h-4 text-white animate-spin" />
            </span>
          )}
        </td>
      </tr>
    </>
  )
}
