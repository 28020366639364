import { ExclamationCircleIcon, PencilSquareIcon, PrinterIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { accountTypes } from 'components/Modals/CreateUser/config'
import { SaveChanges } from 'components/SaveChanges'
import { DisbursementSchedMap, DisbursementStartPurMap, noteColors, PaidByInitialMap, PaidToInitialMap } from 'config'
import { usePermissions } from 'hooks/usePermissions'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import { Prompt } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  downloadFeeWorksheet,
  downloadFile,
  getAdminConfig,
  gethud1PageData,
  posthud1Notes,
  posthud1Page1Data,
} from 'services'
import { svgLoading } from 'stories/assets'
import { Button, Checkbox } from 'stories/components'
import { ClosingCostsDetailsModal } from 'stories/components/ClosingCostsDetailsModal/ClosingCostsDetailsModal'
import { CustomContextMenu } from 'stories/components/CustomContextMenu'
import { FieldNote } from 'stories/components/FieldNote'
import { InterimInterestModal } from 'stories/components/InterimInterestModal'
import { MIPFundingFeeModal } from 'stories/components/MIPFundingFeeModal'
import { OverrideCaclModal } from 'stories/components/OverrideCalcModal/OverrideCalcModal'
import { PrepaidItemsDetailsModal } from 'stories/components/PrepaidItemDetailsModal/PrepaidItemDetailsModal'
import { Tooltip } from 'stories/components/Tooltip/Tooltip'
import {
  confirm,
  convertNegative2Parentheses,
  convertParentheses2Negative,
  formatDate,
  getFirstDayofNextMonth,
  getPrice1or2decimal,
  getPrice2decimal,
  getPrice3decimal,
  isEmpty,
  openAuditLog,
  removeComma,
} from 'utils'
import { RenderInput } from 'utils/RenderInput'
import { setLoanNumber } from 'utils/setLoanNumber'

import { HUD1InputType } from '../HUD1Page1/constant'
import { calculateAggregateAdjustment } from '../HUD1Page2'
import { AddNewFeeModal } from '../HUD1Page2/AddNewFeeModal'

const paymentKeys = [
  'PrepaidItems.0.Payment',
  'PrepaidItems.1.Payment',
  'PrepaidItems.2.Payment',
  'PrepaidItems.3.Payment',
  'PrepaidItems.4.Payment',
  'PrepaidItems.5.Payment',
  'PrepaidItems.6.Payment',
]

export const FeeWorkSheet = () => {
  const [action, setAction] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [changed, setChanged] = useState(false)
  const [inputs, setInputs] = useState<any>({})
  const [data, setData] = useState<any>({})
  const [orgData, setOrgData] = useState<any>({})
  const [IDs, setIDs] = useState<Array<string>>([])
  const [Parties, setParties] = useState<Array<Record<string, string>>>([])
  const [ClosingCosts, setClosingCosts] = useState<Array<Record<string, string>>>([])
  const [PrepaidItems, setPrepaidItems] = useState<Array<Record<string, string>>>([])
  const [notes, setNotes] = useState<any>({})
  const [focusedKey, setFocusedKey] = useState('')
  const [ruleConfig, setRuleConfig] = useState<Array<any>>([])
  const [editField, setEditField] = useState({ key: '', value: null })
  const [ovData, setOVData] = useState<any>({})
  const [showInterimInterestModal, setShowInterimInterestModal] = useState(false)
  const [showMIPFundingModal, setShowMIPFundingModal] = useState(false)
  const [isUpdateMonthReserve, setIsUpdateMonthReserve] = useState<boolean>(false)
  const [isNewFeeLoading, setIsNewFeeLoading] = useState<boolean>(false)
  const [isAddCCModalOpen, setIsAddCCModalOpen] = useState<boolean>(false)
  const [contextMenu, setContextMenu] = useState({
    visible: false,
    x: 0,
    y: 0,
    key: '',
    value: null,
  })
  const [closingCostsDetailData, setClosingCostsDetailData] = useState<any>({})
  const [prepaidItemsDetailData, setPrepaidItemsDetailData] = useState<any>({})

  const loanNumber = setLoanNumber()
  const containerRef = useRef<HTMLDivElement | null>(null)

  const { hasPermission } = usePermissions()
  const canEditFeeWorksheet = hasPermission('ADMIN_TO_AE_PROFILE_PERMISSION')

  const initData = async () => {
    setAction('')
    setIsLoading(true)
    const [res, ruleConfig] = await Promise.all([gethud1PageData('feeWorkSheet'), getAdminConfig('rules')])
    setRuleConfig(ruleConfig.revalidateLoan)
    if (res.success) {
      setInputs(res.inputs)
      setData(res.data)
      setOrgData(res.data)
      setIDs(res.IDs)
      setClosingCosts(res.ClosingCosts)
      setPrepaidItems(res.PrepaidItems)
      setParties(res.Parties)
      setNotes(res.notes)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    initData()
  }, [])

  useEffect(() => {
    checkMonthReserve()
  }, [data])

  useEffect(() => {
    if (contextMenu.visible) {
      document.addEventListener('click', closeContextMenu)
    } else {
      document.removeEventListener('click', closeContextMenu)
    }

    // Cleanup when component unmounts or when context menu is closed
    return () => {
      document.removeEventListener('click', closeContextMenu)
    }
  }, [contextMenu.visible])

  const closeContextMenu = () => {
    setContextMenu({
      visible: false,
      x: 0,
      y: 0,
      key: '',
      value: null,
    })
  }

  const onChange = (key: string, value: any, type?: string) => {
    const temp = cloneDeep(data)
    if (type === 'number') temp[key] = removeComma(value)
    else temp[key] = value
    setData(temp)
    if (!changed) setChanged(true)
  }

  const onBlur = (key: string, value: any, type?: string, orgData?: Record<string, any>) => {
    setFocusedKey('')
    const hasOrgData = !!orgData
    if (!orgData) orgData = data
    let temp = cloneDeep(orgData!)
    const keys = key.split('.')

    if (type === 'number') {
      value = convertParentheses2Negative(value)
      if (key.includes('ClosingCosts') && key.includes('Points'))
        temp[key] = getPrice3decimal(value.replaceAll(',', '')).replaceAll(',', '')
      else temp[key] = getPrice1or2decimal(value.replaceAll(',', '')).replaceAll(',', '')
    } else temp[key] = value

    if (key === 'Status.SchedFundingDate') {
      temp['HUD1.InterimInterestDateFrom'] = value
      temp['HUD1.InterimInterestDateTo'] = getFirstDayofNextMonth(value)
    }
    if (keys[0] === 'ClosingCosts') {
      const pointKey = keys[0] + '.' + keys[1] + '.' + 'Points'
      const borrowerKey = keys[0] + '.' + keys[1] + '.' + 'BorrowerAmount'
      const sellerKey = keys[0] + '.' + keys[1] + '.' + 'SellerAmount'
      if (!temp[pointKey]) {
        if (orgData![pointKey]) {
          temp[borrowerKey] = ''
          temp[sellerKey] = ''
        }
      } else if (temp[pointKey] <= 0) {
        temp[borrowerKey] = 0
      } else {
        temp[borrowerKey] = (temp[pointKey] * temp['HUD1.Line202Calc']) / 100 - temp[sellerKey]
      }
    }
    if (!hasOrgData) setData(temp)
    else return temp
  }

  const onFocus = (key: string) => {
    if (!canEditFeeWorksheet) return
    const temp = cloneDeep(data)
    temp[key] = convertNegative2Parentheses(data[key])
    setData(temp)
    setFocusedKey(key)
  }

  const handleContextMenu = (event: any, key: string, value: any) => {
    event.preventDefault()
    if (containerRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect()
      setContextMenu({
        visible: true,
        x: event.clientX - containerRect.left,
        y: event.clientY - containerRect.top,
        key: key,
        value: value,
      })
    }
  }

  const onDeleteFee = () => {
    initData()
    toast('Successfully deleted fee', { type: 'success' })
  }

  const showInputValue = (value: any, key: string) => {
    if (key !== focusedKey) {
      if (key.includes('ClosingCosts') && key.includes('Points')) value = getPrice3decimal(value, false, true)
      else value = getPrice2decimal(value, false, true)
      return convertNegative2Parentheses(value)
    }
    return value
  }

  const onSaveChanges = async () => {
    const ccTemp = cloneDeep(ClosingCosts)
    const ppTemp = cloneDeep(PrepaidItems)
    let json: any = {}
    Object.keys(data).map((key) => {
      if (JSON.stringify(data[key]) != JSON.stringify(orgData[key])) {
        json[key] = data[key]
        const params = key.split('.')
        if (params[0] === 'ClosingCosts') ccTemp[+params[1]][params[2]] = data[key]
        if (params[0] === 'PrepaidItems') ppTemp[+params[1]][params[2]] = data[key]
      }
    })
    const paymentChanged = !!Object.keys(json).filter((v) => paymentKeys.includes(v)).length
    if (paymentChanged) {
      const result = await confirm(
        `Due to a change, a revalidation is required.  A task will be generated for the ${ruleConfig
          .map((v) => accountTypes[v])
          .join(', ')} to clear this item.`,
      )
      if (!result) return
    }

    if (Object.keys(json).length > 0) {
      json.IDs = IDs
      json.ClosingCosts = ClosingCosts
      json.PrepaidItems = PrepaidItems
      json.Parties = Parties
      json.OriginData = orgData
      setAction('saveChanges')
      await posthud1Page1Data('page1', json)
      setAction('')
      setOrgData(data)
      setClosingCosts(ccTemp)
      setPrepaidItems(ppTemp)
      setChanged(false)
    }
    initData()
  }

  const closeOVModal = (save: false, data: any) => {
    if (save) onChange(ovData.overrideKey, data.ovValue)
    setOVData({})
  }

  const onFieldNoteModalSubmit = async (valueKey: string, data: Record<string, any>) => {
    setEditField({ key: '', value: null })
    setIsLoading(true)
    const res = await posthud1Notes(valueKey, data)
    if (res.success) {
      const temp = cloneDeep(notes)
      temp[valueKey] = data
      setNotes(temp)
    }
    setIsLoading(false)
  }

  const showHistory = async (keys: Array<string>) => {
    const options = {
      table: 'HUD1',
      field: keys.join(', '),
      keys: {
        field: keys,
      },
      renderValue: (data: any) => <span dangerouslySetInnerHTML={{ __html: data }} />,
    }
    keys = keys.filter((key) => key)
    openAuditLog(options)
  }

  const closeMIPFundingModal = (save: boolean, detailData: any) => {
    if (save) {
      let newData = cloneDeep(data)
      Object.keys(detailData).map((key) => {
        if (data[key] !== detailData[key]) {
          newData = onBlur(key, detailData[key], '', newData)
        }
      })
      setData(newData)
      setChanged(true)
    }
    setShowMIPFundingModal(false)
    setShowInterimInterestModal(false)
  }

  const closeCostingDetailModal = (save: false, detailData: any) => {
    if (save) {
      let newData = cloneDeep(data)
      Object.keys(detailData).map((v) => {
        const { key, value } = detailData[v]
        if (data[key] !== value && Object.keys(data).includes(key)) {
          newData = onBlur(key, value, '', newData)
        }
      })
      setData(newData)
      setChanged(true)
    }
    setClosingCostsDetailData({})
    setPrepaidItemsDetailData({})
  }

  const closeAddNewFeeModal = (save: false) => {
    setIsAddCCModalOpen(false)
    setIsNewFeeLoading(false)
    if (save) {
      if (changed) onSaveChanges()
      initData()
      toast('Successfully added new fee(s)', { type: 'success' })
    }
  }

  const calculateValue = (key: string) => {
    let CALCULATED_VALUE: any
    switch (key) {
      case 'BorrowerTotal':
        CALCULATED_VALUE = 0
        inputs['closingCosts']?.map((item: any) => {
          const { feeBKey, ResponsiblePartyTypeKey, lineId, pocKey } = item
          if (data[ResponsiblePartyTypeKey] == 1 || lineId == 802 || data[pocKey]) return
          CALCULATED_VALUE += Number(data[feeBKey])
        })
        break
      case 'SellerTotal':
        CALCULATED_VALUE = 0
        inputs['closingCosts']?.map((item: any) => {
          const { feeSKey, ResponsiblePartyTypeKey, lineId, pocKey } = item
          if (data[ResponsiblePartyTypeKey] == 1 || lineId == 802 || data[pocKey]) return
          CALCULATED_VALUE += Number(data[feeSKey])
        })
        break
      case 'DiscountBorrowerTotal':
        CALCULATED_VALUE = 0
        inputs['closingCosts']?.map((item: any) => {
          const { feeBKey, ResponsiblePartyTypeKey, lineId, pocKey } = item
          if (data[ResponsiblePartyTypeKey] == 1 || lineId != 802 || data[pocKey]) return
          CALCULATED_VALUE += Number(data[feeBKey])
        })
        break
      case 'DiscountSellerTotal':
        CALCULATED_VALUE = 0
        inputs['closingCosts']?.map((item: any) => {
          const { feeSKey, ResponsiblePartyTypeKey, lineId, pocKey } = item
          if (data[ResponsiblePartyTypeKey] == 1 || lineId != 802 || data[pocKey]) return
          CALCULATED_VALUE += Number(data[feeSKey])
        })
        break
      case 'Loan._DailyInterest':
        const roundTo = !data['Loan.InterimIntDecimalsOV'] ? 2 : Number(data['Loan.InterimIntDecimalsOV']) + 1
        const loanAmount = data['isDutch'] ? +data['HUD1.Line202Calc'] : +data['Loan.initialLoanAmount']
        CALCULATED_VALUE =
          Math.round(
            (((loanAmount || 0) *
              (data['Loan.BDInterimIntCalcMethodOV'] == 3 && data['Loan.LoanProductType'] == 1
                ? +data['Loan.ARMIndexValue'] + +data['Loan.ARMMargin']
                : +data['Loan.interestRate']) || 0) /
              100 /
              (data['Loan.InterimIntDaysPerYearOV'] == 2 ? 365 : 360)) *
              Math.pow(10, roundTo),
          ) / Math.pow(10, roundTo)
        break
      case 'Loan._InterimInterest':
        if (data['Loan.DailyInterestOV'])
          CALCULATED_VALUE = (+data['Loan.InterimInterestDays'] || 0) * +data['Loan.DailyInterestOV']
        else {
          CALCULATED_VALUE = (+data['Loan.InterimInterestDays'] || 0) * +calculateValue('Loan._DailyInterest')
        }
        CALCULATED_VALUE = Math.round(CALCULATED_VALUE * 100) / 100
        break
      case 'Loan.interimInterestBorrower':
        CALCULATED_VALUE =
          +(data['Loan.InterimInterestOV'] || calculateValue('Loan._InterimInterest') || 0) -
          (+data['Loan.InterimInterestPBSDesired'] || 0)
        break
      case 'PrepaidTotal':
        CALCULATED_VALUE = data['Loan.InterimInterestOV'] || calculateValue('Loan._InterimInterest')
        ;[0, 5].map((i) => {
          const prefix = `PrepaidItems.${i}.`
          if (data[`${prefix}PremiumPOC`]) return
          CALCULATED_VALUE +=
            +data[`${prefix}PremiumOV`] || +data[`${prefix}MonthsInAdvance`] * +data[`${prefix}Payment`]
        })
        for (let i = 0; i < 7; i++) {
          const prefix = `PrepaidItems.${i}.`
          if (data[`${prefix}ReservesPOC`]) continue
          CALCULATED_VALUE +=
            +data[`${prefix}ReservesOV`] || +data[`${prefix}MonthsInReserve`] * +data[`${prefix}Payment`]
        }
        CALCULATED_VALUE += calculateAggregateAdjustment(data)
        break
      case 'PrepaidOtherTotal':
        CALCULATED_VALUE = Number(data['Loan.InterimInterestPBSDesired'] || 0)
        ;[0, 5].map((i) => {
          const prefix = `PrepaidItems.${i}.`
          if (data[`${prefix}PremiumPOC`]) return
          CALCULATED_VALUE += Number(data[`${prefix}PremiumPBSDesired`])
        })
        for (let i = 0; i < 7; i++) {
          const prefix = `PrepaidItems.${i}.`
          if (data[`${prefix}ReservesPOC`]) continue
          CALCULATED_VALUE += Number(data[`${prefix}ReservesPBSDesired`])
        }
        break
      default:
        break
    }
    return CALCULATED_VALUE
  }

  const calculateMonthReserve = () => {
    const firstPaymentMonth = Number(formatDate(data['Loan.FirstPaymentDate'], 'M'))
    const firstPaymentYear = Number(formatDate(data['Loan.FirstPaymentDate'], 'YYYY'))

    const calculatedMonths = []
    for (let i = 0; i < 7; i++) {
      const disbursementSched = +data[`PrepaidItems.${i}.DisbursementSched`]
      const disbursementStartPur = +data[`PrepaidItems.${i}.DisbursementStartPur`]
      const disbursementStartYear = +data[`PrepaidItems.${i}.DisbursementStartYear`]
      const monthInReserve = +data[`PrepaidItems.${i}.MonthsInReserve`]
      const cushion = data[`PrepaidItems.${i}.CushionOV`] || data[`PrepaidItems.${i}._Cushion`] || 0
      let result: number = 0
      if ([0, 1].includes(Number(disbursementSched))) {
        result = 0
      } else if ([6, 7].includes(Number(disbursementSched))) {
        result = cushion
      } else if ([3, 4, 5].includes(Number(disbursementSched))) {
        if (disbursementStartPur == 0) result = monthInReserve
        else if (disbursementStartPur == 13) result = 1
        else if (
          disbursementStartYear > firstPaymentYear + 1 ||
          (disbursementStartYear == firstPaymentYear + 1 && firstPaymentMonth < disbursementStartPur + 1)
        )
          result = 0
        else {
          let monthsInPeriod = 12
          if (disbursementSched == 3) monthsInPeriod = 3
          else if (disbursementSched == 4) monthsInPeriod = 6

          let min = 100
          for (let i = 0; i < 12 / monthsInPeriod; i++) {
            const currentMonth = (disbursementStartPur + monthsInPeriod * (i - 1)) % 12
            const val = ((firstPaymentMonth % 12) - ((currentMonth + 1) % 12) + 12) % 12
            if (min > val) min = val
          }
          result = min
        }
        result += cushion
      }
      calculatedMonths.push(result)
    }
    return calculatedMonths
  }

  const checkMonthReserve = () => {
    let result = false
    const calculatedMonths = calculateMonthReserve()
    for (let i = 0; i < 7; i++) {
      if (data[`PrepaidItems.${i}.MonthsInReserve`] != calculatedMonths[i]) {
        result = true
        break
      }
    }
    setIsUpdateMonthReserve(result)
  }

  const onUpdateMonthReserve = async () => {
    const temp = cloneDeep(data)
    let htmlContent =
      'Would you like to update the number of months in reserves?<div style="font-size: 16px; padding: 0 26px; text-align: left; margin-top: 12px;">'
    const titles = [
      'Hazard Insurance',
      'Mortgage Insurance',
      data['PrepaidItems.2.NameOV'],
      'Property Taxes',
      'Association Dues',
      'Flood Insurance',
      data['PrepaidItems.6.NameOV'],
    ]
    const calculatedMonths = calculateMonthReserve()
    calculatedMonths.map((v, index: number) => {
      if (temp[`PrepaidItems.${index}.MonthsInReserve`] != v) {
        htmlContent += `<p>The months in reserve for ${titles[index]} should be ${v}</p>`
      }
    })
    htmlContent += '</div>'
    const result = await confirm(htmlContent)
    if (!result) return
    calculatedMonths.map((v, index: number) => {
      temp[`PrepaidItems.${index}.MonthsInReserve`] = v == 0 ? '' : v
    })
    setData(temp)
    setChanged(true)
  }

  const renderInput = (input: any, option?: any) => {
    const {
      type,
      titleKey,
      pocKey,
      PPFCKey,
      PaidByOtherTypeKey,
      feeBKey,
      feeMultipleKey,
      feeSKey,
      lineNoKey,
      ClosingCostTypeKey,
      PaidToTypeKey,
      ResponsiblePartyTypeKey,
      inputWidth,
      valueKey,
      contentType,
      overrideKey,
      calculatedValue,
      ReservesPOCKey,
      reserveOVKey,
      monthKey,
      canOverride,
      title,
      PrepaidItemTypeKey,
      PeriodicPaymentAmountKey,
      DisbursementSchedKey,
      DisbursementStartPurKey,
      CushionKey,
      CushionOVKey,
      MonthsInAdvanceKey,
      PremiumOVKey,
      PremiumPBSDesired,
      PremiumPOCKey,
      PremiumNetFromWireKey,
      PremiumPaidToTypeKey,
      PremiumPointsAndFeesAmountOV,
      ReservesPointsAndFeesAmountOV,
      QMATRNotesKey,
      PremiumPaidByOtherTypeKey,
      ReservesPaidByOtherTypeKey,
      MISMOPrepaidItemTypeOVKey,
      DisbursementStartYearKey,
      DisbursementPeriodsKey,
      DisbursementListKey,
      TRIDBlockKey,
      GFEBlockKey,
      FinancedKey,
      NetFromWireKey,
      NotCountedKey,
      ProviderChosenByBorrowerKey,
      lineIdKey,
      PointsAndFeesAmountOVKey,
      PaidToNameKey,
      isPOCDisabled,
      partyType,
      defaultValueKey,
      ccid,
    } = input
    let VALUE = valueKey ? data[valueKey] : null
    const OVVALUE = overrideKey ? data[overrideKey] : null
    let CALCULATED_VALUE: any = valueKey ? calculateValue(valueKey) : null
    if (valueKey != 'Loan._DailyInterest') CALCULATED_VALUE = getPrice2decimal(CALCULATED_VALUE, false, true)
    VALUE = !isEmpty(CALCULATED_VALUE) ? CALCULATED_VALUE : calculatedValue || VALUE

    const isPremium = option === 'premium'
    if (type === HUD1InputType.closingCost) {
      return (
        <>
          <td className="p-2">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-1">
                <span
                  className="cursor-pointer"
                  onClick={() =>
                    setClosingCostsDetailData({
                      hudNo: { value: data[lineNoKey], key: lineNoKey },
                      lineName: { value: title, key: 'title' },
                      isPoints: {
                        value:
                          (data[lineNoKey] == 801 && [32, 34].includes(data[ClosingCostTypeKey])) ||
                          data[lineNoKey] == 802,
                        key: 'isPoints',
                      },
                      points: { value: data[feeMultipleKey], key: feeMultipleKey },
                      borrowerAmount: { value: data[feeBKey], key: feeBKey },
                      sellerAmount: { value: data[feeSKey], key: feeSKey },
                      TRIDBlock: { value: data[TRIDBlockKey], key: TRIDBlockKey },
                      GFEBlock: { value: data[GFEBlockKey], key: GFEBlockKey },
                      ClosingCostType: { value: data[ClosingCostTypeKey], key: ClosingCostTypeKey },
                      PaidToType: { value: data[PaidToTypeKey], key: PaidToTypeKey },
                      Financed: { value: data[FinancedKey], key: FinancedKey },
                      ResponsiblePartyType: { value: data[ResponsiblePartyTypeKey], key: ResponsiblePartyTypeKey },
                      NetFromWire: { value: data[NetFromWireKey], key: NetFromWireKey },
                      NotCounted: { value: data[NotCountedKey], key: NotCountedKey },
                      ProviderChosenByBorrower: {
                        value: data[ProviderChosenByBorrowerKey],
                        key: ProviderChosenByBorrowerKey,
                      },
                      PaidByOtherType: { value: data[PaidByOtherTypeKey], key: PaidByOtherTypeKey },
                      poc: { value: data[pocKey], key: pocKey },
                      PPFC: { value: data[PPFCKey], key: PPFCKey },
                      lineId: { value: data[lineIdKey], key: lineIdKey },
                      PointsAndFeesAmountOV: { value: data[PointsAndFeesAmountOVKey], key: PointsAndFeesAmountOVKey },
                      PaidTo: { value: data[PaidToNameKey], key: PaidToNameKey },
                      isPOCDisabled: { value: isPOCDisabled, key: '' },
                      partyType: { value: partyType, key: '' },
                      partyName: { value: data[defaultValueKey], key: '' },
                      ccid: { value: ccid, key: '' },
                    })
                  }
                >
                  <PencilSquareIcon className="w-4 h-4" />
                </span>
                <span>{data[titleKey]}</span>
              </div>
              {((data[lineNoKey] == 801 && [32, 34].includes(data[ClosingCostTypeKey])) || data[lineNoKey] == 802) && (
                <input
                  className={`w-[80px] px-2 text-right border bg-${noteColors[notes[feeMultipleKey]?.flag]}`}
                  value={showInputValue(data[feeMultipleKey], feeMultipleKey)}
                  onBlur={(e) => {
                    onBlur(feeMultipleKey, e.target.value, 'number')
                  }}
                  onFocus={() => onFocus(feeMultipleKey)}
                  onChange={(e) => onChange(feeMultipleKey, e.target.value)}
                  onContextMenu={(e) => handleContextMenu(e, feeMultipleKey, data[feeMultipleKey])}
                  disabled={data[lineNoKey] == 802}
                  readOnly={!canEditFeeWorksheet}
                />
              )}
            </div>
          </td>
          <td className="p-2">
            <Checkbox
              className={`w-fit`}
              id={'POC'}
              key={'POC'}
              value={data[PPFCKey]}
              onChange={(e) => onChange(PPFCKey, e)}
            />
          </td>
          <td className="p-2">
            <Checkbox
              className={`w-fit`}
              id={'POC'}
              key={'POC'}
              value={data[pocKey]}
              onChange={(e) => onChange(pocKey, e)}
            />
          </td>
          <td className="p-2">{PaidToInitialMap[data[PaidToTypeKey]]}</td>
          <td className="p-2">
            <input
              className={`px-2 text-right border w-[80px] bg-${noteColors[notes[feeBKey]?.flag]}`}
              value={showInputValue(data[feeBKey], feeBKey)}
              onBlur={(e) => {
                onBlur(feeBKey, e.target.value, 'number')
              }}
              onFocus={() => onFocus(feeBKey)}
              onChange={(e) => onChange(feeBKey, e.target.value)}
              disabled={(data[feeMultipleKey] ? true : false) || data[lineNoKey] == 802}
              onContextMenu={(e) => handleContextMenu(e, feeBKey, data[feeBKey])}
              readOnly={!canEditFeeWorksheet}
            />
          </td>
          <td className="p-2">
            <div className="flex items-center">
              <input
                className={`px-2 text-right border w-[80px] bg-${noteColors[notes[feeSKey]?.flag]}`}
                value={showInputValue(data[feeSKey], feeSKey)}
                onBlur={(e) => {
                  onBlur(feeSKey, e.target.value, 'number')
                }}
                onFocus={() => onFocus(feeSKey)}
                onChange={(e) => onChange(feeSKey, e.target.value)}
                onContextMenu={(e) => handleContextMenu(e, feeSKey, data[feeSKey])}
                readOnly={!canEditFeeWorksheet}
              />
              <span>
                <div className="flex items-center">
                  {PaidByInitialMap[data[PaidByOtherTypeKey] || 0]}{' '}
                  {data[ResponsiblePartyTypeKey] == 1 ? (
                    <Tooltip message="This fee is the responsibility of the seller; it will generally be excluded from calculations.">
                      <span className="bg-yellow-200 shadow-md p-1 cursor-default">S</span>
                    </Tooltip>
                  ) : (
                    ''
                  )}
                </div>
              </span>
            </div>
          </td>
          <td className="p-2"></td>
          <td className="p-2 text-right">
            {convertNegative2Parentheses(
              +data[feeBKey] + +data[feeSKey] == 0
                ? ''
                : getPrice2decimal(+data[feeBKey] + +data[feeSKey], false, true),
            )}
          </td>
        </>
      )
    }
    if (type === HUD1InputType.prepaidItem) {
      return (
        <>
          <td className="p-2 flex justify-between items-center">
            <div className="flex flex-1 items-center gap-1">
              {title != 'Mortgage Insurance' ? (
                <span
                  className="cursor-pointer hover:text-shade-blue"
                  onClick={() =>
                    setPrepaidItemsDetailData({
                      title: [3, 7].includes(data[PrepaidItemTypeKey]) ? data[titleKey] : title,
                      payment: { value: data[feeMultipleKey], key: feeMultipleKey },
                      periodicPayment: { value: data[PeriodicPaymentAmountKey], key: PeriodicPaymentAmountKey },
                      disbSched: { value: data[DisbursementSchedKey], key: DisbursementSchedKey },
                      starting: { value: data[DisbursementStartPurKey], key: DisbursementStartPurKey },
                      cushion: { value: data[CushionKey], key: CushionKey },
                      cushionOV: { value: data[CushionOVKey], key: CushionOVKey },
                      monthsInAdvance: { value: data[MonthsInAdvanceKey], key: MonthsInAdvanceKey },
                      premiumOV: { value: data[PremiumOVKey], key: PremiumOVKey },
                      premiumPBS: { value: data[PremiumPBSDesired], key: PremiumPBSDesired },
                      premiumPOC: { value: data[PremiumPOCKey], key: PremiumPOCKey },
                      netFromWire: { value: data[PremiumNetFromWireKey], key: PremiumNetFromWireKey },
                      paidTo: { value: data[PremiumPaidToTypeKey], key: PremiumPaidToTypeKey },
                      premPointsAndFeesOV: {
                        value: data[PremiumPointsAndFeesAmountOV],
                        key: PremiumPointsAndFeesAmountOV,
                      },
                      monthsInReserve: { value: data[monthKey], key: monthKey },
                      reserveOV: { value: data[reserveOVKey], key: reserveOVKey },
                      reservePBS: { value: data[feeSKey], key: feeSKey },
                      reservesPOC: { value: data[ReservesPOCKey], key: ReservesPOCKey },
                      resPointsAndFeesOV: {
                        value: data[ReservesPointsAndFeesAmountOV],
                        key: ReservesPointsAndFeesAmountOV,
                      },
                      atrNotes: { value: data[QMATRNotesKey], key: QMATRNotesKey },
                      premiumPaidByOther: { value: data[PremiumPaidByOtherTypeKey], key: PremiumPaidByOtherTypeKey },
                      reservePaidByOther: { value: data[ReservesPaidByOtherTypeKey], key: ReservesPaidByOtherTypeKey },
                      prepaidItemType: { value: data[MISMOPrepaidItemTypeOVKey], key: MISMOPrepaidItemTypeOVKey },
                      disbStartYear: { value: data[DisbursementStartYearKey], key: DisbursementStartYearKey },
                      disbPeriods: { value: data[DisbursementPeriodsKey], key: DisbursementPeriodsKey },
                      disbList: { value: data[DisbursementListKey], key: DisbursementListKey },
                    })
                  }
                >
                  <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
                </span>
              ) : (
                <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
              )}
              {title || data[titleKey]}
            </div>
            {isPremium && (
              <div>
                <input
                  className={`px-2 text-right border w-12 bg-${noteColors[notes[MonthsInAdvanceKey]?.flag]}`}
                  value={data[MonthsInAdvanceKey]}
                  onChange={(e) => onChange(MonthsInAdvanceKey, e.target.value)}
                  onContextMenu={(e) => handleContextMenu(e, MonthsInAdvanceKey, data[MonthsInAdvanceKey])}
                  readOnly={!canEditFeeWorksheet}
                />
                <span>mo ×</span>
                <input
                  className={`px-2 text-right border w-[80px] bg-${noteColors[notes[feeMultipleKey]?.flag]}`}
                  value={showInputValue(data[feeMultipleKey], feeMultipleKey)}
                  onBlur={(e) => {
                    onBlur(feeMultipleKey, e.target.value, 'number')
                  }}
                  onFocus={() => onFocus(feeMultipleKey)}
                  onChange={(e) => onChange(feeMultipleKey, e.target.value)}
                  onContextMenu={(e) => handleContextMenu(e, feeMultipleKey, data[feeMultipleKey])}
                  readOnly={!canEditFeeWorksheet}
                />
                <span>/mo</span>
              </div>
            )}
          </td>
          {!isPremium && (
            <>
              <td className="p-2">
                <input
                  className={`px-2 text-right border w-12 bg-${noteColors[notes[monthKey]?.flag]}`}
                  value={data[monthKey]}
                  onChange={(e) => onChange(monthKey, e.target.value)}
                  onContextMenu={(e) => handleContextMenu(e, monthKey, data[monthKey])}
                  readOnly={!canEditFeeWorksheet}
                />
                ×
              </td>
              <td className="p-2">
                {' '}
                <input
                  className={`px-2 text-right border w-[80px] bg-${noteColors[notes[feeMultipleKey]?.flag]}`}
                  value={showInputValue(data[feeMultipleKey], feeMultipleKey)}
                  onBlur={(e) => {
                    onBlur(feeMultipleKey, e.target.value, 'number')
                  }}
                  onFocus={() => onFocus(feeMultipleKey)}
                  onChange={(e) => onChange(feeMultipleKey, e.target.value)}
                  onContextMenu={(e) => handleContextMenu(e, feeMultipleKey, data[feeMultipleKey])}
                  readOnly={!canEditFeeWorksheet}
                />
                =
              </td>
            </>
          )}
          <td className="p-2 text-right">
            <input
              className={`px-2 text-right border w-[80px] bg-${
                noteColors[notes[isPremium ? PremiumOVKey : reserveOVKey]?.flag]
              }`}
              value={`${
                (isPremium && data[PremiumPOCKey]) || (!isPremium && data[ReservesPOCKey]) ? 'poc ' : ''
              }${getPrice2decimal(
                data[isPremium ? PremiumOVKey : reserveOVKey]
                  ? +data[isPremium ? PremiumOVKey : reserveOVKey] - +data[isPremium ? PremiumPBSDesired : feeSKey]
                  : +data[isPremium ? MonthsInAdvanceKey : monthKey] * +data[feeMultipleKey] -
                      +data[isPremium ? PremiumPBSDesired : feeSKey],
                false,
                true,
              )}`}
              disabled={true}
              onContextMenu={(e) =>
                handleContextMenu(
                  e,
                  isPremium ? PremiumOVKey : reserveOVKey,
                  getPrice2decimal(
                    data[isPremium ? PremiumOVKey : reserveOVKey]
                      ? +data[isPremium ? PremiumOVKey : reserveOVKey] - +data[isPremium ? PremiumPBSDesired : feeSKey]
                      : +data[isPremium ? MonthsInAdvanceKey : monthKey] * +data[feeMultipleKey] -
                          +data[isPremium ? PremiumPBSDesired : feeSKey],
                    false,
                    true,
                  ),
                )
              }
              readOnly={!canEditFeeWorksheet}
            />
          </td>
          <td className="p-2 text-right flex gap-1 items-center justify-end">
            <input
              className={`px-2 text-right border w-[80px] bg-${
                noteColors[notes[isPremium ? PremiumPBSDesired : feeSKey]?.flag]
              }`}
              value={showInputValue(
                data[isPremium ? PremiumPBSDesired : feeSKey],
                isPremium ? PremiumPBSDesired : feeSKey,
              )}
              onBlur={(e) => {
                onBlur(isPremium ? PremiumPBSDesired : feeSKey, e.target.value, 'number')
              }}
              onFocus={() => onFocus(isPremium ? PremiumPBSDesired : feeSKey)}
              onChange={(e) => onChange(isPremium ? PremiumPBSDesired : feeSKey, e.target.value)}
              onContextMenu={(e) =>
                handleContextMenu(
                  e,
                  isPremium ? PremiumPBSDesired : feeSKey,
                  data[isPremium ? PremiumPBSDesired : feeSKey],
                )
              }
              readOnly={!canEditFeeWorksheet}
            />
            <span>{PaidByInitialMap[data[isPremium ? PremiumPaidByOtherTypeKey : ReservesPaidByOtherTypeKey]]}</span>
          </td>
          {isPremium && (
            <td className="p-2 text-right">
              {getPrice2decimal(
                data[PremiumOVKey] ? data[PremiumOVKey] : +data[MonthsInAdvanceKey] * +data[feeMultipleKey],
                false,
                true,
              )}
            </td>
          )}
          {!isPremium && (
            <>
              <td className="p-2">
                <select
                  name=""
                  className="p-0 pl-1 text-sm rounded-sm border-gray-200"
                  style={{ paddingRight: '1.5rem' }}
                  onChange={(e) => onChange(DisbursementSchedKey, e.target.value)}
                  value={data[DisbursementSchedKey]}
                  onContextMenu={(e) => handleContextMenu(e, DisbursementSchedKey, data[DisbursementSchedKey])}
                  disabled={!canEditFeeWorksheet}
                >
                  {Object.keys(DisbursementSchedMap).map((key) => {
                    return (
                      <option key={key} value={key}>
                        {DisbursementSchedMap[key]}
                      </option>
                    )
                  })}
                </select>
              </td>
              <td className="p-2">
                {[3, 4, 5].includes(Number(data[DisbursementSchedKey])) && (
                  <select
                    name=""
                    className="p-0 pl-1 text-sm rounded-sm border-gray-200"
                    style={{ paddingRight: '1.5rem' }}
                    onChange={(e) => onChange(DisbursementStartPurKey, e.target.value)}
                    value={Number(data[DisbursementStartPurKey])}
                    onContextMenu={(e) => handleContextMenu(e, DisbursementStartPurKey, data[DisbursementStartPurKey])}
                  >
                    {Object.keys(DisbursementStartPurMap).map((key) => {
                      return (
                        <option key={key} value={key}>
                          {DisbursementStartPurMap[key]}
                        </option>
                      )
                    })}
                  </select>
                )}
              </td>
              <td className="p-2">
                {[3, 4, 5].includes(Number(data[DisbursementSchedKey])) &&
                  ![0, 13].includes(Number(data[DisbursementStartPurKey])) && (
                    <input
                      className={`px-2 text-right border w-[80px] bg-${
                        noteColors[notes[DisbursementStartYearKey]?.flag]
                      }`}
                      value={getPrice1or2decimal(data[DisbursementStartYearKey])}
                      onChange={(e) => onChange(DisbursementStartYearKey, e.target.value, 'number')}
                      onContextMenu={(e) =>
                        handleContextMenu(e, DisbursementStartYearKey, data[DisbursementStartYearKey])
                      }
                      readOnly={!canEditFeeWorksheet}
                    />
                  )}
              </td>
            </>
          )}
        </>
      )
    }
    if (type === HUD1InputType.normalInput) {
      return (
        <input
          className={`px-2 text-right border w-[${inputWidth || '120px'}] bg-${noteColors[notes[valueKey]?.flag]}`}
          value={contentType == 'number' ? showInputValue(data[valueKey], valueKey) : data[valueKey]}
          onBlur={(e) => {
            onBlur(valueKey, e.target.value, contentType == 'text' ? '' : 'number')
          }}
          onFocus={() => onFocus(valueKey)}
          onChange={(e) => onChange(valueKey, e.target.value)}
          onContextMenu={(e) => handleContextMenu(e, valueKey, data[valueKey])}
          readOnly={!canEditFeeWorksheet}
        />
      )
    }
    if (type === HUD1InputType.calculatedInput) {
      const currentValue =
        valueKey == 'Loan._DailyInterest'
          ? convertNegative2Parentheses(OVVALUE || VALUE)
          : convertNegative2Parentheses(getPrice2decimal(OVVALUE || VALUE || 0, false, true))
      let feeBValue
      if (overrideKey.includes('ReservesOV') && data[ReservesPOCKey]) {
        feeBValue = data[reserveOVKey]
          ? data[reserveOVKey] - data[feeSKey]
          : +data[feeMultipleKey] * +data[monthKey] - +data[feeSKey]
        feeBValue = getPrice2decimal(feeBValue, false, true)
      }
      return (
        <>
          {canOverride && (
            <span
              className="cursor-pointer hover:text-shade-blue"
              onClick={() =>
                setOVData({
                  title,
                  overrideKey,
                  calcValue: CALCULATED_VALUE || calculatedValue || data[valueKey],
                  ovValue: data[overrideKey] || '',
                })
              }
              onContextMenu={(e) => handleContextMenu(e, valueKey, currentValue)}
            >
              <span
                className={`px-2 text-right border cursor-not-allowed inline-block w-[80px] overflow-auto  no-scrollbar whitespace-nowrap text-overflow-ellipsis ${
                  OVVALUE ? 'bg-red-100' : ''
                } bg-${noteColors[notes[valueKey]?.flag]}`}
              >
                {overrideKey.includes('ReservesOV') && data[ReservesPOCKey]
                  ? `${feeBValue}${feeBValue ? 'B*' : ''} ${data[feeSKey]}${data[feeSKey] ? 'S*' : ''}`
                  : currentValue}
              </span>
            </span>
          )}
        </>
      )
    }
  }

  const onDownload = async () => {
    setIsLoading(true)
    const pdfData = await downloadFeeWorksheet()
    downloadFile(`Fee Worksheet ${loanNumber}(${moment().tz('America/New_York').format('YYYY-MM-DD')}).pdf`, pdfData)
    setIsLoading(false)
  }

  return (
    <div className="HUD1Page3-container relative" ref={containerRef}>
      <LayoutLoading show={action !== '' || isLoading} />
      <Prompt
        when={changed}
        message={`You've made some changes on Fee WorkSheet page!\nAre you sure want to leave without Saving?`}
      />
      <div className="flex items-center justify-between">
        <h2 className="text-2xl font-bold flex items-center mb-3">
          Fee WorkSheet
          {isLoading && (
            <span className="ml-3">
              <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
            </span>
          )}
        </h2>
        <p className={`hover:underline cursor-pointer flex justify-end`}>
          <span className="p-1 hover-shadow1 cursor-pointer rounded" onClick={onDownload}>
            <PrinterIcon className="w-5 h-5 text-shade-blue" />
          </span>
        </p>
      </div>
      <RenderInput input={{ inputType: 'section', title: 'Closing Costs' }} Key="ccSection" onChange={() => {}} />
      <table className="w-full mb-4 mt-4">
        <thead className="text-left bg-blue-100">
          <tr>
            <td className="p-2">Closing Costs</td>
            <td className="p-2">PPFC</td>
            <td className="p-2">POC</td>
            <td className="p-2">TO</td>
            <td className="p-2">Borrower</td>
            <td className="p-2">Seller/Lender</td>
            <td className="p-2">Estimate</td>
            <td className="p-2 text-right">GFE</td>
          </tr>
        </thead>
        <tbody className="text-sm">
          {inputs['closingCosts']?.map((input: any) => {
            return <tr>{renderInput(input)}</tr>
          })}
        </tbody>
      </table>
      <div className="flex items-center">
        <Button
          link
          color="blue"
          onClick={() => {
            setIsNewFeeLoading(true), setIsAddCCModalOpen(true)
          }}
          disabled={isNewFeeLoading}
        >
          Add new fee
        </Button>
        {isNewFeeLoading && (
          <span className="ml-3">
            <img src={svgLoading} className="inline w-4 h-4 text-white animate-spin" />
          </span>
        )}
      </div>
      <p className="bg-blue-100 p-2">Closing Costs Summary</p>
      <table className="w-full mb-4">
        <thead className="text-right text-sm">
          <tr>
            <td className="p-2"></td>
            <td className="p-2">Total</td>
            <td></td>
            <td className="p-2">Paid by Others</td>
            <td></td>
            <td className="p-2">Paid by Borrower</td>
          </tr>
        </thead>
        <tbody className="text-sm">
          <tr>
            <td className="p-2">Closing Costs Subtotal</td>
            <td className="text-right p-2">
              {getPrice2decimal(calculateValue('BorrowerTotal') + calculateValue('SellerTotal'), false, true)}
            </td>
            <td className="text-center">-</td>
            <td className="text-right p-2">{getPrice2decimal(calculateValue('SellerTotal'), false, true)}</td>
            <td className="text-center">=</td>
            <td className="text-right p-2">{getPrice2decimal(calculateValue('BorrowerTotal'), false, true)}</td>
          </tr>
          <tr>
            <td className="p-2">Buydown Funds</td>
            <td className="text-right p-2">0.00</td>
            <td className="text-center">-</td>
            <td className="text-right p-2">0.00</td>
            <td className="text-center">=</td>
            <td className="text-right p-2">0.00</td>
          </tr>
          <tr>
            <td className="p-2">Discount Points</td>
            <td className="text-right p-2">
              {getPrice2decimal(
                calculateValue('DiscountBorrowerTotal') + calculateValue('DiscountSellerTotal'),
                false,
                true,
              )}
            </td>
            <td className="text-center">-</td>
            <td className="text-right p-2">{getPrice2decimal(calculateValue('DiscountSellerTotal'), false, true)}</td>
            <td className="text-center">=</td>
            <td className="text-right p-2">{getPrice2decimal(calculateValue('DiscountBorrowerTotal'), false, true)}</td>
          </tr>
          <tr>
            <td className="p-2">Total Closing Costs</td>
            <td className="text-right p-2 border-t-[1px] border-gray-400">
              {getPrice2decimal(
                calculateValue('BorrowerTotal') +
                  calculateValue('SellerTotal') +
                  calculateValue('DiscountBorrowerTotal') +
                  calculateValue('DiscountSellerTotal'),
                false,
                true,
              )}
            </td>
            <td className="text-center border-t-[1px] border-gray-400">-</td>
            <td className="text-right p-2 border-t-[1px] border-gray-400">
              {getPrice2decimal(calculateValue('SellerTotal') + calculateValue('DiscountSellerTotal'), false, true)}
            </td>
            <td className="text-center border-t-[1px] border-gray-400">=</td>
            <td className="text-right p-2 border-t-[1px] border-gray-400">
              {getPrice2decimal(calculateValue('BorrowerTotal') + calculateValue('DiscountBorrowerTotal'), false, true)}
            </td>
          </tr>
        </tbody>
      </table>
      <RenderInput input={{ inputType: 'section', title: 'Prepaids' }} Key="ppSection" onChange={() => {}} />
      {inputs['interimInterestCalc'] && (
        <table className="w-full mb-4 mt-4">
          <thead className="text-left bg-blue-100">
            <tr>
              <td className="p-2">Daily Interest</td>
              <td className="p-2 text-right">Borrower</td>
              <td className="p-2 text-right">Seller/Lender</td>
              <td className="p-2 text-right">GFE</td>
            </tr>
          </thead>
          <tbody className="text-sm">
            <tr>
              <td className="p-2">
                <div className="flex items-center gap-2">
                  <span
                    className="cursor-pointer hover:text-shade-blue hover:underline"
                    onClick={() => setShowInterimInterestModal(true)}
                  >
                    <PencilSquareIcon className="w-4 h-4" />
                  </span>
                  {renderInput(inputs['interimInterestCalc'][0])} days × {renderInput(inputs['interimInterestCalc'][1])}{' '}
                  / day
                </div>
              </td>
              <td className="p-2 text-right">
                <input
                  className="px-2 border border-gray-200 text-[14px] w-[80px] py-0 placeholder-gray-500 text-end"
                  value={getPrice2decimal(calculateValue('Loan.interimInterestBorrower'), false, true)}
                  disabled={true}
                  readOnly={!canEditFeeWorksheet}
                />
              </td>
              <td className="p-2 text-right">
                {renderInput(inputs['interimInterestCalc'][3])}
                {PaidByInitialMap[data['Loan.InterimInterestPaidByOtherType']]}
              </td>
              <td className="p-2 text-right">
                {data['Loan.InterimInterestOV'] || calculateValue('Loan._InterimInterest')}
              </td>
            </tr>
            <tr className="text-base text-left bg-blue-100">
              <td className="p-2">Paid In Advance</td>
              <td className="p-2 text-right">Borrower</td>
              <td className="p-2 text-right">Seller/Lender</td>
              <td className="p-2 text-right">GFE</td>
            </tr>
            {inputs['prepaidItems'] && (
              <>
                <tr>{renderInput(inputs['prepaidItems'][0], 'premium')}</tr>
                <tr>{renderInput(inputs['prepaidItems'][5], 'premium')}</tr>
              </>
            )}
            <tr>
              <td className="p-2">
                <div className="flex items-center gap-1">
                  <PencilSquareIcon className="w-4 h-4" />
                  Mortgage Ins. Premium
                </div>
              </td>
              <td className="p-2"></td>
              <td className="p-2">
                <div className="flex gap-1 justify-end">
                  {inputs['902Calc'] && renderInput(inputs['902Calc'][0])}
                  <span>S</span>
                </div>
              </td>
              <td className="p-2"></td>
            </tr>
            <tr>
              <td className="p-2 flex justify-between">
                <div className="flex items-center gap-1">
                  <span className="cursor-pointer hover:text-shade-blue" onClick={() => setShowMIPFundingModal(true)}>
                    <PencilSquareIcon className="w-4 h-4" />
                  </span>
                  UFMIP Financed
                </div>
                <span>0.00 + Paid in Cash</span>
              </td>
              <td className="p-2 text-right">
                {getPrice2decimal(data['Loan.MIPFFPaidInCash'] - +data['Loan.MIPFFPaidInCashPBSDesired'], false, true)}
              </td>
              <td className="p-2 flex gap-1 justify-end">
                {inputs['902Calc'] && renderInput(inputs['902Calc'][1])}
                <span>{PaidByInitialMap[data['Loan.MIPFFPaidInCashPaidByOtherType'] || 0]}</span>
              </td>
              <td className="p-2 text-right">{getPrice2decimal(data['Loan.MIPFFPaidInCash'], false, true)}</td>
            </tr>
          </tbody>
        </table>
      )}
      <p className="p-2 bg-blue-100 mb-4">Escrow Deposit</p>

      <div className="grid gap-4 md:grid-cols-8 sm:grid-cols-8 grid-cols-1 mb-4">
        {inputs['escrowDeposit']?.map((input: any) => {
          const { valueKey } = input
          input.value = data[valueKey]
          input.history = true
          input.bgColor = noteColors[notes[valueKey]?.flag]

          return (
            <div
              className={`input flex-1 md:col-span-${input.span || 2} sm:col-span-4`}
              key={valueKey}
              onContextMenu={(e) => handleContextMenu(e, valueKey, data[valueKey])}
            >
              <RenderInput
                input={{ ...input, disabled: !canEditFeeWorksheet }}
                Key={valueKey}
                onChange={onChange}
                showHistory={() => showHistory([valueKey])}
              />
            </div>
          )
        })}
      </div>
      <table className="w-full mb-4 text-sm">
        <thead>
          <tr>
            <td>
              {isUpdateMonthReserve && canEditFeeWorksheet && (
                <button className="ml-4 bg-gray-100 p-1 shadow-md hover:drop-shadow-md" onClick={onUpdateMonthReserve}>
                  <div className="flex gap-1 items-center">
                    Update Month in Reserves
                    <ExclamationCircleIcon className="w-4 h-4 text-red-700" />
                  </div>
                </button>
              )}
            </td>
            <td className="p-2">Mo's</td>
            <td className="p-2">$/Mo</td>
            <td className="p-2">Borrower</td>
            <td className="p-2">Seller/Lender</td>
            <td className="p-2">Disburse</td>
            <td className="p-2">Starting</td>
            <td className="p-2">Year</td>
          </tr>
        </thead>
        <tbody>
          {inputs['prepaidItems']?.map((input: any) => {
            return <tr>{renderInput(input, 'reserve')}</tr>
          })}
          <tr>
            <td className="p-2">Aggregate Adjustment</td>
            <td className="p-2"></td>
            <td className="p-2"></td>
            <td className="p-2 text-right">
              {convertNegative2Parentheses(getPrice2decimal(calculateAggregateAdjustment(data), false, true))}
            </td>
          </tr>
        </tbody>
      </table>
      <p className="p-2 bg-blue-100">Prepaids Summary</p>
      <table className="w-full mb-4">
        <thead className="text-right text-sm">
          <tr>
            <td className="p-2"></td>
            <td className="p-2">Total</td>
            <td></td>
            <td className="p-2">Paid by Others</td>
            <td></td>
            <td className="p-2">Paid by Borrower</td>
          </tr>
        </thead>
        <tbody className="text-sm">
          <tr>
            <td className="p-2">Prepaids Subtotal</td>
            <td className="text-right p-2">{getPrice2decimal(calculateValue('PrepaidTotal'), false, true)}</td>
            <td className="text-center">-</td>
            <td className="text-right p-2">{getPrice2decimal(calculateValue('PrepaidOtherTotal'), false, true)}</td>
            <td className="text-center">=</td>
            <td className="text-right p-2">
              {getPrice2decimal(calculateValue('PrepaidTotal') - calculateValue('PrepaidOtherTotal'), false, true)}
            </td>
          </tr>
          <tr>
            <td className="p-2">Mortgage Ins. Premium</td>
            <td className="text-right p-2">0.00</td>
            <td className="text-center">-</td>
            <td className="text-right p-2">0.00</td>
            <td className="text-center">=</td>
            <td className="text-right p-2">0.00</td>
          </tr>
          <tr>
            <td className="p-2">Financed MI Paid in Cash</td>
            <td className="text-right p-2">{getPrice2decimal(data['Loan.MIPFFPaidInCash'] || 0, false, true)}</td>
            <td className="text-center">-</td>
            <td className="text-right p-2">
              {getPrice2decimal(data['Loan.MIPFFPaidInCashPBSDesired'] || 0, false, true)}
            </td>
            <td className="text-center">=</td>
            <td className="text-right p-2">
              {getPrice2decimal(data['Loan.MIPFFPaidInCash'] - +data['Loan.MIPFFPaidInCashPBSDesired'], false, true)}
            </td>
          </tr>
          <tr>
            <td className="p-2">Total Prepaids</td>
            <td className="text-right p-2 border-t-[1px] border-gray-400">
              {getPrice2decimal(calculateValue('PrepaidTotal') + (+data['Loan.MIPFFPaidInCash'] || 0), false, true)}
            </td>
            <td className="text-center border-t-[1px] border-gray-400">-</td>
            <td className="text-right p-2 border-t-[1px] border-gray-400">
              {getPrice2decimal(
                calculateValue('PrepaidOtherTotal') + (+data['Loan.MIPFFPaidInCashPBSDesired'] || 0),
                false,
                true,
              )}
            </td>
            <td className="text-center border-t-[1px] border-gray-400">=</td>
            <td className="text-right p-2 border-t-[1px] border-gray-400">
              {getPrice2decimal(
                calculateValue('PrepaidTotal') +
                  (+data['Loan.MIPFFPaidInCash'] || 0) -
                  (calculateValue('PrepaidOtherTotal') + (+data['Loan.MIPFFPaidInCashPBSDesired'] || 0)),
                false,
                true,
              )}
            </td>
          </tr>
        </tbody>
      </table>
      <div className="flex justify-end">
        <p className={`hover:underline cursor-pointer flex justify-end`}>
          <span className="p-1 hover-shadow1 cursor-pointer rounded" onClick={onDownload}>
            <PrinterIcon className="w-5 h-5 text-shade-blue" />
          </span>
        </p>
      </div>
      {contextMenu.visible && (
        <CustomContextMenu
          x={contextMenu.x}
          y={contextMenu.y}
          onClose={setEditField}
          valueKey={contextMenu.key}
          value={contextMenu.value}
          showHistory={showHistory}
        />
      )}

      {editField.key && (
        <FieldNote
          valueKey={editField.key}
          currentValue={editField.value!}
          noteData={notes[editField.key]}
          onClose={() => setEditField({ key: '', value: null })}
          onOk={onFieldNoteModalSubmit}
        />
      )}

      {Object.keys(ovData).length > 0 && (
        <OverrideCaclModal
          key={ovData.overrideKey}
          title={ovData.title}
          calcValue={ovData.calcValue}
          ovValue={ovData.ovValue}
          ovOptions={ovData.ovOptions}
          onClose={closeOVModal}
        />
      )}

      {Object.keys(closingCostsDetailData).length > 0 && (
        <ClosingCostsDetailsModal
          closingCosts={closingCostsDetailData}
          loanNumber={loanNumber}
          loanAmount={data['HUD1.Line202Calc']}
          onClose={closeCostingDetailModal}
          onSubmit={closeCostingDetailModal}
          onDelete={onDeleteFee}
          canEdit={canEditFeeWorksheet}
        />
      )}

      {Object.keys(prepaidItemsDetailData).length > 0 && (
        <PrepaidItemsDetailsModal
          prepaidItems={prepaidItemsDetailData}
          onClose={closeCostingDetailModal}
          onSubmit={closeCostingDetailModal}
          canEdit={canEditFeeWorksheet}
        />
      )}

      {showInterimInterestModal && <InterimInterestModal data={data} onClose={closeMIPFundingModal} />}
      {showMIPFundingModal && <MIPFundingFeeModal data={data} onClose={closeMIPFundingModal} />}
      {isAddCCModalOpen && <AddNewFeeModal onClose={closeAddNewFeeModal}></AddNewFeeModal>}

      <SaveChanges show={changed} label="Save Changes" onSave={onSaveChanges} />
    </div>
  )
}
