import { accountTypes } from 'components/Modals/CreateUser/config'
import type { InputType } from 'config/input.type.constants'

export const notificationTypeOpts: any = {
  once: 'Once',
  always: 'Always',
}

export const accountTypeWithBorrower: Record<string, any> = { ...accountTypes, borrower: 'Borrower' }

export const defaultInputs = (): Record<string, InputType> => {
  return {
    from: {
      inputType: 'text',
      type: 'date',
      title: 'From',
      required: true,
    },
    to: {
      inputType: 'text',
      type: 'date',
      title: 'To',
      required: true,
    },
    accountType: {
      inputType: 'multiselect',
      title: 'Account Type',
      options: accountTypeWithBorrower,
      defaultSelected: false,
      value: {},
      sort: true,
    },
    type: {
      title: 'Type',
      inputType: 'select',
      hasDefaultOption: true,
      options: notificationTypeOpts,
      required: true,
    },
    title: {
      inputType: 'text',
      title: 'Title',
      required: true,
      span: 2,
    },
    content: {
      inputType: 'textarea',
      title: 'Content',
      required: true,
      span: 2,
    },
    parentEmails: {
      title: 'Parent Emails (will notify all children)',
      inputType: 'AutoComplete',
      autoCompleteType: 'email',
      type: 'email',
      span: 2,
      userSearch: true,
    },
    individualEmails: {
      title: 'Individual Emails',
      inputType: 'AutoComplete',
      autoCompleteType: 'email',
      type: 'email',
      span: 2,
      userSearch: true,
    },
  }
}
