export enum AfxStatus {
  OrderReceived = 'Order Received',
  OnHold = 'On Hold',
  Resumed = 'Resumed',
  Cancelled = 'Cancelled',
  OrderComplete = 'Order Complete',
}

export const AfxBgColors: Record<AfxStatus, string> = {
  [AfxStatus.OrderReceived]: 'bg-gray-500',
  [AfxStatus.OnHold]: 'bg-yellow-700',
  [AfxStatus.Resumed]: 'bg-blue-400',
  [AfxStatus.Cancelled]: 'bg-red-500',
  [AfxStatus.OrderComplete]: 'bg-green-500',
}

export interface IAfxInboundAdd {
  event_name: 'property.add'
  webhook_sent_at: string
  data: {
    your_order_number: string
    rep_first_name: string
    rep_last_name: string
    rep_email: string
    rep_additional_emails: string
    rep_phone: string
    report_delivery_email: string
    client_email: string
    property_type: string
    property_address: string
    property_city: string
    property_state: string
    property_zip: string
    property_county: string
    property_apn: string
    property_alt_apn: string
    property_latitude: string
    property_longitude: string
    property_placekey: string
    property_owner_names: string
    property_lender_name: string
    property_lender_loan_number: string
    special_instructions: string
    is_test: number
  }
}

export interface IAfxInboundStatus {
  event_name: 'property.status.update'
  webhook_sent_at: string
  data: {
    your_order_number: string
    status: AfxStatus
    is_test: number
  }
}

export interface IAfxInboundDocument {
  event_name: 'property.document.upload'
  webhook_sent_at: string
  data: {
    your_order_number: string
    file_name: string
    file_key: string // Custom field
    public_url_to_file: string
    is_test: number
  }
}

export interface IAfxInboundMessage {
  event_name: 'property.message.add'
  webhook_sent_at: string
  data: {
    your_order_number: string
    message: string
    internal: number
    by: string
    created_at: string
    is_test: number
  }
}

export interface IAfxOutboundStatus {
  event_name: 'property.status.update'
  webhook_sent_at: string
  data: {
    your_order_number: string
    afx_property_id: string
    status: AfxStatus
    price: number
    is_test: number
  }
}

export interface IAfxOutboundDocument {
  event_name: 'property.document.upload'
  webhook_sent_at: string
  data: {
    your_order_number: string
    afx_property_id: string
    file_name: string
    public_url_to_file: string
    file_extension: 'pdf'
    file_mime: 'application/pdf'
    file_size: number
    report_data: Record<string, any>
    is_test: number
  }
}

export interface IAfxOutboundMessage {
  event_name: 'property.message.add'
  webhook_sent_at: string
  data: {
    your_order_number: string
    afx_property_id: string
    message: string
    internal: number
    by: string
    created_at: string
    is_test: number
  }
}

export interface IAfxOutboundProduct {
  event_name: 'property.product.update'
  webhook_sent_at: string
  data: {
    your_order_number: string
    afx_property_id: string
    product_id: number
    property_type: 'Commercial' | 'Residential'
    price: number
    is_test: number
  }
}

export interface IAfxData {
  id: number
  loanNumber: number
  status: AfxStatus
  history: (
    | IAfxInboundAdd
    | IAfxInboundStatus
    | IAfxInboundDocument
    | IAfxInboundMessage
    | IAfxOutboundStatus
    | IAfxOutboundDocument
    | IAfxOutboundMessage
    | IAfxOutboundProduct
  )[]
  createdAt: Date
}
