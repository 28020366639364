import { CheckBadgeIcon, ChevronLeftIcon } from '@heroicons/react/24/outline'
import { companyName } from 'config'
import jwtDecode from 'jwt-decode'
import { useState } from 'react'
import { sendTemporaryForgetPasswordEmail } from 'services'
import { Button, Input2 } from 'stories/components'
import { useTitle } from 'utils/pageTitle'
const cloneDeep = require('clone-deep')

const defaultInputs = {
  email: '',
}

export function TemporaryForgetPwd({
  token,
  onSubmit: _onSubmit,
  isModal,
}: {
  token: string
  onSubmit: Function
  isModal: boolean
}) {
  useTitle(`Forgot Password - ${companyName}`)

  const [isLoading, setLoading] = useState(false)
  const [sent, setSent] = useState(false)
  const [inputStates, setInputStates] = useState(defaultInputs)
  const [errors, setErrors] = useState({
    email: '',
  })

  const onSubmit = async () => {
    const newErrors = cloneDeep(defaultInputs)
    if (!inputStates.email) newErrors.email = 'Required'
    else if (token != 'signin') {
      const payload: any = jwtDecode(token)
      if (payload.email != inputStates.email) newErrors.email = 'Email is wrong. Please confirm again.'
    }
    setErrors(newErrors)

    if (!inputStates.email || newErrors.email) return

    setLoading(true)
    sendTemporaryForgetPasswordEmail({ email: inputStates.email, token })
      .then(({ success }) => {
        success && setSent(true)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onChange = (key: string, value: string) => {
    let newState: any = Object.assign({}, inputStates)
    newState[key] = value
    setInputStates(newState)
  }

  const keyPress = (e: any) => {
    if (e.keyCode === 13 || e.keyCode === 9) {
      onSubmit()
      e.preventDefault()
    }
  }

  return (
    <div
      className={
        !isModal ? `home-container sm:text-center lg:text-left w-full block mt-0 relative bg-stone-100 md:py-20` : ''
      }
    >
      <div
        className={
          !isModal ? `max-w-screen-2xl m-auto lg:pt-10 md:pt-5 lg:pl-5 md:pl-3 sm:pt-2 sm-pl-2 pb-10 px-2 pt-4` : ''
        }
      >
        <div className="bg-white rounded m-auto lg:max-w-md md:max-w-md sm:max-w-sm pl-6 pr-6 pt-8 pb-6 relative">
          <p className="text-stone-800 text-xl font-bold mb-3">Problems with your password?</p>
          {sent ? (
            <div
              className="bg-blue-50 border border-blue-400 text-blue-700 px-4 py-3 rounded relative mb-4 text-[15.5px]"
              role="alert"
            >
              <div className="flex justify-center mb-3">
                <CheckBadgeIcon className="w-10 h-10"></CheckBadgeIcon>
              </div>
              <div>
                We've just sent a password reset email to the email address associated with your account. Kindly check
                your inbox.
              </div>
            </div>
          ) : (
            <div>
              <p className="text-base text-stone-800 mb-6">
                Enter the email you used when creating your account and we’ll send you instructions to reset your
                password
              </p>
              <div>
                <Input2
                  type="email"
                  title="Email"
                  value={inputStates.email}
                  required
                  error={errors.email ? 'Please provide a valid email address.' : ''}
                  onChange={(value) => onChange('email', value)}
                  onKeyDown={(e) => keyPress(e)}
                />
                <div className="my-7"></div>
                <Button className="mt-1" color="sky" full bold onClick={onSubmit} loading={isLoading}>
                  Send
                </Button>
              </div>
            </div>
          )}

          <p
            className="mt-3 hover:text-gray-900 hover:underline text-gray-500 flex items-center gap-1 cursor-pointer"
            onClick={() => _onSubmit()}
          >
            <ChevronLeftIcon className="w-4 h-4"></ChevronLeftIcon>
            Back to Login
          </p>
        </div>
      </div>
    </div>
  )
}
