import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { IS_MAIN_COMPANY } from 'config'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { createTicket } from 'services/apis/ticket'
import { Button } from 'stories/components'
import { InputConvert, InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { defaultInputs } from './constants'
import { TicketPriority } from './types'

export const CreateTicket = () => {
  const navigate = useHistory()
  const auth = useSelector((state: any) => state.auth)
  const [inputs, setInputs] = useState(defaultInputs())
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    if (IS_MAIN_COMPANY) navigate.push('/')
  }, [])

  const onChange = async (key: string, value: any) => {
    const newInputs = cloneDeep(inputs)

    const input = newInputs[key]
    input.value = InputConvert(input, value)
    input.error = ''

    if (key === 'priority') {
      const daysToAdd = value === TicketPriority.High ? 1 : value === TicketPriority.Normal ? 3 : 7

      newInputs['dueDate'].value = new Date(new Date().setDate(new Date().getDate() + daysToAdd))
    }

    setInputs(newInputs)
  }

  const onSubmit = () => {
    const newInputs = cloneDeep(inputs)
    let values: Record<string, any> = {}
    let hasError = false
    Object.keys(inputs).forEach((key) => {
      if (inputs[key].visible === false) return null
      newInputs[key].error = InputValidate(newInputs[key])
      const { value, error } = newInputs[key]
      if (error) hasError = true
      values[key] = value || ''
    })
    setInputs(newInputs)
    if (hasError) return false

    values['userName'] = auth.profile.name
    console.log(values)
    setLoading(true)
    createTicket(values)
      .then(() => {
        setLoading(false)
        navigate.goBack()
      })
      .catch(() => setLoading(false))
  }

  const goBackToTickets = () => {
    navigate.goBack()
  }

  return (
    <div className="py-6 px-2">
      <div className="relative shadow1 max-w-screen-2xl m-auto bg-white rounded p-3 md:p-7 pb-3 md:pb-3 sm:text-center lg:text-left w-full">
        <LayoutLoading show={isLoading} />

        <div className="gap-2 mb-3 flex items-center justify-between">
          <h1 className="text-2xl font-variation-settings-600 flex items-center gap-2" onClick={goBackToTickets}>
            <ArrowLeftIcon className="w-4 h-4" />
            <span>Submit a Ticket</span>
          </h1>
        </div>
        <div className="flex flex-col gap-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {Object.keys(inputs).map((key) => {
              const input = inputs[key]
              return (
                <div className={input.span ? `col-span-${input.span}` : ''}>
                  <RenderInput input={input} Key={key} onChange={onChange} />
                </div>
              )
            })}
          </div>
        </div>
        <div className="flex justify-end mt-6">
          <Button onClick={onSubmit}>Submit a Ticket</Button>
        </div>
      </div>
    </div>
  )
}
