import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { InputType } from 'config/input.type.constants'
import { taskInputs } from 'pages/Admin/ConditionsAndTemplates'
import { EmailAssigneeOptions, TaskAssigneeOptions } from 'pages/Tasks/constants'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { getEmailTemplatesGroup, submitTask } from 'services'
import { Button, FormTable } from 'stories/components'
import { InputConvert, InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { defaultInputs, emailInputs } from './constants'
import { LoanSubmissionConfig } from './types'

export const LoanSubmissionDetails = ({
  action,
  label,
  data,
  adminEmails,
  onBack,
  tempTasks,
}: {
  action: string
  label: string
  data: LoanSubmissionConfig
  adminEmails: string[]
  onBack: Function
  tempTasks: Array<any>
}) => {
  const [loanGlobalStatus] = useSelector((state: any) => [state.loanGlobalStatus])
  const [tasks, setTasks] = useState(Array<any>)
  const [emails, setEmails] = useState<Array<any>>([])
  const [inputs, setInputs] = useState(defaultInputs(loanGlobalStatus))
  const [tInputs, setTInputs] = useState<Record<string, any>>({})
  const [eInputs, setEInputs] = useState<Record<string, any>>({})
  const [isLoading, setIsLoading] = useState(false)
  const [emailTemplates, setEmailTemplates] = useState<any[]>([])

  useEffect(() => {
    setIsLoading(true)
    getEmailTemplatesGroup('loanAction').then((emails: any) => {
      setIsLoading(false)
      setEmailTemplates(emails)
      let temp: any = cloneDeep(inputs)
      Object.keys(temp).forEach((key) => {
        const value = (data as any)[key]
        temp[key].value = value
      })
      setInputs(temp)
    })
  }, [action])

  useEffect(() => {
    let temp: any = emailInputs()
    const options: Record<number, string> = {}
    emailTemplates.forEach((item: any) => (options[item.id] = item.subject))
    temp['type'].options = options
    temp['emailTo'].options = assigneeOptions
    setEInputs(temp)
  }, [emailTemplates])

  useEffect(() => {
    setTasks(data.tasks)
    setEmails(data.emails)
  }, [data])

  const assigneeOptions = useMemo(() => {
    const options = { ...EmailAssigneeOptions }
    adminEmails.forEach((email) => (options[email] = email))
    return options
  }, [adminEmails])

  const taskOptions = useMemo(() => {
    const options = { ...TaskAssigneeOptions }
    adminEmails.forEach((email) => (options[email] = email))
    return options
  }, [adminEmails])

  useEffect(() => {
    let obj: any = {},
      IDObj: any = {
        0: 0,
      }
    tempTasks.map((task) => {
      obj[`${task.no}`] = `${task.no} - ${task.description}`
    })
    tasks.map((task) => {
      IDObj[task.id] = task.id
    })
    let temp: any = taskInputs()
    temp = {
      parentID: {
        title: 'Parent ID',
        inputType: 'select',
        hasDefaultOption: true,
        value: 0,
        options: IDObj,
        error: '',
        required: true,
        span: 2,
      },
      taskTemplateNo: {
        title: 'Choose Template',
        inputType: 'select',
        hasDefaultOption: true,
        options: obj,
        error: '',
      },
      ...temp,
    }
    temp['assignedTo'].options = taskOptions
    temp['assignedToManager'].options = taskOptions
    setTInputs(temp)
  }, [tempTasks, taskOptions, tasks])

  const onChange = async (key: string, value: any) => {
    const newInputs = cloneDeep(inputs)
    newInputs[key].value = InputConvert(newInputs[key], value)
    newInputs[key].error = ''
    setInputs(newInputs)
  }

  const onSubmit = () => {
    const newInputs = cloneDeep(inputs)
    let values: Record<string, any> = {}
    let hasError = false
    Object.keys(inputs).forEach((key) => {
      if (inputs[key].visible === false) return null
      newInputs[key].error = InputValidate(newInputs[key])
      let { value, error } = newInputs[key]
      if (error) hasError = true
      values[key] = value
    })
    setInputs(newInputs)
    if (hasError) return false
    values.tasks = tasks
    values.emails = emails
    onBack(values)
  }

  const onSubmitEmails = (currentId: any, values: Record<string, any>) => {
    return new Promise(async (resolve) => {
      let temp = cloneDeep(emails)
      if (!currentId) {
        values = {
          id: Date.now(),
          ...values,
        }
        temp.push(values)
      } else {
        temp.map((item, index) => {
          if (item.id == currentId) {
            temp[index] = { ...temp[index], ...values }
          }
        })
      }
      setEmails(temp)
      resolve(values)
    })
  }

  const onRemoveEmails = async (id: any) => {
    return new Promise(async (resolve) => {
      let temp: any = []
      emails.map((item) => {
        if (item.id != id) temp.push(item)
      })
      setEmails(temp)
      resolve(true)
    })
  }

  const onSubmitTasks = (currentId: any, values: Record<string, any>) => {
    return new Promise(async (resolve) => {
      let temp = cloneDeep(tasks)
      if (!currentId) {
        let ID = 1
        tasks.map((task) => {
          if (ID <= task.id) ID = task.id + 1
        })
        values = {
          id: ID,
          ...values,
        }
        if (values.taskTemplateNo) {
          temp.push(values)
        } else {
          setIsLoading(true)
          delete values.taskTemplateNo
          try {
            const res = await submitTask(0, values)
            setIsLoading(false)
            if (res.taskNo) {
              values.taskTemplateNo = res.taskNo
              temp.push(values)
            }
          } catch {
            setIsLoading(false)
          }
        }
      } else {
        temp.map((item, index) => {
          if (item.id == currentId) {
            if (item.id == values.parentID) toast(`Invalid Parent ID`, { type: 'error' })
            else temp[index] = { ...temp[index], ...values }
          }
        })
      }
      setTasks(temp)
      resolve(values)
    })
  }

  const onRemoveTasks = async (id: any) => {
    return new Promise(async (resolve) => {
      let temp: any = []
      let hasChild = false
      tasks.map((item) => {
        if (item.parentID == id) hasChild = true
        if (item.id != id) temp.push(item)
      })
      if (hasChild) {
        toast('Child Tasks Exist!', { type: 'error' })
        resolve(true)
      } else {
        setTasks(temp)
        resolve(true)
      }
    })
  }

  const inputLogic = (inputs: Record<string, InputType>, ID?: number, key?: string) => {
    console.log(ID)
    const templateNo = inputs['taskTemplateNo'].value
    inputs['description'].disabled = templateNo ? true : false
    if (key === 'taskTemplateNo') {
      let temp: any = taskInputs()
      Object.keys(temp).map((key) => {
        inputs[key].value = temp[key].value
      })

      tempTasks.map((item) => {
        if (item.no == templateNo) {
          Object.keys(inputs).map((key) => {
            if (!['taskTemplateNo', 'parentID'].includes(key)) {
              inputs[key].value = item[key] || temp[key].value
              inputs[key].error = ''
            }
          })
        }
      })
    }
    return inputs
  }

  const inputELogic = (inputs: Record<string, InputType>, ID?: number, key?: string) => {
    console.log(ID)
    if (key === 'type') {
      emailTemplates.map((email) => {
        if (email.id == inputs.type.value) inputs.content.value = email.content
      })
    }
    return inputs
  }

  const getEmailType = (type: string) => {
    let rlt = ''
    emailTemplates.map((email) => {
      if (email.id == type) rlt = email.subject
    })
    return rlt
  }

  return (
    <div className="w-full relative">
      <LayoutLoading show={isLoading} />
      <div className="text-shade-blue flex items-center mb-2 font-bold">
        <ChevronLeftIcon className="w-4 h-4" />
        <span className="hover:underline cursor-pointer" onClick={() => onBack(null)}>
          Back
        </span>
      </div>

      <div className="flex flex-col gap-4 mb-4">
        <p className="text-[18px] font-semibold">Action: {label}</p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {Object.keys(inputs).map((key) => {
            const input = inputs[key]
            let rlt = []
            rlt.push(
              <div className={input.span ? `col-span-${input.span}` : ''} key={key}>
                <RenderInput input={input} Key={key} onChange={onChange} />
              </div>,
            )
            if (key === 'section2') {
              rlt.push(
                <div key={`email-table`} className={`col-span-2`}>
                  <FormTable
                    key="emails"
                    header={[
                      {
                        key: 'emailTo',
                        title: 'To',
                        value: (row: any) => {
                          return (
                            <div>
                              {row.emailTo.map((email: string) => (
                                <div>{assigneeOptions[email]}</div>
                              ))}
                            </div>
                          )
                        },
                      },
                      {
                        key: 'content',
                        title: (
                          <div>
                            <div className="mb-1 border-b">Subject</div>
                            <div className="mb-1 border-b">Content</div>
                            <div>Loan Info</div>
                          </div>
                        ),
                        value: (row: any) => {
                          return (
                            <div>
                              <div className="mb-1 border-b">{getEmailType(row.type)}</div>
                              <div className="mb-1 border-b italic">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: row.content.replace(/\n/g, '<br />'),
                                  }}
                                />
                              </div>
                              <div>{row.includes?.map((key: string) => `${eInputs['includes'].options[key]}, `)}</div>
                            </div>
                          )
                        },
                      },
                    ]}
                    inputs={eInputs}
                    data={emails}
                    inputLogic={inputELogic}
                    onSubmit={(id, values) => onSubmitEmails(id, values)}
                    onRemove={(id) => onRemoveEmails(id)}
                  />
                </div>,
              )
            }
            if (key === 'section3') {
              rlt.push(
                <div key={`section3-table`} className={`col-span-2`}>
                  <FormTable
                    key="tasks"
                    header={[
                      { key: 'parentID', title: 'Parent ID' },
                      { key: 'id', title: 'ID' },
                      { key: 'taskTemplateNo', title: 'Template No' },
                      {
                        key: 'description',
                        title: (
                          <div>
                            <div className="border-b mb-1">Description</div>Notes
                          </div>
                        ),
                        value: (row: any) => {
                          return (
                            <div>
                              <div className="border-b mb-1">{row.description}</div>
                              <div>{row.notes}</div>
                            </div>
                          )
                        },
                      },
                      {
                        key: 'assignedTo',
                        title: (
                          <div>
                            <div className="border-b mb-1">Assigned to</div>Manager
                          </div>
                        ),
                        value: (row: any) => {
                          return (
                            <div>
                              <div className="border-b mb-1">
                                {row.assignedTo.map((to: string) => TaskAssigneeOptions[to] || to).join(', ')}
                              </div>
                              <div>
                                {row.assignedToManager.map((to: string) => TaskAssigneeOptions[to] || to).join(', ')}
                              </div>
                            </div>
                          )
                        },
                      },
                      { key: 'dueDays', title: 'Due Days' },
                    ]}
                    inputs={tInputs}
                    inputLogic={inputLogic}
                    data={tasks}
                    onSubmit={(id, values) => onSubmitTasks(id, values)}
                    onRemove={(id) => onRemoveTasks(id)}
                  />
                </div>,
              )
            }
            return rlt
          })}
        </div>
      </div>

      <Button onClick={onSubmit}>Save</Button>
    </div>
  )
}
