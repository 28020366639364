export function getTextWidth(text: string, fontSize: string = '12px', fontFamily: string = 'Work Sans'): number {
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')

  if (context) {
    context.font = `${fontSize} ${fontFamily}`
    return context.measureText(text).width
  }

  return 0
}
