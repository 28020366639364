import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'
import { Overview } from 'components/Overview'
import { AccountType } from 'config'
import { usePermissions } from 'hooks/usePermissions'
import NationalFlood from 'pages/Admin/Vendors/NationalFlood/NationalFlood'
import { DocumentsSlideNameType, FraudGaurd, Lexis, SSA, Tax } from 'pages/Loan'
import Notes from 'pages/Loan/Servicing/Notes/Notes'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Api from 'services/api'

const externalLinks = ['orderValuation']

export default function LoanInTake() {
  const [selectedMenu, setSelectedMenu] = useState('')
  const [menuItems, setMenuItems] = useState<any>({})

  const setMenu = (menuItem: string) => {
    setSelectedMenu(menuItem)
  }

  const { auth } = useSelector((state: any) => {
    return {
      auth: state.auth,
    }
  })

  const { hasPermission } = usePermissions()

  useEffect(() => {
    if (Object.keys(menuItems).length) return
    let temp: any = {}
    if (hasPermission('ADMIN_TO_AE_PROFILE_PERMISSION')) {
      if (auth.profile.accountType !== AccountType.ACCOUNT_EXECUTIVE) {
        temp['nationalFlood'] = 'National Flood'
        temp['dataverifySSA'] = 'DataVerify SSA'
        temp['dataverify4506'] = 'DataVerify 4506'
        temp['dataverifyDrive'] = 'DataVerify Drive'
        temp['lexis'] = 'SmartLinx Person Report'
      }
      temp['notes'] = 'Notes'
      temp['orderValuation'] = 'Order Valuation'
    }
    setMenuItems(temp)
    setSelectedMenu(Object.keys(temp)[0])
  }, [auth])

  const renderFragment = useMemo(() => {
    switch (selectedMenu) {
      case 'nationalFlood':
        return <NationalFlood child={true} />
      case 'dataverifySSA':
        return <SSA />
      case 'dataverify4506':
        return <Tax />
      case 'dataverifyDrive':
        return <FraudGaurd />
      case 'lexis':
        return <Lexis />
      case 'notes':
        return <Notes child={true} />
      default: {
        return <div></div>
      }
    }
  }, [selectedMenu])

  const loanNumber = Api.getLoanNumber()

  const renderMenu = (menus: Record<string, string>) => {
    return (
      <ul className="sidebar-items flex flex-col p-4 pb-10">
        {Object.keys(menus).map((item: string, index) => {
          const isActive = selectedMenu === item
          if (externalLinks.indexOf(item) !== -1) {
            return (
              <li key={index} className="py-2 hover:underline cursor-pointer">
                <Link to={`/order_valuation/${loanNumber}`} target="_blank">
                  <span className="flex gap-2 items-center">
                    <span>
                      {index + 1}. {menus[item]}
                    </span>
                    <ArrowTopRightOnSquareIcon className="w-4 h-4"></ArrowTopRightOnSquareIcon>
                  </span>
                </Link>
              </li>
            )
          }
          return (
            <li
              key={index}
              onClick={() => {
                setMenu(item)
              }}
              className="py-2"
            >
              <p className={`hover:underline cursor-pointer ${isActive ? 'border px-4 py-1 bg-zinc-100' : 'py-1'}`}>
                {index + 1}. {menus[item]}
              </p>
            </li>
          )
        })}
      </ul>
    )
  }

  return (
    <div className="py-6 px-2">
      <Overview title="Loan Intake" />
      <DocumentsSlideNameType loading={false} loadDocs={true} />
      <div className="max-w-screen-2xl m-auto grid grid-cols-12 md:gap-6">
        <div className="sidebar-left col-span-12 md:col-span-3 shrink-0 bg-white shadow1 rounded mb-4">
          {renderMenu(menuItems)}
        </div>
        <div className="relative h-fit content-right col-span-12 md:col-span-9 bg-white p-4 rounded shadow1 mb-4">
          <h2 className="text-2xl font-bold flex items-center mb-5">{menuItems[selectedMenu]}</h2>
          {renderFragment}
        </div>
      </div>
    </div>
  )
}
