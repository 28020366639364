import { GlobalConfigType, setApplicationStep } from 'actions'
import cloneDeep from 'clone-deep'
import { FieldOrigin, InputType, LoanFieldProps } from 'config'
import { visibleLoansLogic } from 'pages/LoanStructure/Logic'
import { store } from 'reducers'
import { InputValidate } from 'utils'
import { getInvisibleFields } from 'utils/loan'

import { additionalPropertyInfoValidate } from '../AdditionalPropertyInformation/logics'
import { defaultInputs } from './constants'

export const initFields = (fields: Record<string, InputType>) => {
  const visibleLoans = visibleLoansLogic()
  Object.keys(fields).map((key) => {
    if (visibleLoans.indexOf(key) !== -1) {
      fields[key].disabled = true
      fields[key].tooltip = 'Loan Structure Input Field!'
    }
  })
  if (visibleLoans.indexOf('secondLienPayment') === -1) fields['secondLienPayment'].visible = false

  const invisibleFields = getInvisibleFields(FieldOrigin.PropertyInformation)
  invisibleFields.forEach((key) => (fields[key].visible = false))

  return fields
}

export const propertyInfoValidate = (validateOnly = false, updateStore = false): any => {
  const { loan, step, globalConfigReducer } = store.getState()
  const loanFieldProperties = globalConfigReducer[GlobalConfigType.LoanFields] as LoanFieldProps
  if (((loanFieldProperties as any)['visibility'] || {})[FieldOrigin.PropertyInformation] === false) {
    if (validateOnly) return true
    else return {}
  }
  let fields = initFields(defaultInputs())
  const fieldProps = loanFieldProperties[FieldOrigin.PropertyInformation]

  let success = true
  Object.keys(fields).map((key) => {
    const error = InputValidate({ ...fields[key], value: loan[key] })
    if (error.length > 0) {
      success = false
      fields[key].error = error
    }
    if (fieldProps && fieldProps[key] && fieldProps[key].tooltip) fields[key].tooltip = fieldProps[key].tooltip
  })
  success = success && additionalPropertyInfoValidate()
  if (updateStore) {
    let application = cloneDeep(step.application)
    application.property = success ? 1 : -1
    store.dispatch(setApplicationStep(application))
  }
  if (validateOnly) return success
  return fields
}
