import cloneDeep from 'clone-deep'
import type { BaseFile, InputType } from 'config'
import moment from 'moment-timezone'
import type { ILoanGlobalSettings } from 'pages/Admin/AdminTools/Configuration/GlobalSettings'
import type { ILoan, ILoanDetail } from 'pages/LoanStructure/interfaces'
import { store } from 'reducers'

import { getPrice3decimal, removeComma } from './convertor'

export const REQUIRED_FIELD_ERROR_MESSAGE = 'Required field'
export function validateEmail(email: string) {
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export function isHTML(str: string) {
  var a = document.createElement('div')
  a.innerHTML = str

  for (var c = a.childNodes, i = c.length; i--; ) {
    if (c[i].nodeType == 1) return true
  }

  return false
}

function validateNumberLetters(val: string, count: number) {
  let numbers = 0
  val = val.toString()
  for (let i = 0; i < val.length; i += 1) {
    if (val[i] >= '0' && val[i] <= '9') numbers += 1
  }
  return numbers === count
}

function validCreditScore(val: string) {
  let numbers = 0
  val = val.toString()
  for (let i = 0; i < val.length; i += 1) {
    if (val[i] >= '0' && val[i] <= '9') numbers += 1
  }
  return numbers === 3
}

export const isValidDate = function (dateString: string) {
  try {
    var regEx = /^\d{4}-\d{2}-\d{2}$/
    if (!dateString.match(regEx)) return false // Invalid format
    var d = new Date(dateString)
    var dNum = d.getTime()
    if (!dNum && dNum !== 0) return false // NaN value, Invalid date
    return d.toISOString().slice(0, 10) === dateString
  } catch {
    return false
  }
}

export const isValidZipcode = function (value: string) {
  try {
    return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value)
  } catch {
    return false
  }
}

export function loanAmountRestriction(value: any, loanNumber: any, loanGlobalSettings: ILoanGlobalSettings) {
  const { minProposedLoanAmount, maxProposedLoanAmount } = loanGlobalSettings

  value = removeComma(value)
  let isPrevLoan = true
  if (loanNumber === 'New') isPrevLoan = false
  loanNumber = Number(loanNumber)
  if (loanNumber >= 1286 && loanNumber < 3000000) isPrevLoan = false

  let error = ''
  if (isPrevLoan) {
    if (
      removeComma(value) < removeComma(minProposedLoanAmount) ||
      removeComma(value) > removeComma(maxProposedLoanAmount)
    ) {
      error = `Must be in ${getPrice3decimal(minProposedLoanAmount)} - ${getPrice3decimal(maxProposedLoanAmount)}`
    }
  } else {
    if (
      removeComma(value) < removeComma(minProposedLoanAmount) ||
      removeComma(value) > removeComma(maxProposedLoanAmount)
    ) {
      error = `Must be in ${getPrice3decimal(minProposedLoanAmount)} - ${getPrice3decimal(maxProposedLoanAmount)}`
    }
  }

  return error
}

function asIsValueRestriction(value: any, loanNumber: any) {
  value = removeComma(value)
  let isPrevLoan = true
  if (loanNumber === 'New') isPrevLoan = false
  loanNumber = Number(loanNumber)
  if (loanNumber >= 1340 && loanNumber < 3000000) isPrevLoan = false

  let error = ''
  if (isPrevLoan) {
    if (removeComma(value) < 20000) {
      return 'Minimum value is 20,000'
    }
  } else {
    if (removeComma(value) < 50000) {
      return 'Minimum value is 50,000'
    }
  }
  return error
}

export const InputValidate = function (
  data: any,
  loanNumber: any = -1,
  loanDetail: ILoanDetail | undefined = undefined,
  loan: ILoan | undefined = undefined,
) {
  let error = ''
  let {
    options,
    key = '',
    value = '',
    inputType,
    visible = true,
    required = false,
    disabled = false,
    isPastDate = false,
    allowDefaultOption,
    // length = 0,
    // minLength = 0,
    maxLength = 0,
  } = data
  if (!visible) return ''
  if (disabled) return ''
  // if (value && length && value.length != length) return `Length must be ${length}.`
  // if (value && minLength && value.length < minLength) return `Minimum length must be ${minLength}.`
  if (['undefined', 'null'].indexOf(value) !== -1) value = null
  if (value && maxLength && value.length > maxLength) return `Maximum length must be ${maxLength}.`
  if (!required) return ''
  if (['section', 'group', 'divider', 'alert'].indexOf(inputType.toLowerCase()) !== -1) return ''
  if (value === null || value.toString().length === 0) error = REQUIRED_FIELD_ERROR_MESSAGE
  if (inputType.toLowerCase() === 'select') {
    if (value === '') {
      if (allowDefaultOption) error = ''
      else error = REQUIRED_FIELD_ERROR_MESSAGE
    } else {
      if (Array.isArray(options)) {
        if (options.indexOf(value) === -1) error = REQUIRED_FIELD_ERROR_MESSAGE
      } else {
        if (options[value] === undefined) error = REQUIRED_FIELD_ERROR_MESSAGE
      }
    }
  } else if (inputType.toLowerCase() === 'table') {
    if (value.length == 0) error = REQUIRED_FIELD_ERROR_MESSAGE
  }

  if (inputType.toLowerCase() === 'multiselect') {
    const selected = Array.isArray(value) ? value : Object.keys(value).filter((key) => value[key])
    if (!selected.length) return REQUIRED_FIELD_ERROR_MESSAGE
  }

  if (inputType.toLowerCase() === 'filetable') {
    if (!value.length) return REQUIRED_FIELD_ERROR_MESSAGE
  }

  if (['estimatedCreditScore', 'proposedLoanAmount'].includes(key)) {
    if (!loanDetail) loanDetail = store.getState().loanDetail
    if (!loan) loan = store.getState().loan
  }
  switch (key) {
    case 'proposedMonthlyRent':
      if (removeComma(value) <= 0) error = `Must be above than 0`
      break
    case 'proposedOccupancy':
      if (value === 'Owner Occupied') error = `Can not be 'Owner Occupied'`
      break
    case 'proposedLoanAmount':
      return loanAmountRestriction(value, loanNumber, loanDetail?.loanGlobalSettings as ILoanGlobalSettings)
    case 'asIsValue':
      return asIsValueRestriction(value, loanNumber)
    case 'estimatedCreditScore':
      const { minCreditScore } = loanDetail?.loanGlobalSettings as ILoanGlobalSettings
      if (!loan?.isNoScore && removeComma(value) < removeComma(minCreditScore)) {
        return `Must be greater than ${minCreditScore}`
      }
      if (loan?.isNoScore) return ''
      break
    case 'bankRoutingNumber':
      if (!validateNumberLetters(value, 9)) {
        return `Bank Routing Number must be 9 digits.`
      }
      break
    case 'zipCode':
      if (!isValidZipcode(value)) {
        return `Invalid Code.`
      }
      break
  }
  if (error.length > 0) return error
  if (data.inputType.toLowerCase() === 'autocomplete') return error
  try {
    switch (data.type) {
      case 'date':
        if (!isValidDate(data.value)) {
          error = 'Invalid Date Format.'
        } else if (isPastDate && new Date(data.value) > new Date()) error = 'This date cannot be a future date.'
        break
      case 'email':
        if (!validateEmail(data.value)) {
          error = 'Email address is not in a valid format.'
        }
        break
      case 'phone':
        if (!validateNumberLetters(data.value, 10)) {
          error = 'Must be 10 digits.'
        }
        break
      case 'ssn':
      case 'entityTaxID':
        if (!validateNumberLetters(data.value, 9)) {
          error = 'Must be 9 digits.'
        }
        break
      case 'creditScore':
        if (!loan?.isNoScore && !validCreditScore(data.value)) {
          error = 'Must be 3 digits.'
        }
        break
    }
  } catch {}
  return error
}

export const validDateMMDDYYYY = (date: string) => {
  let rlt = false
  try {
    rlt = moment(date, 'MM/DD/YYYY', true).isValid()
  } catch {
    rlt = false
  }
  return rlt
}

export const isEmpty = (value: any): boolean =>
  value === undefined ||
  value === null ||
  (typeof value === 'object' && Object.keys(value).length === 0) ||
  (typeof value === 'string' && value.trim().length === 0)

const keyMap: Record<string, string> = {
  accountExecutive: 'AccountExecutive',
  broker: 'Broker',
  creator: 'Creator',
  loanOfficer: 'LoanOfficer',
  loanProcessor: 'LoanProcessor',
  underwriter: 'Underwriter',
}

export const getUnknownKeyInPartiesEmailTemplate = (content: string) => {
  const partyMaps = getPartiesMap({})
  while (true) {
    const matched = content.match(/{{.+?}}/)
    if (!matched) break
    const foundBlock = matched[0]
    const foundKey = foundBlock.replace(/{|}/g, '')
    if (partyMaps[foundKey] !== undefined) content = content.replace(foundBlock, partyMaps[foundKey])
    else return foundKey
  }
  return null
}

export const getPartiesMap = (parties: Record<string, any>) => {
  let partiesMap: Record<string, string> = {
    LoanNumber: '',
    SubjectPropertyAddress: '',
    BorrowerName: '',
  }
  Object.keys(keyMap).map((key) => {
    let { email, name, phone } = parties[key] || {}
    partiesMap[`${keyMap[key]}Name`] = name || ''
    partiesMap[`${keyMap[key]}Email`] = email || ''
    partiesMap[`${keyMap[key]}Phone`] = phone || ''
  })
  return partiesMap
}

export const isDateRangeIncludingToday = (fromDate: string, toDate: string) => {
  const today = moment()
  const fromDateMoment = moment(fromDate)
  const toDateMoment = moment(toDate)

  // Set the time part of today to be midnight
  today.startOf('day')

  return today.isBetween(fromDateMoment, toDateMoment, null, '[]')
}

export const validateForm = ({ inputs, setInputs }: { inputs: Record<string, InputType>; setInputs: Function }) => {
  let hasError = false
  let newInputs = cloneDeep(inputs)
  const data: Record<string, any> = {}
  for (const key in inputs) {
    const input = newInputs[key]
    if (input.visible === false || (input.visible && typeof input.visible == 'function' && !input.visible(inputs)))
      continue

    if (input.value && input.value.trim) input.value = input.value.trim()
    input.error = InputValidate(input)
    data[key] = input.value
    if (input.error) hasError = true
    if (input.type == 'thousandSep') data[key] = removeComma(data[key])
  }

  if (hasError) setInputs(newInputs)

  return {
    hasError,
    data,
    newInputs,
  }
}

export const isBaseFile = (value: any): value is BaseFile => {
  return (
    value &&
    typeof value.id === 'number' &&
    typeof value.name === 'string' &&
    typeof value.fileKey === 'string' &&
    typeof value.createdAt === 'number'
  )
}
