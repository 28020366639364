import { GlobalConfigType, setApplicationStep } from 'actions'
import cloneDeep from 'clone-deep'
import { LoanFieldProps } from 'config'
import { store } from 'reducers'
import { isEmpty, removeComma } from 'utils'

import { stepApplicationKeys } from '../logic'
import { BorrowerSeperator } from './constants'

export const creditValidate = (borrowerSeperator: string, updateStore = false, afterRemoved = false) => {
  const { borrower, step, globalConfigReducer } = store.getState()

  const loanFieldProperties = globalConfigReducer[GlobalConfigType.LoanFields] as LoanFieldProps
  if (((loanFieldProperties as any)['visibility'] || {})['credit'] === false) {
    return true
  }

  let success = true
  const data = borrower[borrowerSeperator]
  if (!data || !data.creditRequestType || !data.equifax || !data.experian || !data.transunion) success = false

  if (afterRemoved) success = false
  if (updateStore) {
    let application = cloneDeep(step.application)
    const key = stepApplicationKeys[borrowerSeperator].credit
    application[key] = success ? 1 : -1
    store.dispatch(setApplicationStep(application))
  }

  return success
}

const getMiddleValue = (a: number, b: number, c: number) => {
  let zeros = 0
  if (a <= 0) zeros += 1
  if (b <= 0) zeros += 1
  if (c <= 0) zeros += 1

  const minValue = Math.min(a, b, c)
  const maxValue = Math.max(a, b, c)

  let middleValue = a + b + c - minValue - maxValue
  if (zeros >= 2) middleValue = maxValue

  return middleValue
}

export const isSameStructureApplicationCreditScore = () => {
  const { loan, borrower } = store.getState()
  let { estimatedCreditScore, isNoScore } = loan
  if (isNoScore) return { same: true, value: 0, error: '' }
  const { equifax, experian, transunion } = borrower['borrower']
  estimatedCreditScore = removeComma(estimatedCreditScore)

  let middleValue = getMiddleValue(removeComma(equifax), removeComma(experian), removeComma(transunion))

  if (loan.includeCoborrower) {
    let { equifax, experian, transunion } = borrower['coBorrower']
    const coBorrowerMiddleScore = getMiddleValue(removeComma(equifax), removeComma(experian), removeComma(transunion))
    middleValue = Math.min(middleValue, coBorrowerMiddleScore)
  }

  const priceScore = borrower[BorrowerSeperator.BORROWER].priceScore

  if (!isEmpty(priceScore)) {
    const options = getPriceScoreOptions()

    if (options.includes(priceScore)) middleValue = priceScore
  }

  if (middleValue == estimatedCreditScore) return { same: true, value: middleValue, error: '' }
  return { same: false, value: middleValue, error: `Credit Score does not match Credit Report (${middleValue})` }
}

export const getPriceScoreOptions = () => {
  const { borrower, loanDetail } = store.getState()
  const options: string[] = []

  ;['equifax', 'transunion', 'experian'].forEach((key) => {
    if (!isEmpty(borrower[BorrowerSeperator.BORROWER][key]))
      if (!options.includes(borrower[BorrowerSeperator.BORROWER][key]))
        options.push(borrower[BorrowerSeperator.BORROWER][key])

    if (loanDetail.includeCoborrower)
      if (!isEmpty(borrower[BorrowerSeperator.CO_BORROWER][key]))
        if (!options.includes(borrower[BorrowerSeperator.CO_BORROWER][key]))
          options.push(borrower[BorrowerSeperator.CO_BORROWER][key])
  })

  options.sort()

  return options
}
