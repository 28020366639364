import { API_ADD_TICKET_MESSAGE, API_DELETE_TICKET, API_GET_ALL_MESSAGES_FILTER, API_GET_TICKETS_FILTER } from 'config'
import Api from 'services/api'

export const getTickets = (filterData: Record<string, any>) => {
  return Api.get(API_GET_TICKETS_FILTER, filterData)
}

export const getAllTicketMessages = (ticketId: string, stackNum: number = 1) => {
  return Api.get(API_GET_ALL_MESSAGES_FILTER, {}, { ticketId, stackNum })
}

export const sendTicketMessage = (data: Record<string, any>) => {
  return Api.post(API_ADD_TICKET_MESSAGE, data)
}

export const deleteTicket = (id: number) => {
  return Api.delete(API_DELETE_TICKET, {}, { id })
}

export const createTicket = (data: Record<string, any>) => {
  return Api.post(API_GET_TICKETS_FILTER, data)
}
