import { GlobalConfigType } from 'actions'
import { visibilitySections } from 'pages/Admin/AdminTools/Configuration/LoanFieldsProperties/inputs'
import { validateLoanStructureData } from 'pages/LoanStructure/Logic'
import { store } from 'reducers'
import { LoanType } from 'reducers/loanDetail.reducer'
import { hasAuthPermission } from 'utils/hasAuthPermission'

import { BorrowerSeperator } from './CreditScore/constants'

type menuType = {
  [key: string]: string
}

export const getAppBorrowerMenu = (borrowerApplication: boolean = false) => {
  const { loan, borrower, globalConfigReducer } = store.getState()
  let isCorrespondentLoan = loan.type == LoanType.CORRESPONDENT

  let rlt: menuType = {
    borrower: 'Borrower Information',
    property: 'Property Information',
    asset: 'Asset & Liability',
    track: 'Track Record',
    hmda: 'Declarations & HMDA',
    sign: 'Acknowledgement & Sign',
    credit: 'Credit Score',
    additionalMember: 'Additional Entity Members',
  }
  if (borrower.borrower?.additionalMembers?.length > 0)
    rlt.additionalMember += ` (${borrower.borrower?.additionalMembers?.length})`
  if (borrowerApplication) delete rlt.credit
  if (isCorrespondentLoan) {
    // delete rlt.sign
    delete rlt.credit
  }

  const visibilityProps = globalConfigReducer[GlobalConfigType.LoanFields]['visibility'] || {}
  Object.keys(visibilitySections).forEach((fieldOrigin) => {
    if (visibilityProps[fieldOrigin] === false) delete rlt[(visibilitySections as any)[fieldOrigin]]
  })
  return rlt
}

export const getAppCoBorrowerMenu = (
  borrowerApplication: boolean = false,
  borrowerSeparator: string = 'coBorrower',
) => {
  const { loan, globalConfigReducer } = store.getState()
  let isCorrespondentLoan = loan.type == LoanType.CORRESPONDENT
  let coBorrwerIdx = borrowerSeparator === 'coBorrower' ? 1 : borrowerSeparator === 'coBorrower2' ? 2 : 3
  coBorrwerIdx += 1

  let rlt: menuType = {
    [`borrower${coBorrwerIdx}`]: 'Borrower Information',
    [`hmda${coBorrwerIdx}`]: 'Declarations & HMDA',
    [`sign${coBorrwerIdx}`]: 'Acknowledgement',
    [`credit${coBorrwerIdx}`]: 'Credit Score',
  }
  if (borrowerApplication) delete rlt[`credit${coBorrwerIdx}`]
  if (isCorrespondentLoan) {
    delete rlt[`credit${coBorrwerIdx}`]
  }

  const visibilityProps = globalConfigReducer[GlobalConfigType.LoanFields]['visibility'] || {}
  Object.keys(visibilitySections).forEach((fieldOrigin) => {
    if (visibilityProps[fieldOrigin] === false) delete rlt[`${(visibilitySections as any)[fieldOrigin]}${coBorrwerIdx}`]
  })
  return rlt
}

const getMapKeys = () => {
  const borrowerMenus = getAppBorrowerMenu()
  const coBorrowerMenus = getAppCoBorrowerMenu()
  return {
    ...borrowerMenus,
    ...coBorrowerMenus,
  }
}

const scoreValidateMenus: any = {
  [BorrowerSeperator.BORROWER]: ['property', 'borrower', 'asset', 'track', 'hmda', 'sign'],
  [BorrowerSeperator.CO_BORROWER]: ['borrower2', 'hmda2', 'sign2'],
  [BorrowerSeperator.CO_BORROWER2]: ['borrower2', 'hmda2', 'sign2'],
  [BorrowerSeperator.CO_BORROWER3]: ['borrower2', 'hmda2', 'sign2'],
}

const signValidateMenus: any = {
  [BorrowerSeperator.BORROWER]: ['property', 'borrower', 'asset', 'track', 'hmda'],
  [BorrowerSeperator.CO_BORROWER]: ['borrower2', 'hmda2'],
  [BorrowerSeperator.CO_BORROWER2]: ['borrower2', 'hmda2'],
  [BorrowerSeperator.CO_BORROWER3]: ['borrower2', 'hmda2'],
}

export const pullScoreValidation = (borrowerSeperator: string): string[] => {
  const { step, auth } = store.getState()
  const noRequireSignValidation = hasAuthPermission(auth, 'ADMIN_TO_AE_PROFILE_PERMISSION')
  const { application } = step
  let rlt: any = []
  const mapKeys = getMapKeys()
  scoreValidateMenus[borrowerSeperator].map((key: string) => {
    if (key == 'asset') return
    if (application[key] === -1) {
      if (['sign', 'sign2'].indexOf(key) !== -1 && noRequireSignValidation) {
      } else {
        let text = ''
        if (borrowerSeperator === 'coBorrower') text = `Co-Borrower's `
        rlt.push(`- ${text} ${mapKeys[key]} Need to be Completed!`)
      }
    }
  })
  return rlt
}

export const signApplictionValidation = () => {
  const { step, borrower, loanDetail, loan } = store.getState()
  const { application } = step
  const { includeCoborrower } = loanDetail
  let borrowerKeys = ['borrower']
  if (includeCoborrower) borrowerKeys.push('coBorrower')
  let rlt: any = []
  const mapKeys = getMapKeys()
  const loanStructureErrors = validateLoanStructureData(loan, loanDetail.rateData)
  rlt.push(...loanStructureErrors)
  borrowerKeys.map((borrowerSeperator) => {
    signValidateMenus[borrowerSeperator].map((key: string) => {
      if (key == 'asset') return
      if (application[key] === -1) {
        let text = ''
        if (borrowerSeperator === 'coBorrower') text = `Co-Borrower's `
        rlt.push(`${text} ${mapKeys[key]} Need to be Completed!`)
      }
    })
  })
  if (![LoanType.CORRESPONDENT, LoanType.TABLEFUNDER].includes(loanDetail.loanType)) {
    if (borrower.borrower.agreeToSign !== true) rlt.push(`Borrower Need to Agree on Acknowledgement!`)
    if (includeCoborrower && borrower.coBorrower.agreeToSign !== true)
      rlt.push(`Co-Borrower Need to Agree on Acknowledgement!`)
  }
  return rlt
}

export const stepApplicationKeys: Record<any, any> = {
  [BorrowerSeperator.BORROWER]: {
    borrower: 'borrower',
    property: 'property',
    asset: 'asset',
    hmda: 'hmda',
    sign: 'sign',
    credit: 'credit',
    additionalMember: 'additionalMember',
  },
  [BorrowerSeperator.CO_BORROWER]: {
    borrower: 'borrower2',
    hmda: 'hmda2',
    sign: 'sign2',
    credit: 'credit2',
  },
  [BorrowerSeperator.CO_BORROWER2]: {
    borrower: 'borrower3',
    hmda: 'hmda3',
    sign: 'sign3',
    credit: 'credit3',
  },
  [BorrowerSeperator.CO_BORROWER3]: {
    borrower: 'borrower4',
    hmda: 'hmda4',
    sign: 'sign4',
    credit: 'credit4',
  },
}
