import { GlobalConfigType, setApplicationStep } from 'actions'
import cloneDeep from 'clone-deep'
import { FieldOrigin, LoanFieldProps } from 'config'
import moment from 'moment-timezone'
import { visibleLoansLogic } from 'pages/LoanStructure/Logic'
import { store } from 'reducers'
import { getPrice3decimal, removeComma } from 'utils'

export const trackRecordValidate = (hasTrackRecord: any, tracks: any, updateStore = false) => {
  let success = true,
    passedTracks = 0
  const requiredTracks = howManyTrackRecord()

  tracks.map((item: any) => {
    passedTracks += trackStatusCheck(item) ? 1 : 0
  })

  if (hasTrackRecord === undefined || hasTrackRecord === null) success = false
  else if (hasTrackRecord === true) {
    if (passedTracks < requiredTracks) {
      success = false
    }
  } else if (hasTrackRecord === false) {
    if (requiredTracks > 0) success = false
  }

  if (updateStore) {
    const { step } = store.getState()
    let application = cloneDeep(step.application)
    application['track'] = success ? 1 : -1
    store.dispatch(setApplicationStep(application))
  }
  return success
}

export const howManyTrackRecord = () => {
  let rlt = 0
  const visibleFields = visibleLoansLogic()
  const { loan } = store.getState()
  try {
    if (visibleFields.includes('howManyExperiences')) rlt = Number(loan?.howManyExperiences)
    else if (visibleFields.includes('experience')) rlt = Number(loan.experience?.split(' ')[0])
  } catch {}
  return rlt
}

export const trackStatusCheck = (data: any, withError: boolean = false) => {
  let success = true
  let errors = []

  const { loan, loanDetail, globalConfigReducer } = store.getState()

  const loanFieldProperties = globalConfigReducer[GlobalConfigType.LoanFields] as LoanFieldProps
  if (((loanFieldProperties as any)['visibility'] || {})[FieldOrigin.TrackRecord] === false) {
    if (withError) {
      return {
        success,
        errors: [],
      }
    }
    return success
  }
  const {
    [FieldOrigin.TrackRecord]: {
      purchaseDateBeforeLoanDate: { defaultValue: purchaseDateBeforeLoanDate = true } = {},
      addressDifferentPropertyAddr: { defaultValue: addressDifferentPropertyAddr = true } = {},
      purchaseDateBeforeSoldDate: { defaultValue: purchaseDateBeforeSoldDate = true } = {},
      soldDateBeforeLoanDate: { defaultValue: soldDateBeforeLoanDate = true } = {},
      soldDateWithin30monthsLoanDate: {
        defaultValue: { enabled: soldDateWithin30monthsLoanDate = true, value: soldDateWithinMonths = 36 } = {},
      } = {},
      purchaseDateWithin24monthsSoldDate: {
        defaultValue: { enabled: purchaseDateWithin24monthsSoldDate = true, value: purchaseDateWithinMonths = 24 } = {},
      } = {},
      soldPriceLessthan75AfterRepairValue: {
        defaultValue: {
          enabled: soldPriceLessthan75AfterRepairValue = true,
          value: soldPriceLessthanPercent = 75,
        } = {},
      } = {},

      purchaseDateBeforeLoanDateYes: { defaultValue: purchaseDateBeforeLoanDateYes = true } = {},
      addressDifferentPropertyAddrYes: { defaultValue: addressDifferentPropertyAddrYes = true } = {},
      purchaseDateWithin24monthsExistingClosingDate: {
        defaultValue: {
          enabled: purchaseDateWithin24monthsExistingClosingDate = true,
          value: purchaseDateWithinMonthsYes = 24,
        } = {},
      } = {},
      purchaseDateFrom24monthsExistingClosingDate: {
        defaultValue: {
          enabled: purchaseDateFrom24monthsExistingClosingDate = true,
          value: purchaseDateFromMonthsYes = 12,
        } = {},
      } = {},
      purchasePriceLessthan75TransactionPurPrice: {
        defaultValue: {
          enabled: purchasePriceLessthan75TransactionPurPrice = true,
          value: purchasePriceLessthanYes = 75,
        } = {},
      } = {},
    } = {},
  } = loanFieldProperties

  const { loanProcessDate, closingDate: estimatedClosingDate } = loanDetail
  const { subjectPropertyAddress, propertyPurchasePrice } = loan
  const visibleFields = visibleLoansLogic()

  errors.push(`Loan Date: ${loanProcessDate}`)

  if (data.override) {
  } else {
    if (data.asRental === false) {
      if (
        purchaseDateBeforeLoanDate &&
        moment(new Date(loanProcessDate)).isBefore(moment(new Date(data.purchaseDate)))
      ) {
        success = false
        errors.push(`Purchase Date must be before Loan Date.`)
      }
      if (addressDifferentPropertyAddr && data.propertyAddress == subjectPropertyAddress) {
        success = false
        errors.push(`Address cannot be the same as Subject Property Address.`)
      }

      if (
        purchaseDateBeforeSoldDate &&
        moment(new Date(data.salesDate)).isBefore(moment(new Date(data.purchaseDate)))
      ) {
        success = false
        errors.push(`Purchase Date must be before Sold Date.`)
      }
      if (soldDateBeforeLoanDate && moment(new Date(loanProcessDate)).isBefore(moment(new Date(data.salesDate)))) {
        success = false
        errors.push(`Sold Date must be before Loan Date.`)
      }

      if (
        soldDateWithin30monthsLoanDate &&
        moment(new Date(loanProcessDate)).isAfter(moment(new Date(data.salesDate)).add(soldDateWithinMonths, 'months'))
      ) {
        success = false
        errors.push(`Sold Date must be within ${soldDateWithinMonths} months of Loan Date.`)
      }
      if (
        purchaseDateWithin24monthsSoldDate &&
        moment(new Date(data.salesDate)).isAfter(
          moment(new Date(data.purchaseDate)).add(purchaseDateWithinMonths, 'months'),
        )
      ) {
        success = false
        errors.push(`Purchase Date must be within ${purchaseDateWithinMonths} months of Sold Date.`)
      }

      if (visibleFields.includes('afterRepairValue')) {
        if (
          soldPriceLessthan75AfterRepairValue &&
          removeComma(data.salesPrice) / removeComma(loan.afterRepairValue) < soldPriceLessthanPercent / 100
        ) {
          success = false
          errors.push(`Sold Price, cannot be less than ${soldPriceLessthanPercent}% of After Repair Value.`)
          errors.push(`After Repair Value: $${getPrice3decimal(loan.afterRepairValue)}`)
        }
      }
    } else {
      if (
        purchaseDateBeforeLoanDateYes &&
        moment(new Date(loanProcessDate)).isBefore(moment(new Date(data.purchaseDate)))
      ) {
        success = false
        errors.push(`Purchase Date must be before Loan Date.`)
      }
      if (addressDifferentPropertyAddrYes && data.propertyAddress == subjectPropertyAddress) {
        success = false
        errors.push(`Address cannot be the same as Subject Property Address.`)
      }

      if (
        purchaseDateWithin24monthsExistingClosingDate &&
        moment(new Date(estimatedClosingDate)).isAfter(
          moment(new Date(data.purchaseDate)).add(purchaseDateWithinMonthsYes, 'months'),
        )
      ) {
        success = false
        errors.push(`Purchase Date must be within ${purchaseDateWithinMonthsYes} months of existing closing date.`)
      }

      if (
        purchaseDateFrom24monthsExistingClosingDate &&
        moment(new Date(estimatedClosingDate)).isBefore(
          moment(new Date(data.purchaseDate)).add(purchaseDateFromMonthsYes, 'months'),
        )
      ) {
        success = false
        errors.push(`Purchase Date must be at least ${purchaseDateFromMonthsYes} months from existing closing date.`)
      }

      if (visibleFields.includes('afterRepairValue')) {
        if (
          purchasePriceLessthan75TransactionPurPrice &&
          removeComma(data.purchasePrice) / removeComma(propertyPurchasePrice) < purchasePriceLessthanYes / 100
        ) {
          success = false
          errors.push(`Purchase Price must be within ${purchasePriceLessthanYes}% of transaction Purchase Price.`)
          errors.push(`Transaction Purchase Price: $${getPrice3decimal(propertyPurchasePrice)}`)
        }
      }
    }
  }
  if (withError) {
    return {
      success,
      errors,
    }
  }
  return success
}
