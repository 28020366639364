import {
  ArrowDownTrayIcon,
  ArrowTrendingUpIcon,
  CheckCircleIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  EyeIcon,
  PaperAirplaneIcon,
  Square2StackIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { Overview } from 'components/Overview'
import { AccountType, COMPANY_ADDRESS, COMPANY_TITLE } from 'config'
import type { InputType } from 'config/input.type.constants'
import { usePermissions } from 'hooks/usePermissions'
import { SettingKeyContractorDescription } from 'pages/Admin'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { openS3Document } from 'services'
import {
  getAdminConfig,
  getContractorApplication,
  getContractorAppPDF,
  sendContractorApplication,
  updateContractorApplication,
} from 'services/apis'
import { getSetting } from 'services/apis/admin'
import { Button, FormTable, FormTableHeader, PlainTable } from 'stories/components'
import { Tooltip } from 'stories/components/Tooltip/Tooltip'
import {
  capitalize,
  confirm,
  formatTime,
  InputConvert,
  InputValidate,
  openAuditLog,
  renderTableHistoryValue,
  replaceNextLineToBr,
  validateEmail,
} from 'utils'
import { copyClipboard } from 'utils/copyClipboard'
import { RenderInput } from 'utils/RenderInput'
import { setLoanNumber } from 'utils/setLoanNumber'

import { ContractorDocuments } from './ContractorDocuments'
import { ContractorSignDialog } from './ContractorSignDialog'

export * from './ContractorDocuments'
export * from './type'

export default function ContractorApplication(props: any) {
  const { no } = useParams() as any
  const loanNumber = setLoanNumber()
  const [menus, setMenus] = useState<Record<string, string>>({
    welcome: 'To Whom It May Concern',
    general: 'Contractor General Information',
    capability: 'Statement of Capabilities',
    subcontractor: 'Subcontractor References',
    supplier: 'Supplier References',
    client: 'Client References',
    track: 'Construction Track Records',
    sign: 'Acknowledgement & Sign',
  })
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState<Record<string, boolean>>({
    general: false,
    capability: false,
    subcontractor: false,
    supplier: false,
    client: false,
    track: false,
    sign: false,
  })
  const [activeMenu, setActiveMenu] = useState('welcome')
  const [partyContractorInfo, setPartyContractorInfo] = useState<any>({})
  const [contractorGeneralInfo, setContractorGeneralInfo] = useState<Record<string, InputType>>({})
  const [capabilityInfo, setCapabilityInfo] = useState<Record<string, InputType>>({})
  const [references, setReferences] = useState<any>({
    subcontractor: {
      inputs: {},
      data: [],
    },
    supplier: {
      inputs: {},
      data: [],
    },
    client: {
      inputs: {},
      data: [],
    },
    track: {
      inputs: {},
      data: [],
    },
  })
  const [fieldValueChanged, setFieldValueChanged] = useState(false)
  const [showSignModal, setShowSignModal] = useState(false)
  const [lockContractor, setLockContractor] = useState(false)
  const [approveContractor, setApproveContractor] = useState(false)
  const [agreeSign, setAgreeSign] = useState(false)
  const [signData, setSignData] = useState<any>({})
  const [signContent, setSignContent] = useState([])
  const [action, setAction] = useState('')
  const [welcomeText, setWelcomeText] = useState('')
  const [requiredTracks, setRequiredTracks] = useState<number>(5)
  const { hasPermission } = usePermissions()
  const isAdmin = hasPermission(['MANAGE_ADMIN_TOOLS', 'MANAGE_APPRAISER_SETTLEMENT_CONTRACTOR'])

  const { auth } = useSelector((state: any) => {
    return {
      auth: state.auth,
    }
  })

  useEffect(() => {
    getSetting(SettingKeyContractorDescription).then(({ value }) => setWelcomeText(value))
  }, [])

  const canEdit = useMemo(() => {
    let rlt = true
    if (signData.fileKey) rlt = false
    if (lockContractor) rlt = false
    const matched = auth?.role?.role?.permissions?.find(
      (permissionName: string) => permissionName === 'ANYTIME_CAN_EDIT_LOAN_APPLICATION_STRUCTURE',
    )
    if (matched) rlt = true
    if ([AccountType.VENDOR_APPROVAL, AccountType.FEASIBILITY_ANALYST].includes(auth.profile.accountType)) rlt = true
    return rlt
  }, [auth, signData, lockContractor])

  const fullPermission = useMemo(() => {
    return [
      AccountType.ADMIN,
      AccountType.VENDOR_APPROVAL,
      AccountType.DRAW_SPECIALIST,
      AccountType.FEASIBILITY_ANALYST,
    ].includes(auth.profile.accountType)
  }, [auth.profile])

  const contractorURL = props.contractor

  const checkReferenceStatus = (data: any, temp: any) => {
    temp['subcontractor'] = data.subcontractor.data.length >= 3
    temp['supplier'] = data.supplier.data.length >= 3
    temp['client'] = data.client.data.length >= 3
    temp['track'] = data.track.data.length >= requiredTracks
    return temp
  }

  const init = async () => {
    setLoading(true)
    const [res, rules] = await Promise.all([getContractorApplication(no), getAdminConfig('rules')])
    setRequiredTracks(Number(rules.constructionTrackValidation))
    if (res.success) {
      let temp = cloneDeep(status)
      const _contractorGeneralInfo = checkValidationStatus('general', res.contractorGeneralInfo, false)
      setContractorGeneralInfo(_contractorGeneralInfo.data)
      temp['general'] = _contractorGeneralInfo.success

      const _capabilityInfo = checkValidationStatus('capability', res.capabilityInfo, false)
      setCapabilityInfo(_capabilityInfo.data)
      temp['capability'] = _capabilityInfo.success

      setStatus(checkReferenceStatus(res.references, temp))
      setReferences(res.references)
      setLockContractor(res.lockContractor)
      setApproveContractor(res.approveContractor)
      setAgreeSign(res.agreeSign)
      setSignData(res.signData)
      setSignContent(res.signContent)
      setPartyContractorInfo(res.partyContractorInfo)
      if (props.menu) setActiveMenu(props.menu)
    }
    setLoading(false)
  }

  useEffect(() => {
    let success = true
    Object.keys(status).map((key) => {
      if (!status[key]) success = false
    })
    if (props.appStatusChange) props.appStatusChange(success)
  }, [status])

  useEffect(() => {
    setStatus(checkReferenceStatus(references, status))
  }, [references])

  useEffect(() => {
    let temp = cloneDeep(status)
    temp.sign = agreeSign && signData.fileKey?.length > 0
    setStatus(temp)
  }, [agreeSign, signData])

  useEffect(() => {
    setLoanNumber()
    if (!contractorURL) {
      let temp = cloneDeep(menus)
      temp.documents = 'Contractor Documents'
      setMenus(temp)
    }
    init()
  }, [])

  const renderPreNext = useMemo(() => {
    let orderMenuKeys: any = {}
    let currentOrder = -1

    Object.keys(menus).map((key: string, index: number) => {
      orderMenuKeys[index] = key
      if (key === activeMenu) currentOrder = index
    })

    let prevKey = '',
      nextKey = ''
    try {
      prevKey = orderMenuKeys[currentOrder - 1]
    } catch {}
    try {
      nextKey = orderMenuKeys[currentOrder + 1]
    } catch {}

    const cn = `px-9 py-1 border border-shade-blue rounded cursor-pointer text-shade-blue hover:bg-shade-blue hover:text-white`
    return (
      <div className="flex flex-wrap gap-4 justify-center my-5">
        {prevKey && (
          <div className={cn} onClick={() => setActiveMenu(prevKey)}>
            <span className="">
              <ChevronLeftIcon className="w-6 h-6"></ChevronLeftIcon>
            </span>
          </div>
        )}
        {nextKey && (
          <div className={cn} onClick={() => setActiveMenu(nextKey)}>
            <span className="">
              <ChevronRightIcon className="w-6 h-6"></ChevronRightIcon>
            </span>
          </div>
        )}
      </div>
    )
  }, [activeMenu])

  const welcomeFragment = (
    <div className="md:px-2">
      <div>
        <span className="font-semibold">Congratulations!</span> One of our clients has chosen your firm as the
        contractor for their construction project. To be qualified for a{' '}
        <span className="font-semibold">{COMPANY_TITLE}</span> funded project, we will need some additional information:
      </div>
      <div className="mt-5">
        <p dangerouslySetInnerHTML={{ __html: replaceNextLineToBr(welcomeText) }} className="mb-4 ml-4" />
      </div>
      <div className="mt-5">
        You will need to complete this process only once. Subsequently, we perform an annual update of licenses and
        insurance.
        <br />
        Thank you for your assistance in the process and for helping make this project a successful one.
      </div>
      <div className="mt-3">
        Regards,
        <br />
        Construction Management
        <br />
        {COMPANY_TITLE}
        <br />
        {COMPANY_ADDRESS}
      </div>
    </div>
  )

  const checkValidationStatus = (key: string, data: any, updateStatus: boolean = true) => {
    let temp = cloneDeep(status)
    let success = true
    Object.keys(data).map((key) => {
      data[key].error = InputValidate(data[key])
      if (data[key].error.length) success = false
    })
    temp[key] = success
    if (updateStatus) setStatus(temp)

    return {
      data,
      success,
    }
  }

  const onChangeLockContractor = async (key: string, value: boolean) => {
    let json: any = {
      [key]: value,
    }
    if (!value) {
      if (signData.fileKey) {
        const result = await confirm('The contractor needs to sign the application again.<br/>Do you want to proceed?')
        if (!result) return
      }
      json.signData = {}
    }
    setLoading(true)
    await updateContractorApplication(no, json)
    setLockContractor(value)
    if (!value) setSignData({})
    setLoading(false)
  }

  const onChangeApproveContractor = async (key: string, value: boolean) => {
    if (value) {
      const result = await confirm(
        'Are you sure you are going to approve this contractor?<br/>You must make sure that all necessary documents are uploaded by the contractor, and then you approved it.',
      )
      if (!result) return
    }
    setLoading(true)
    await updateContractorApplication(no, { [key]: value })
    setApproveContractor(value)
    setLoading(false)
  }

  const onChangeAgreeSign = async (key: string, value: boolean) => {
    setLoading(true)
    await updateContractorApplication(no, { [key]: value })
    setAgreeSign(value)
    setLoading(false)
  }

  const onChangeCapabilityInfo = async (key: string, value: string) => {
    setFieldValueChanged(true)
    let newInputs = cloneDeep(capabilityInfo)
    newInputs[key].value = InputConvert(newInputs[key], value)
    setCapabilityInfo(checkValidationStatus('capability', newInputs).data)
  }

  const onChangeGeneralInfo = async (key: string, value: string) => {
    setFieldValueChanged(true)
    let newInputs = cloneDeep(contractorGeneralInfo)
    newInputs[key].value = InputConvert(newInputs[key], value)
    setContractorGeneralInfo(checkValidationStatus('general', newInputs).data)
  }

  const showHistory = async (key: string, headers: Array<FormTableHeader> = []) => {
    const options = {
      table: 'Contractor',
      field: `${key}`,
      keys: {
        no,
        field: key,
      },
      renderValue: headers.length ? (data: any) => renderTableHistoryValue(data, headers) : null,
    }
    openAuditLog(options)
  }

  const onBlurGeneralInfo = async (key: string) => {
    if (!fieldValueChanged) return
    setFieldValueChanged(false)
    const input: any = contractorGeneralInfo[key]
    if (input.error && input.error.length > 0) return
    setLoading(true)
    await updateContractorApplication(no, { [key]: input.value })
    setLoading(false)
  }

  const onBlurCapabilityInfo = async (key: string) => {
    if (!fieldValueChanged) return
    setFieldValueChanged(false)
    const input: any = capabilityInfo[key]
    if (input.error && input.error.length > 0) return
    setLoading(true)
    await updateContractorApplication(no, { [key]: input.value })
    setLoading(false)
  }

  const fillPartyContractorInfo = async () => {
    let newInputs = cloneDeep(contractorGeneralInfo)
    Object.keys(partyContractorInfo).map((key) => {
      newInputs[key].value = InputConvert(newInputs[key], partyContractorInfo[key])
    })
    setLoading(true)
    await updateContractorApplication(no, partyContractorInfo)
    setLoading(false)
    setContractorGeneralInfo(checkValidationStatus('general', newInputs).data)
  }

  const generalFragment = () => {
    return (
      <div className="grid gap-4 md:grid-cols-2 grid-cols-1 mb-3">
        {Object.keys(contractorGeneralInfo).map((key, index) => {
          let input = contractorGeneralInfo[key]
          if (key === 'entityTitle' && !contractorURL && canEdit && Object.keys(partyContractorInfo).length) {
            let tooltip = ''
            Object.keys(partyContractorInfo).map((key) => {
              tooltip += `<div>${contractorGeneralInfo[key].title}: ${partyContractorInfo[key]}</div>`
            })
            input.additionalElements = (
              <span className={`text-shade-blue hover:underline cursor-pointer`}>
                <Tooltip message={`<div>${tooltip}</div>`}>
                  <span className="font-variation-settings-600 px-3" onClick={fillPartyContractorInfo}>
                    Fill Party Contractor
                  </span>
                </Tooltip>
              </span>
            )
          }
          input.disabled = !canEdit
          return (
            <div className={'input md:col-span-1'} key={index}>
              <RenderInput
                input={input}
                Key={key}
                onChange={onChangeGeneralInfo}
                showHistory={showHistory}
                onBlur={onBlurGeneralInfo}
              />
            </div>
          )
        })}
      </div>
    )
  }

  const onSubmitReferences = (currentId: any, values: Record<string, any>, key: string) => {
    return new Promise(async (resolve) => {
      setLoading(true)
      let temp: any = cloneDeep(references)
      if (!currentId) {
        values.id = Date.now()
        temp[key].data.push(values)
      } else {
        temp[key].data.map((item: any, index: number) => {
          if (item.id == currentId) {
            temp[key].data[index] = {
              ...temp[key].data[index],
              ...values,
            }
          }
        })
      }
      await updateContractorApplication(no, { [key]: temp[key].data })
      setReferences(temp)
      setLoading(false)
      resolve(values)
    })
  }

  const onRemoveReferences = async (id: any, key: string) => {
    return new Promise(async (resolve) => {
      setLoading(true)
      let temp: any = []
      references[key].data.map((item: any) => {
        if (item.id != id) temp.push(item)
      })
      await updateContractorApplication(no, { [key]: temp })
      let refTemp = cloneDeep(references)
      refTemp[key].data = temp
      setReferences(refTemp)
      setLoading(false)

      resolve(true)
    })
  }

  const referencesFragment = (key: string) => {
    return (
      <div key={key}>
        <FormTable
          key={`references-${key}`}
          header={[
            { key: 'name', title: `${capitalize(key)} Name` },
            {
              key: 'email',
              title: 'Email',
            },
            { key: 'phone', title: 'Telephone' },
            { key: 'scope', title: 'Scope of Work Completed' },
          ]}
          permission={canEdit ? 1 : 3}
          inputs={references[key].inputs}
          defaultData={references[key].data}
          onSubmit={(id, values) => onSubmitReferences(id, values, key)}
          onRemove={(id) => onRemoveReferences(id, key)}
          showHistory={(headers) => showHistory(key, headers)}
        />
        {!status[key] && (
          <div
            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative text-[16px] my-4"
            role="alert"
          >
            At least 3 references are required!
          </div>
        )}
      </div>
    )
  }

  const trackFragment = () => {
    return (
      <div>
        <FormTable
          key="track"
          header={[
            { key: 'address', title: 'Address' },
            { key: 'owner', title: 'Owner on Title' },
            { key: 'constructionStartDate', title: 'Start Date' },
            { key: 'constructionCompDate', title: 'Completion Date' },
            { key: 'newConstruction', title: 'Ground Up?' },
            { key: 'budget', title: 'Budget' },
            {
              key: 'projectTypes',
              title: 'Project Types',
              value: (row: any) => {
                let rlt = ''
                try {
                  Object.keys(row.projectTypes).map((key) => {
                    if (row.projectTypes[key]) rlt += `${key}, `
                  })
                } catch {}
                return rlt
              },
            },
            { key: 'units', title: 'Total Units' },
            { key: 'revenue', title: 'Total Revenue' },
          ]}
          permission={canEdit ? 1 : 3}
          inputs={references.track.inputs}
          defaultData={references.track.data}
          onSubmit={(id, values) => onSubmitReferences(id, values, 'track')}
          onRemove={(id) => onRemoveReferences(id, 'track')}
          showHistory={(headers) => showHistory('track', headers)}
        />
        {!status.track && (
          <div
            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative text-[16px] my-4"
            role="alert"
          >
            At least {requiredTracks} track records are required!
          </div>
        )}
      </div>
    )
  }

  const capabilityFragment = () => {
    return (
      <div className="grid gap-4 md:grid-cols-2 grid-cols-1 mb-3">
        {Object.keys(capabilityInfo).map((key, index) => {
          let input = capabilityInfo[key]
          input.disabled = !canEdit
          return (
            <div className={'input md:col-span-1'} key={index}>
              <RenderInput
                input={input}
                Key={key}
                onChange={onChangeCapabilityInfo}
                showHistory={showHistory}
                onBlur={onBlurCapabilityInfo}
              />
            </div>
          )
        })}
      </div>
    )
  }

  const renderValidation = () => {
    let validations: any = []
    if (!agreeSign) {
      validations.push('- Contractor need to agree on acknowledgement!')
    }
    Object.keys(status).map((key) => {
      if (key !== 'sign' && !status[key]) {
        validations.push(`- ${menus[key]}`)
      }
    })
    if (validations.length) {
      return (
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4 text-[14px]"
          role="alert"
        >
          <div className="font-variation-settings-600">Following steps are required before Sign Application.</div>
          {validations.map((item: string, index: number) => {
            return (
              <div className="ml-2" key={index}>
                {item}
              </div>
            )
          })}
        </div>
      )
    }
    return null
  }

  const onSignApplication = async () => {
    setShowSignModal(true)
  }

  const applicationPDF = async () => {
    setLoading(true)
    if (signData.fileKey) openS3Document(signData.fileKey, true)
    else {
      const res = await getContractorAppPDF(no)
      if (res.success) {
        let a = document.createElement('a')
        a.href = res.content
        a.download = 'Contractor_Builder Application.pdf'
        a.click()
      }
    }
    setLoading(false)
    return true
  }

  const onSignApp = (data: any) => {
    setSignData(data)
    setTimeout(() => {
      props.changeMenu()
    }, 300)
  }

  const signFragment = () => {
    const tableData = [
      [
        contractorGeneralInfo.entityEmail?.value || '',
        <p className="font-['freehand'] text-[24px]">
          {contractorGeneralInfo.firstName?.value || ''} {contractorGeneralInfo.lastName?.value || ''}
        </p>,
        signData.fileKey ? 'Signed' : 'Unsigned',
        signData.fileKey ? formatTime(signData.time) : '',

        <div
          className="flex items-center gap-2 cursor-pointer hover:underline text-shade-blue font-variation-settings-600"
          onClick={applicationPDF}
        >
          {signData.fileKey
            ? [<span key={0}>View Signed App</span>, <EyeIcon key={1} className="w-4 h-4"></EyeIcon>]
            : [
                <span key={0}>View Unsigned App</span>,
                <ArrowDownTrayIcon key={1} className="w-4 h-4"></ArrowDownTrayIcon>,
              ]}
        </div>,
      ],
    ]
    return (
      <div className="md:px-2">
        {signContent.map((item: any, index) => {
          return (
            <div className="mb-5" key={index}>
              <div className="font-semibold mb-1">{item[0]}</div>
              <div>
                {' '}
                <span dangerouslySetInnerHTML={{ __html: item[1] }} />
              </div>
            </div>
          )
        })}
        <div className="mb-4">
          <RenderInput
            input={{
              inputType: 'toggle',
              title: 'I agree with the above and confirm to sign.',
              error: agreeSign ? '' : 'Required!',
              value: agreeSign,
              history: true,
              disabled: !canEdit,
            }}
            Key={'agreeSign'}
            onChange={onChangeAgreeSign}
            showHistory={showHistory}
          />
        </div>
        <div className="">
          <div className="font-semibold mb-3">- Sign Application</div>
          {renderValidation()}
          <div className="overflow-x-auto">
            <div className="w-fit">
              <PlainTable header={['Email', 'Signature', 'Status', 'Signed Date', 'PDF']} data={tableData} />
            </div>
          </div>

          <div className="flex flex-wrap gap-4">
            {!contractorURL && (
              <Button onClick={() => sendApplication(false)} loading={action === 'sendApplication'}>
                <div className="flex gap-2 items-center">
                  Send Signature Request
                  <PaperAirplaneIcon className="w-4 h-4"></PaperAirplaneIcon>
                </div>
              </Button>
            )}
            {contractorURL &&
              (signData.fileKey ? (
                <Button onClick={props.changeMenu}>Go to Documents Upload</Button>
              ) : (
                <Button onClick={onSignApplication} disabled={renderValidation() !== null}>
                  Click Here to Sign Application
                </Button>
              ))}
          </div>
        </div>
        {showSignModal && (
          <ContractorSignDialog
            onSign={onSignApp}
            onClose={() => setShowSignModal(false)}
            applicationPDF={applicationPDF}
          />
        )}
      </div>
    )
  }

  const documentsFragment = () => {
    return (
      <div className="">
        <ContractorDocuments setLoading={setLoading} canChangeStatus={fullPermission} />
      </div>
    )
  }

  const renderFragment = () => {
    switch (activeMenu) {
      case 'welcome':
        return welcomeFragment
      case 'general':
        return generalFragment()
      case 'capability':
        return capabilityFragment()
      case 'subcontractor':
      case 'supplier':
      case 'client':
        return referencesFragment(activeMenu)
      case 'track':
        return trackFragment()
      case 'sign':
        return signFragment()
      case 'documents':
        return documentsFragment()
    }
  }

  const sendApplication = async (copyURL: boolean) => {
    if (!validateEmail(contractorGeneralInfo.entityEmail.value)) {
      return toast(`Please input valid contractor's entity email!`, { type: 'error' })
    }
    if (!copyURL && signData.fileKey) {
      return toast('Application already signed!', { type: 'error' })
    }
    setLoading(true)
    setAction(copyURL ? 'copyAppURL' : 'sendApplication')
    const res = await sendContractorApplication(no, { copyURL })
    if (copyURL && res.success) {
      copyClipboard(res.link)
    }
    setLoading(false)
    setAction('')
  }

  const totalStatusFragment = useMemo(() => {
    let rlt = null
    if (approveContractor || lockContractor) {
      rlt = (
        <div className="px-4 pt-4 flex justify-end flex-wrap gap-4 italic -mb-4 font-bold text-[13px]">
          {lockContractor && <div className="text-red-700">- Locked</div>}
          {approveContractor && <div className="text-shade-blue">- Approved</div>}
        </div>
      )
    }
    return rlt
  }, [approveContractor, lockContractor])

  const isZeroLoanNumber = loanNumber == 0
  return (
    <div className="ContractorApplication-container px-2 my-6">
      {!contractorURL && (
        <Overview
          title={`Contractor/Builder Application No.${no}`}
          backLink={
            isZeroLoanNumber
              ? isAdmin
                ? '/admin_tool/bankerSearch?tab=constractor'
                : '/resources'
              : `/contractor_application/${loanNumber}`
          }
          backTitle={'Return to All Contractor Applications'}
        />
      )}
      <div className="relative max-w-screen-2xl m-auto grid grid-cols-12 md:gap-6">
        <LayoutLoading show={loading} />
        <div className="sidebar-left col-span-12 md:col-span-3 shrink-0 bg-white shadow1 rounded pb-6 min-h-[200px] mb-4">
          <div className="relative">
            {totalStatusFragment}
            <ul className="sidebar-items flex flex-col p-4 pb-10">
              {Object.keys(menus).map((key: string, index) => {
                const isActive = activeMenu === key
                return (
                  <li
                    key={index}
                    onClick={() => {
                      setActiveMenu(key)
                    }}
                    className="border-b py-2 flex justify-between items-center "
                  >
                    <p
                      className={`flex-1 hover:underline cursor-pointer ${
                        isActive ? 'border px-4 py-1 bg-zinc-100' : 'py-1'
                      }`}
                    >
                      <span>
                        {index + 1}. {menus[key]}
                      </span>
                    </p>

                    <span className="ml-2">
                      {status[key] === true && (
                        <span className="text-shade-blue">
                          <CheckCircleIcon className="w-4 h-4"></CheckCircleIcon>
                        </span>
                      )}
                      {status[key] === false && (
                        <span className="text-red-800">
                          <XCircleIcon className="w-4 h-4"></XCircleIcon>
                        </span>
                      )}
                    </span>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
        <div className="col-span-12 md:col-span-9">
          <div className="relative h-fit content-right bg-white p-4 rounded shadow1 mb-4">
            <div className="flex flex-wrap items-center gap-4 mb-5">
              <h2 className="text-2xl font-bold">{menus[activeMenu]}</h2>
              {fullPermission && activeMenu === 'sign' && (
                <div className="flex flex-wrap gap-4">
                  <RenderInput
                    input={{
                      inputType: 'toggle',
                      title: 'Lock Contractor',
                      value: lockContractor,
                      history: true,
                    }}
                    Key={'lockContractor'}
                    onChange={onChangeLockContractor}
                    showHistory={showHistory}
                  />
                </div>
              )}
              {fullPermission && activeMenu === 'documents' && (
                <div className="flex flex-wrap gap-4">
                  <RenderInput
                    input={{
                      inputType: 'toggle',
                      title: 'Approve Contractor',
                      value: approveContractor,
                      history: true,
                    }}
                    Key={'approveContractor'}
                    onChange={onChangeApproveContractor}
                    showHistory={showHistory}
                  />
                </div>
              )}
            </div>
            {!contractorURL && ['welcome', 'general'].includes(activeMenu) && (
              <div className="flex justify-center flex-wrap gap-4 my-2">
                <Button
                  className=""
                  color="gray"
                  onClick={() => sendApplication(false)}
                  loading={action === 'sendApplication'}
                >
                  <div className="flex gap-2 items-center">
                    Send Application to Contractor
                    <ArrowTrendingUpIcon className="w-4 h-4"></ArrowTrendingUpIcon>
                  </div>
                </Button>
                <Button
                  link
                  className=""
                  color="gray"
                  onClick={() => sendApplication(true)}
                  loading={action === 'copyAppURL'}
                >
                  <div className="flex gap-1 items-center">
                    Copy Application URL
                    <Square2StackIcon className="w-4 h-4"></Square2StackIcon>
                  </div>
                </Button>
              </div>
            )}
            {renderFragment()}
          </div>
          <div className="">{renderPreNext}</div>
        </div>
      </div>
    </div>
  )
}
