import {
  API_CLOSING_COST_SETUP_UPDATE,
  API_CLOSING_COST_SETUPS,
  API_CONDITION_BROKER_TEMPLATE_BY_ID,
  API_CONDITION_BROKER_TEMPLATES,
  API_CONDITION_CREATE,
  API_CONDITION_DELETE,
  API_CONDITION_KEY_UPDATE,
  API_CONDITION_NEW_NUMBER,
  API_CONDITION_UPDATE,
  API_CONDITIONS,
  API_CONDITIONS_BY_NOS,
  API_CONDITIONS_BY_TEMPLATE,
  API_CONDITIONS_REPLACE_CONTENT,
  API_CONDITIONS_SIMPLY,
  API_DELETE_ROLODEX,
  API_EMAIL_TEMPLATE,
  API_EMAIL_TEMPLATE_BY_ID,
  API_GET_ALL_ROLODEX,
  API_GET_ROLODEX,
  API_GET_ROLODEX_CATEGORIES,
  API_INVESTOR_SERVICER,
  API_INVESTOR_SERVICER_ITEM,
  API_LOAN_APPLICATION_TEMPLATE,
  API_PAYMENT_TYPE_TEMPLATE,
  API_PAYMENT_TYPE_TEMPLATE_BY_ID,
  API_SERVICING_NOTE_TEMPLATE,
  API_SERVICING_NOTE_TEMPLATE_BY_ID,
  API_SERVICING_NOTE_TEMPLATE_ORDER_CHANGE,
  API_SUBMIT_ROLODEX,
  API_TASK_TEMPLATE,
  API_TASK_TEMPLATE_CREATE,
  API_TASK_TEMPLATE_DELETE,
  API_TASK_TEMPLATE_UPDATE,
  API_TEMPLATE_CREATE,
  API_TEMPLATE_DELETE,
  API_TEMPLATE_UPDATE,
  API_TEMPLATES,
  API_UPDATE_ROLODEX,
  API_WAREHOUSE_LENDER,
  API_WAREHOUSE_LENDER_BY_ID,
} from 'config'
import Api from 'services/api'

export const getConditions = () => {
  return Api.get(API_CONDITIONS)
}

export const getConditionBrokerTemplates = () => {
  return Api.get(API_CONDITION_BROKER_TEMPLATES)
}

export const getConditionsSimply = () => {
  return Api.get(API_CONDITIONS_SIMPLY)
}

export const submitCondition = (id: number, data: Record<string, string | number | boolean>) => {
  if (!id) return Api.post(API_CONDITION_CREATE, data)

  return Api.put(API_CONDITION_UPDATE, data, { id })
}

export const updateConditionWithKey = (id: number, key: string, value: any) => {
  return Api.put(API_CONDITION_KEY_UPDATE, { value }, { id, key })
}

export const deleteCondition = (id: number) => {
  return Api.delete(API_CONDITION_DELETE, {}, { id })
}

export const getTemplates = () => {
  return Api.get(API_TEMPLATES)
}

export const submitTemplate = (id: number, data: Record<string, string | number | boolean | number[] | null>) => {
  if (!id) return Api.post(API_TEMPLATE_CREATE, data)

  return Api.put(API_TEMPLATE_UPDATE, data, { id })
}

export const submitConditionBrokerTemplate = (id: number, data: any) => {
  return Api.put(API_CONDITION_BROKER_TEMPLATE_BY_ID, data, { id })
}

export const deleteTemplate = (id: number) => {
  return Api.delete(API_TEMPLATE_DELETE, {}, { id })
}

export const getTasks = () => {
  return Api.get(API_TASK_TEMPLATE)
}

export const submitTask = (id: number, data: Record<string, string | number | boolean>) => {
  if (!id) return Api.post(API_TASK_TEMPLATE_CREATE, data)

  return Api.put(API_TASK_TEMPLATE_UPDATE, data, { id })
}

export const deleteTaskTemplate = (id: number) => {
  return Api.delete(API_TASK_TEMPLATE_DELETE, {}, { id })
}

export const getConditionsByTemplate = (templateNo: number) => {
  return Api.get(API_CONDITIONS_BY_TEMPLATE, {}, { templateNo })
}

export const getConditionsByNos = (conditionNos: number[]) => {
  return Api.get(API_CONDITIONS_BY_NOS, { conditionNos: conditionNos.join(',') })
}

export const generateNewConditionNo = (conditionName: string) => {
  return Api.get(API_CONDITION_NEW_NUMBER, { conditionName })
}

export const getRolodexes = (data: Record<string, any>) => {
  return Api.get(API_GET_ROLODEX, data)
}

export const getRolodexCategories = () => {
  return Api.get(API_GET_ROLODEX_CATEGORIES)
}

export const deleteRolodex = (id: number) => {
  return Api.delete(API_DELETE_ROLODEX, {}, { id })
}

export const submitRolodex = (id: number, data: Record<string, any>) => {
  if (id == 0) return Api.post(API_SUBMIT_ROLODEX, data)
  else return Api.put(API_UPDATE_ROLODEX, data, { id })
}

export const getAllRolodex = () => {
  return Api.get(API_GET_ALL_ROLODEX)
}

export const getInvestorServicers = () => {
  return Api.get(API_INVESTOR_SERVICER)
}

export const getInvestorServicersItem = (id: number) => {
  return Api.get(API_INVESTOR_SERVICER_ITEM, {}, { id })
}

export const deleteInvestorServicer = (id: number) => {
  return Api.delete(API_INVESTOR_SERVICER_ITEM, {}, { id })
}

export const submitInvestorServicer = (id: number, data: Record<string, any>) => {
  if (id == 0) return Api.post(API_INVESTOR_SERVICER, data)
  else return Api.put(API_INVESTOR_SERVICER_ITEM, data, { id })
}

export const getEmailTemplates = () => {
  return Api.get(API_EMAIL_TEMPLATE)
}

export const getEmailTemplatesGroup = (group: string) => {
  return Api.get(`${API_EMAIL_TEMPLATE}/:group`, {}, { group })
}

export const deleteEmailTemplate = (id: number) => {
  return Api.delete(API_EMAIL_TEMPLATE_BY_ID, {}, { id })
}

export const submitEmailTemplate = (id: number, data: Record<string, any>) => {
  if (id == 0) return Api.post(API_EMAIL_TEMPLATE, data)
  else return Api.put(API_EMAIL_TEMPLATE_BY_ID, data, { id })
}

export const getServicingTemplateNotes = (type: string) => {
  return Api.get(API_SERVICING_NOTE_TEMPLATE, {}, { type })
}

export const submitServicingNoteTemplate = (id: number, data: Record<string, any>, type: string) => {
  if (id == 0) return Api.post(API_SERVICING_NOTE_TEMPLATE, data, { type })
  else return Api.put(API_SERVICING_NOTE_TEMPLATE_BY_ID, data, { id })
}

export const changeOrderServicingNoteTemplate = (data: any, type: string) => {
  return Api.post(API_SERVICING_NOTE_TEMPLATE_ORDER_CHANGE, data, { type })
}

export const deleteServicingNoteTemplate = (id: number) => {
  return Api.delete(API_SERVICING_NOTE_TEMPLATE_BY_ID, {}, { id })
}

export const getClosingCostSetups = () => {
  return Api.get(API_CLOSING_COST_SETUPS)
}

export const submitClosingCostSetup = (id: number, data: Record<string, string | number | boolean>) => {
  if (!id) return Api.post(API_CONDITION_CREATE, data)

  return Api.put(API_CLOSING_COST_SETUP_UPDATE, data, { id })
}

// Payment Type Apis
export const submitPaymentTypeTemplate = (orderType: string, data: Record<string, any>) => {
  return Api.post(API_PAYMENT_TYPE_TEMPLATE, data, { orderType })
}

export const getAllPaymentType = (orderType: string) => {
  return Api.get(API_PAYMENT_TYPE_TEMPLATE, {}, { orderType })
}

export const updatePaymentTypeTemplate = (orderType: string, id: number, data: Record<string, any>) => {
  return Api.put(API_PAYMENT_TYPE_TEMPLATE_BY_ID, data, { orderType, id })
}

export const deletePaymentTypeTemplate = (orderType: string, id: number) => {
  return Api.delete(API_PAYMENT_TYPE_TEMPLATE_BY_ID, {}, { orderType, id })
}

export const changeOrderPaymentTemplate = (orderType: string, data: Record<string, any>) => {
  return Api.put(API_PAYMENT_TYPE_TEMPLATE, data, { orderType })
}

export const getWarehouseLenders = () => {
  return Api.get(API_WAREHOUSE_LENDER)
}

export const submitWarehouseLender = (id: number, data: Record<string, string | number | boolean>) => {
  if (!id) return Api.post(API_WAREHOUSE_LENDER, data)

  return Api.put(API_WAREHOUSE_LENDER_BY_ID, data, { id })
}

export const deleteWarehouseLender = (id: number) => {
  return Api.delete(API_WAREHOUSE_LENDER_BY_ID, {}, { id })
}

export const getLoanApplicationTemplate = () => {
  return Api.get(API_LOAN_APPLICATION_TEMPLATE)
}

export const submitLoanApplicationTemplate = (data: Record<string, any>) => {
  return Api.post(API_LOAN_APPLICATION_TEMPLATE, data)
}

export const replaceConditionContent = (data: Record<string, any>) => {
  return Api.post(API_CONDITIONS_REPLACE_CONTENT, data)
}
