import { EyeIcon, EyeSlashIcon, PencilSquareIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { SaveChanges } from 'components/SaveChanges'
import { FieldOrigin, ILoanFieldPos, InputType, IVisibleProp, LoanFieldProps } from 'config'
import { getVisibleLogics2Text } from 'config/loan.input.fields.constants'
import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { getAdminConfig, setAdminConfig } from 'services'
import { Button } from 'stories/components'
import { Tooltip } from 'stories/components/Tooltip/Tooltip'
import { removeComma } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { extendedFieldOriginOptions, getInputs, visibilitySections } from './inputs'
import { VisiblePropertyModal } from './VisiblePropertyModal'

export const LoanFieldsProperties = () => {
  const [isLoading, setLoading] = useState(true)
  const [data, setData] = useState<LoanFieldProps>()
  const inputs = useMemo(() => getInputs(), [])
  const [isChanged, setChanged] = useState(false)
  const [selectedField, setSelectedField] = useState<ILoanFieldPos | null>(null)

  useEffect(() => {
    getAdminConfig('loanFieldProperties')
      .then(setData)
      .finally(() => setLoading(false))
  }, [])

  const selectedFieldData = useMemo(() => {
    if (!selectedField || !data) return null
    if (!data[selectedField.fieldOrigin]) return {}
    return data[selectedField.fieldOrigin][selectedField.fieldKey] || {}
  }, [selectedField])

  const isShowVisibleSection = (fieldOrigin: FieldOrigin) => Object.keys(visibilitySections).includes(fieldOrigin)

  const isVisibleSection = (fieldOrigin: FieldOrigin) =>
    data && ((data as any)['visibility'] || {})[fieldOrigin] !== false

  const onVisibleSection = (fieldOrigin: FieldOrigin) => {
    if (!data) return
    const newData = cloneDeep(data) as any
    if (!newData['visibility']) newData['visibility'] = {}
    newData['visibility'][fieldOrigin] = !isVisibleSection(fieldOrigin)
    setData(newData)
    !isChanged && setChanged(true)
  }

  const onChange = (
    fieldOrigin: FieldOrigin,
    fieldKey: string,
    subKey: keyof IVisibleProp,
    value: any,
    input: InputType,
  ) => {
    if (!data) return
    if (!data[fieldOrigin]) data[fieldOrigin] = {}
    const temp = cloneDeep(data)
    if (!temp[fieldOrigin]) temp[fieldOrigin] = {}
    if (!temp[fieldOrigin][fieldKey]) temp[fieldOrigin][fieldKey] = {} as any

    const defaultValue = (temp[fieldOrigin][fieldKey] as any).defaultValue
    if (subKey == 'visible' && ['', undefined].includes(defaultValue)) {
      toast('Default value is required', { type: 'warning' })
      return
    }

    if (['number', 'thousandSep', 'thousandSepNoDecimal'].includes(input.type as any) && subKey == 'defaultValue')
      value = removeComma(value)
    ;(temp[fieldOrigin][fieldKey] as any)[subKey] = value
    setData(temp)
    !isChanged && setChanged(true)
  }

  const onShowProperty = (fieldOrigin: FieldOrigin, fieldKey: string, input: InputType) => {
    setSelectedField({ fieldOrigin, fieldKey, title: input.title })
  }

  const onSubmit = async () => {
    if (!data) return
    setLoading(true)
    setAdminConfig('loanFieldProperties', data)
      .then(() => toast('Loan Fields Configuration has been saved.', { type: 'info' }))
      .finally(() => {
        setLoading(false)
        setChanged(false)
      })
  }

  const renderSection = (fieldOrigin: FieldOrigin, fieldKey: string, input: InputType) => {
    if (!data) return null
    const itemData = data[fieldOrigin] && data[fieldOrigin][fieldKey] ? data[fieldOrigin][fieldKey] : {}
    const visible = itemData.visible || itemData.visible === undefined
    let defaultValue = itemData.defaultValue
    if (defaultValue === undefined) defaultValue = ''
    const tooltip = itemData.tooltip || ''
    const visibleLogicText = getVisibleLogics2Text(itemData)

    let additionalElements = (
      <div className="flex">
        {tooltip && (
          <Tooltip message={tooltip}>
            <span className="btn-icon">
              <QuestionMarkCircleIcon className="w-4 h-4 text-gray-500" />
            </span>
          </Tooltip>
        )}
        <Tooltip message={visibleLogicText}>
          <span className="btn-icon" onClick={() => onChange(fieldOrigin, fieldKey, 'visible', !visible, input)}>
            {visible ? (
              <EyeIcon className={`w-4 h-4 ${!visibleLogicText && 'text-gray-500'}`} />
            ) : (
              <EyeSlashIcon className={`w-4 h-4 ${!visibleLogicText && 'text-gray-500'}`} />
            )}
          </span>
        </Tooltip>
        <span className="btn-icon" onClick={() => onShowProperty(fieldOrigin, fieldKey, input)}>
          <PencilSquareIcon className="w-4 h-4 text-gray-500" />
        </span>
      </div>
    )

    if (input.inputType == 'Section') additionalElements = <></>

    return (
      <div
        className={`grid grid-cols-1 items-center ${visible ? 'opacity-100' : 'opacity-30 z-10'} ${
          input.span ? `col-span-${input.span}` : ''
        } mb-2`}
        key={`${fieldOrigin}-${fieldKey}`}
      >
        <RenderInput
          key={`${fieldOrigin}-${fieldKey}`}
          input={{
            ...input,
            value: defaultValue,
            additionalElements,
          }}
          Key={`${fieldOrigin}-${fieldKey}`}
          onChange={(inputKey: string, value: any) => onChange(fieldOrigin, fieldKey, 'defaultValue', value, input)}
        />
      </div>
    )
  }

  return (
    <div className="relative">
      <LayoutLoading show={isLoading} />

      <div>
        {Object.keys(inputs).map((_fieldOrigin: string, pageIdx: number) => {
          const fieldOrigin = _fieldOrigin as FieldOrigin
          const isVisible = isVisibleSection(fieldOrigin)
          const VisibleIcon = isVisible ? EyeIcon : EyeSlashIcon
          return (
            <div className={`${isVisible ? '' : 'opacity-50'}`}>
              <p className={`text-lg font-bold flex items-center gap-4 ${isVisible ? '' : 'line-through'}`}>
                {pageIdx + 1}. {extendedFieldOriginOptions[fieldOrigin]}{' '}
                {isShowVisibleSection(fieldOrigin) && (
                  <span className="btn-icon my-2" onClick={() => onVisibleSection(fieldOrigin)}>
                    <VisibleIcon className="w-5 h-5" />
                  </span>
                )}
              </p>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
                {Object.keys(inputs[fieldOrigin]).map((fieldKey: string) => {
                  const item = inputs[fieldOrigin][fieldKey]
                  if (item.inputType === 'GoogleMap') return

                  return renderSection(fieldOrigin, fieldKey, item)
                })}
              </div>
            </div>
          )
        })}
      </div>

      <div className="flex flex-row justify-center gap-4">
        <Button onClick={onSubmit} loading={isLoading}>
          Save
        </Button>
      </div>
      <SaveChanges show={isChanged} label="Save Changes" onSave={onSubmit} />
      {selectedField && selectedFieldData && (
        <VisiblePropertyModal
          title={selectedField.title}
          data={selectedFieldData}
          onClose={(newItem?: IVisibleProp) => {
            setSelectedField(null)
            if (!newItem || !data) return

            const newData = cloneDeep(data)
            if (!newData[selectedField.fieldOrigin]) newData[selectedField.fieldOrigin] = {}
            newData[selectedField.fieldOrigin][selectedField.fieldKey] = newItem
            setData(newData)
            setChanged(true)
          }}
        />
      )}
    </div>
  )
}
