import { AUTH_TOKEN_SET } from 'actions'
import { LogInAlertModal } from 'components/Modals'
import { companyName } from 'config'
import jwtDecode from 'jwt-decode'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { signInTemporaryUser } from 'services'
import { Button, Input2 } from 'stories/components'
import { useTitle } from 'utils/pageTitle'
const cloneDeep = require('clone-deep')

const defaultInputs = {
  email: '',
  password: '',
}

export function TemporaryLogin({
  token,
  onSigned,
  onForgotPwd,
  isModal = false,
  temporaryUserEmail = '',
}: {
  token: string
  onSigned: Function
  onForgotPwd: Function
  isModal?: boolean
  temporaryUserEmail?: string
}) {
  useTitle(`Login - ${companyName}`)

  const [isLoading, setLoading] = useState(false)
  const [inputStates, setInputStates] = useState(defaultInputs)
  const [errors, setErrors] = useState({
    email: '',
    password: '',
  })
  const [modalContents, setModalContents] = useState<Array<any>>([])
  const [_token, setToken] = useState('')
  const [requireReset, setRequireReset] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    if (temporaryUserEmail) setInputStates({ ...inputStates, email: temporaryUserEmail })
  }, [])

  const onSubmit = async () => {
    const newErrors = cloneDeep(defaultInputs)
    if (!inputStates.email) newErrors.email = 'Required'
    if (!inputStates.password) newErrors.password = 'Required'
    setErrors(newErrors)

    if (!inputStates.email || !inputStates.password) return

    setLoading(true)

    signInTemporaryUser({
      email: inputStates.email,
      password: inputStates.password,
      token,
    })
      .then(({ token, requireReset, modalContents: _modalContents }) => {
        if (!_modalContents.length) {
          onSigned(token, requireReset)
        } else {
          const payload: any = jwtDecode(token)
          dispatch({
            type: AUTH_TOKEN_SET,
            token: token,
            user: payload,
          })

          setModalContents(_modalContents)
          setToken(token)
          setRequireReset(requireReset)
        }
      })
      .finally(() => setLoading(false))
  }

  const onChange = (key: string, value: string) => {
    let newState: any = Object.assign({}, inputStates)
    newState[key] = value
    setInputStates(newState)
  }

  const keyPress = (e: any) => {
    if (e.charCode === 13) {
      onSubmit()
    }
  }

  const handleLogInAlertClose = () => {
    onSigned(_token, requireReset)
  }

  return (
    <div
      className={
        !isModal ? 'home-container sm:text-center lg:text-left w-full block mt-0 relative bg-stone-100 md:py-20' : ''
      }
    >
      <div className={!isModal ? 'max-w-screen-2xl m-auto lg:pt-10 md:pt-5 sm:pt-2 pb-10 px-2 pt-4' : 'min-w-[400px]'}>
        {!modalContents.length ? (
          <div
            className={`bg-white rounded lg:max-w-xl max-w-full pl-6 pr-6 pt-${isModal ? 4 : 8} pb-6 relative mx-auto`}
          >
            {!isModal && (
              <>
                <p className="text-stone-800 text-4xl font-bold mb-3">Login</p>

                <div className="mb-5 grid grid-cols-3 gap-4">
                  <div className="h-1 bg-shade-blue w-full" />
                  <div className="h-1 bg-stone-200 w-full" />
                  <div className="h-1 bg-stone-200 w-full" />
                </div>
              </>
            )}
            <form>
              <Input2
                type="email"
                title="Email"
                value={inputStates.email}
                required
                error={errors.email ? 'Please provide a valid email address.' : ''}
                onChange={(value) => onChange('email', value)}
                onKeyPress={(e) => keyPress(e)}
              />
              <div className="mb-5"></div>
              <Input2
                type="password"
                title="Password"
                value={inputStates.password}
                required
                error={errors.password ? 'Please provide a valid password.' : ''}
                onChange={(value) => onChange('password', value)}
                onKeyPress={(e) => keyPress(e)}
              />

              <div className="my-7"></div>

              <Button className="mt-1" color="sky" full bold onClick={onSubmit} loading={isLoading}>
                Sign In
              </Button>

              <p
                className="block mt-3 hover:text-gray-900 hover:underline text-gray-500 cursor-pointer"
                onClick={() => onForgotPwd()}
              >
                Forgot your password?
              </p>
            </form>
          </div>
        ) : (
          <LogInAlertModal data={modalContents} onClose={handleLogInAlertClose} userType="borrower" />
        )}
      </div>
    </div>
  )
}
