import { GlobalConfigType, setApplicationStep } from 'actions'
import cloneDeep from 'clone-deep'
import { LoanFieldProps } from 'config'
import { store } from 'reducers'

export const additionalMemberValidate = (hasAdditionalMember: any, dataLength: number, updateStore = false) => {
  const { step, globalConfigReducer } = store.getState()
  const loanFieldProperties = globalConfigReducer[GlobalConfigType.LoanFields] as LoanFieldProps
  if (((loanFieldProperties as any)['visibility'] || {})['additionalMember'] === false) {
    return true
  }

  let success = true
  if (hasAdditionalMember === undefined || hasAdditionalMember === null) success = false
  else if (hasAdditionalMember === true) {
    if (dataLength === 0) {
      success = false
    }
  }
  if (updateStore) {
    let application = cloneDeep(step.application)
    application['additionalMember'] = success ? 1 : -1
    store.dispatch(setApplicationStep(application))
  }
  return success
}
