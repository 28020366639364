import cloneDeep from 'clone-deep'
import { ClosingCostsMap, GFEBlockMap, PaidToMap, ResponsiblePartyMap, TridBlockMap } from 'config'
import { useEffect, useState } from 'react'
import { addClosingCosts, getElectiveClosingCosts } from 'services'
import { Checkbox, Modal, PlainTable } from 'stories/components'

interface AddNewFeeModalProps {
  /**
   * On close handler
   */
  onClose?: Function
}

const header = ['', 'LINE ID', 'HUD NO', 'LINE NAME', 'TRID BLOCK', 'GFE BLOCK', 'TYPE', 'PAID TO', 'RESPONSIBLE PARTY']

export const AddNewFeeModal = ({ onClose = () => {} }: AddNewFeeModalProps) => {
  const [electiveClosingCosts, setEelectiveClosingCosts] = useState<Array<Array<any>>>([])
  const [selectedCC, setSelectedCC] = useState<Record<number, boolean>>({})
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isDataFetched, setIsDataFetched] = useState<boolean>(false)

  const initData = async () => {
    const data = await getElectiveClosingCosts()
    setEelectiveClosingCosts(data)
    setIsDataFetched(true)
  }
  useEffect(() => {
    initData()
  }, [])

  const onModalClose = async (save: boolean) => {
    if (save) {
      setIsLoading(true)
      await addClosingCosts(Object.keys(selectedCC))
    }
    onClose(save)
  }

  const onSelect = (lineId: number, value: boolean) => {
    let temp = cloneDeep(selectedCC)
    if (value) temp[lineId] = true
    else delete temp[lineId]
    setSelectedCC(temp)
  }

  return (
    <>
      {isDataFetched && (
        <Modal
          title="Select the fee(s) to add"
          loading={isLoading}
          isOpen
          onClose={() => onModalClose(false)}
          onOk={() => onModalClose(true)}
          disabled={!Object.keys(selectedCC).length}
        >
          <PlainTable
            header={header}
            data={electiveClosingCosts.map((item: Record<string, any>) => {
              const { lineId, hudNo, lineName, gfeBlock, tridBlock, type, paidToType, responsiblePartyType } = item
              return [
                <Checkbox
                  title=""
                  id={lineId}
                  checked={Object.keys(selectedCC).includes(lineId.toString())}
                  onChange={(value) => onSelect(lineId, value)}
                ></Checkbox>,
                lineId,
                hudNo,
                lineName,
                TridBlockMap[tridBlock],
                GFEBlockMap[gfeBlock],
                ClosingCostsMap[type],
                PaidToMap[paidToType],
                ResponsiblePartyMap[responsiblePartyType],
              ]
            })}
            tdClass="px-4 py-2 break-spaces"
          />
        </Modal>
      )}
    </>
  )
}
