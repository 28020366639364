import { GlobalConfigType, setApplicationStep } from 'actions'
import cloneDeep from 'clone-deep'
import { LoanFieldProps } from 'config'
import { store } from 'reducers'
import { LoanType } from 'reducers/loanDetail.reducer'

import { BorrowerSeperator, CoBorrowers } from '../CreditScore/constants'
import { stepApplicationKeys } from '../logic'

export const signAppValidate = (borrowerSeperator: string, updateStore = false, afterRemoved = false) => {
  const { borrower, step, loanDetail, globalConfigReducer } = store.getState()

  const loanFieldProperties = globalConfigReducer[GlobalConfigType.LoanFields] as LoanFieldProps
  if (((loanFieldProperties as any)['visibility'] || {})['sign'] === false) {
    return true
  }

  let success = true
  const data = borrower[borrowerSeperator]
  if (!data) return false

  const agreeToSignRequired = ![LoanType.CORRESPONDENT, LoanType.TABLEFUNDER].includes(loanDetail.loanType)

  if (borrowerSeperator === BorrowerSeperator.BORROWER) {
    if (data.agreeToSign !== true && agreeToSignRequired) {
      success = false
    } else {
      if (data.signedDate) {
      } else {
        success = false
      }
    }
  }

  if (CoBorrowers.includes(borrowerSeperator)) {
    if (data.agreeToSign !== true && agreeToSignRequired) success = false
  }

  if (data.exempt) success = true

  if (afterRemoved) success = false
  if (updateStore) {
    let application = cloneDeep(step.application)
    const key = stepApplicationKeys[borrowerSeperator].sign
    application[key] = success ? 1 : -1
    store.dispatch(setApplicationStep(application))
  }

  return success
}
