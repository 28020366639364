import { MagnifyingGlassIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import { LayoutLoading } from 'components/LayoutLoading'
import { useEffect, useMemo, useState } from 'react'
import { deleteEmailTemplate, getEmailTemplates } from 'services/apis/conditions'
import { Button, Input2 } from 'stories/components'
import { confirm } from 'utils'

import { EmailGroup } from './constants'
import { EmailTemplateDetails } from './details'
import { EmailTemplate } from './type'

export * from './type'

export function EmailTemplates() {
  const [query, setQuery] = useState('')
  const [total, setTotal] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [list, setList] = useState<EmailTemplate[]>([])
  const [isEditing, setIsEditing] = useState(false)
  const [selectedItem, setSelectedItem] = useState<EmailTemplate | null>(null)
  const [emailFrom, setEmailFrom] = useState<Record<string, string>>({})
  const [purposeOptions, setPurposeOptions] = useState<Record<string, Record<string, string>>>({})
  const [elementOptions, setElementOptions] = useState<Record<string, Record<string, string>>>({})

  useEffect(() => refresh(), [])

  const filteredList = useMemo(() => {
    if (!query) return list
    return list.filter(({ subject, content }) => {
      return [subject, content].join(' ').toLowerCase().includes(query.trim().toLowerCase())
    })
  }, [query, list])

  const refresh = () => {
    setIsLoading(true)
    getEmailTemplates()
      .then(({ data, emailFrom, purposeOptions, elementOptions }) => {
        setTotal(data.length)
        setList(data)
        setEmailFrom(emailFrom)
        setPurposeOptions(purposeOptions)
        setElementOptions(elementOptions)
      })
      .finally(() => setIsLoading(false))
  }

  if (isEditing)
    return (
      <EmailTemplateDetails
        emailFrom={emailFrom}
        purposeOptions={purposeOptions}
        elementOptions={elementOptions}
        defaults={selectedItem}
        onBack={() => setIsEditing(false)}
        onComplete={refresh}
      />
    )

  const onAdd = () => {
    setSelectedItem(null)
    setIsEditing(true)
  }

  const onEdit = (item: EmailTemplate) => {
    setSelectedItem(item)
    setIsEditing(true)
  }

  const onTrash = async (item: EmailTemplate) => {
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to remove this item?
        <br />
        <span className="text-gray-600">Subject: {item.subject}</span>
      </div>
    )
    const result = await confirm(content)
    if (!result) return

    setIsLoading(true)
    deleteEmailTemplate(item.id)
      .then(refresh)
      .catch(() => setIsLoading(false))
  }

  return (
    <div className="List-container relative">
      <LayoutLoading show={isLoading} />
      <h2 className="text-2xl font-bold flex items-center mb-3">Email Templates</h2>
      <div className="flex flex-wrap justify-between mb-3">
        <div className="flex items-center flex-wrap">
          <div className="md:w-96 w-72">
            <Input2
              type="search"
              title="Search"
              hasIcon
              icon={<MagnifyingGlassIcon className="w-5 h-5 text-gray-500 dark:text-gray-400" />}
              value={query}
              onChange={(value) => setQuery(value)}
            />
          </div>
          <p className="ml-5">- {total} Items</p>
        </div>
        <div className="w-32">
          <Button full onClick={onAdd}>
            Add
          </Button>
        </div>
      </div>

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-3 py-3">
                No
              </th>
              <th scope="col" className="py-3 px-3">
                Type
              </th>
              <th scope="col" className="px-3">
                <div className="mb-2 border-b w-fit">Subject</div>
                Content
              </th>
              <th scope="col" className="px-3">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="">
            {filteredList.map((item, index) => {
              return (
                <tr className={`border-b ${index % 2 ? 'bg-slate-50' : 'bg-white'}`} key={`${index}`}>
                  <td scope="row" className="px-3 py-3 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                    {index + 1}
                  </td>
                  <td className="px-3">{EmailGroup[item.group]}</td>
                  <td className="px-3 py-2">
                    <div>
                      <div className="border-b mb-2 text-[15px] w-fit">{item.subject}</div>
                      <code>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: (item.title || item.content).slice(0, 150).replace(/\n/g, '<br />') + '...',
                          }}
                        />
                      </code>
                    </div>
                  </td>
                  <td className="px-3">
                    <span className="flex">
                      <span className="text-shade-blue p-1 hover-shadow1 cursor-pointer" onClick={() => onEdit(item)}>
                        <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
                      </span>
                      <span className="text-red-800 p-1 hover-shadow1 cursor-pointer" onClick={() => onTrash(item)}>
                        <TrashIcon className="w-4 h-4"></TrashIcon>
                      </span>
                    </span>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
