import { SET_LOAN_DETAIL } from 'actions/types'
import cloneDeep from 'clone-deep'

import { createReducer } from '../utils'

export const closingPartiesMap: Record<string, string> = {
  titleCompany: 'Title Company',
  hazardInsurance: 'Hazard Insurance',
  escrowCompany: 'Escrow Company',
}

export const partiesMap: Record<string, string> = {
  creator: 'Loan Creator',
  loanProcessor: 'Loan Processor',
  loanOfficer: 'Loan Officer',
  broker: 'Broker',
  accountExecutive: 'Account Executive',
  underwriter: 'Underwriter',
  accountManager: 'Account Manager',
}

export const partiesChangePermissions: Record<string, string> = {
  broker: 'CAN_CHANGE_LOAN_BROKER',
  underwriter: 'CAN_CHANGE_LOAN_UNDERWRITER',
  accountManager: 'CAN_CHANGE_LOAN_ACCOUNT_MANAGER',
  accountExecutive: 'CAN_CHANGE_LOAN_ACCOUNT_EXECUTIVE',
}

export enum LoanType {
  DEFAULT = 0,
  CORRESPONDENT = 1,
  RETAIL = 2,
  TABLEFUNDER = 3,
  BROKERED = 4,
}

export const loanTypeMap: Record<string, string> = {
  '0': 'Wholesale',
  '1': 'Correspondent',
  '2': 'Retail',
  '3': 'Table Funder',
  '4': 'Brokered',
}

export const getloanDetailInitialState = () => {
  return {
    loanNumber: '',
    byteproFileName: 0,
    entityTitle: '',
    borrowerName: '',
    propertyAddress: '',
    loanProcessDate: '',
    loanStatus: 'prequal',
    servicingStatus: null,
    servicingPipeline: false,
    includeCoborrower: false,
    loanType: null,
    parties: {},
    closeParties: {},
    rateData: {},
    priceAdjustments: [],
    restructureApplicationRelease: true,
    closingDate: '',
    emailContacts: [],
    rush: false,
    submitToAE: 0,
    submitToLoanSetup: 0,
    submitConditions: 0,
    submitToUnderwriter: 0,
    pushedToYieldeasyAt: null,
    crossCollateralBlanketLimit: {},
    allocatedLoanAmount: [],
    loanGlobalSettings: {},
    pricingEngine: true,
    isCreatedByBorrower: false,
    servicerLoanNumber: null,
    templateNumber: 0,
  }
}

export const loanDetail = createReducer(getloanDetailInitialState(), {
  [SET_LOAN_DETAIL]: (state: any, payload: any) => {
    let newState = cloneDeep(state)
    newState = {
      ...newState,
      ...payload.data,
    }
    return newState
  },
})
