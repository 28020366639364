import { LogoBlue } from 'components/Logo'
import { useState } from 'react'
import { logInPageModalAPI } from 'services/apis/admin'
import { Modal, Toggle } from 'stories/components'

export const LogInAlertModal = ({ data, ...props }: { data: Array<any>; onClose: Function; userType?: string }) => {
  const [review, setReview] = useState(false)

  const onClose = () => {
    props.onClose()
  }

  const onSubmit = () => {
    let IDs: any = []
    data.map((item) => IDs.push(item.id))
    logInPageModalAPI({ type: 'review', IDs, userType: props.userType })
    props.onClose()
  }

  return (
    <Modal
      title={
        <div className="flex flex-wrap gap-4">
          <LogoBlue />
          <h3 className="flex items-center justify-center text-[22px] md:ml-8 font-semibold">
            Notifications <span className="ml-1 text-[16px]">({data.length})</span>
          </h3>
        </div>
      }
      disabled={!review}
      titleOkay={'Continue'}
      isOpen
      onClose={onClose}
      onOk={onSubmit}
      titleCancel=""
      hiddenX={true}
    >
      <div className="grid gap-x-3 grid-cols-1 w-[600px]">
        {data.map((item, index) => {
          return (
            <div className="mb-4 text-[15.5px]" key={index}>
              <div className="font-semibold border-b">
                {index + 1}. {item.title}
              </div>
              <div className="ml-3">{item.content}</div>
            </div>
          )
        })}
        <div className="mt-4">
          <Toggle
            id="reviewed"
            title="I have reviewed all of the notifications above."
            onChange={(value) => setReview(value)}
            value={review}
          />
        </div>
      </div>
    </Modal>
  )
}
