import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { INVALID_ALL_INPUTS } from 'config/api.status.constants'
import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import {
  getAppraisalComments,
  postAppraisal2Documents,
  postAppraisal2PayOrInvoice,
  postAppraisalComments,
} from 'services'
import { Button, ButtonGroup, Modal, PlainTable, TextArea } from 'stories/components'
import { type CategoryDocument, CategoryDocuments } from 'stories/components/CategoryFileTable/CategoryFileTable'
import { formatTime, InputConvert, InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { defaultInputs } from './constants'
import { handleFailedDocsNotification } from './handleFailedDocs'
import {
  AppraisalDocumentCategories,
  cardFields,
  IntegrationType,
  integrationTypeTitles,
  paymentInformationFormKeys,
} from './types'

export const AppraisalNation = ({
  data,
  onClose,
  integrationType,
}: {
  data: Record<string, any>
  onClose: Function
  integrationType: IntegrationType
}) => {
  const [menu, setMenu] = useState('Notification Logs')
  const [logs, setLogs] = useState<Array<any>>([])
  const [history, setHistory] = useState<Array<any>>([])
  const [comment, setComment] = useState('')
  const [loading, setLoading] = useState(false)
  const [action, setAction] = useState('')
  const [documents, setDocuments] = useState<Array<CategoryDocument>>([])
  const [uploadedDocs, setUploadedDocs] = useState<Array<string>>(data.thirdPartyAPI.uploadedDocs)
  const [inputs, setInputs] = useState<any>({})

  const { appraisalID, appraisalFileNumber } = data.thirdPartyAPI

  const visibleInputsLogic = (data: any) => {
    let visibleKeys = ['isSendInvoice', 'paymentLinkEmail']
    if (data['isSendInvoice'].value === false) {
      visibleKeys = ['isSendInvoice', ...cardFields]
    }
    Object.keys(data).map((key) => {
      if (!visibleKeys.includes(key)) data[key].visible = false
      else data[key].visible = true
    })
    return data
  }

  useEffect(() => {
    const originInputs = defaultInputs()
    let temp: any = {}
    paymentInformationFormKeys.map((key) => {
      if (!['dividerPay', 'paymentAmount'].includes(key)) {
        let input = originInputs[key]
        if (data.info[key] !== undefined) input.value = data.info[key]
        temp[key] = input
      }
    })
    setInputs(visibleInputsLogic(temp))
    setHistory(data.thirdPartyAPI.history || [])
  }, [])

  const getLogs = async () => {
    setLoading(true)
    let res
    res = await getAppraisalComments(appraisalID, integrationType)
    setLoading(false)
    if (res.success) {
      let rlt: any = []
      const length = res.data.length
      res.data.map((item: any, index: number) => {
        rlt.push([
          length - index,
          <div>
            {formatTime(item.comment_date)}
            <br />
            <span className="italic">{item.comment_by}</span>
          </div>,
          item.comment,
        ])
      })
      let _rlt = []
      for (let i = rlt.length - 1; i >= 0; i -= 1) {
        _rlt.push(rlt[i])
      }
      setLogs(_rlt)
    }
  }

  const onSendMessage = async () => {
    setLoading(true)
    setAction('sendMessage')

    await postAppraisalComments(
      {
        appraisalID: appraisalID,
        comment: comment,
      },
      integrationType,
    )

    setComment('')
    setLoading(false)
    setAction('')
    getLogs()
  }

  const extraDocs = useMemo(() => {
    let docs: any = []
    documents.map((item: CategoryDocument) => {
      if (uploadedDocs?.includes(item.fileKey)) {
      } else docs.push(item)
    })
    return docs
  }, [documents, uploadedDocs])

  const typeTitle = integrationTypeTitles[integrationType]

  const sendDocuments = async () => {
    setLoading(true)
    setAction('sendDocuments')
    const res = await postAppraisal2Documents({
      id: data.id,
      documents: extraDocs,
    })

    handleFailedDocsNotification(res)

    let tempDocs = cloneDeep(uploadedDocs)
    res.uploadedDocs.map((fileKey: string) => {
      tempDocs.push(fileKey)
    })
    setUploadedDocs(tempDocs)
    setLoading(false)
    setAction('')
  }

  useEffect(() => {
    getLogs()
    setDocuments(data.info?.documents || [])
  }, [])

  const onDocumentChange = (docs: Array<CategoryDocument>) => {
    setDocuments(docs)
  }

  const onChangeLoanInputFields = async (key: string, e: any) => {
    const value = InputConvert(inputs[key], e)
    const error = InputValidate({ ...inputs[key], value })
    let temp = cloneDeep(inputs)
    temp[key].value = value
    temp[key].error = error
    if (key === 'isSendInvoice') temp = visibleInputsLogic(temp)
    setInputs(temp)
  }

  const sendPayOrInvoice = async () => {
    let temp = cloneDeep(inputs)
    let json: any = {}
    let hasError = false
    Object.keys(temp).map((key) => {
      if (temp[key].visible !== false) {
        const { value } = temp[key]
        const error = InputValidate({ ...temp[key], value })
        temp[key].error = error
        json[key] = value
        if (error.length) hasError = true
      }
    })
    setInputs(temp)
    if (hasError) {
      return toast(INVALID_ALL_INPUTS, { type: 'error' })
    }

    setLoading(true)
    setAction('sendPayOrInvoice')

    const res = await postAppraisal2PayOrInvoice({
      id: data.id,
      info: json,
    })

    if (res.success) {
      setHistory(res.history)
    }

    setLoading(false)
    setAction('')
  }

  const renderHistory = () => {
    let rlt: any = []
    history.map((item: any, index: number) => {
      rlt.push([index + 1, item.action, item.status, formatTime(item.time)])
    })
    return <PlainTable header={['No', 'Action', 'Status', 'Date']} tdClass={'px-4 py-2'} data={rlt} />
  }

  const renderContent = () => {
    if (menu === 'Notification Logs') {
      return (
        <div className="">
          <div className="">
            <PlainTable header={['No', 'Date / By', 'Comment']} tdClass={'px-4 py-2'} data={logs} />
          </div>
          <TextArea title="Comment" value={comment} onChange={(value) => setComment(value)} />
          <div className="text-center mt-3">
            <Button
              color="gray"
              onClick={onSendMessage}
              loading={action === 'sendMessage'}
              disabled={comment.length === 0}
            >
              <>Send Message to {typeTitle}</>
            </Button>
          </div>
        </div>
      )
    }
    if (menu === 'Documents') {
      return (
        <div className="">
          <CategoryDocuments
            documents={documents}
            onChange={onDocumentChange}
            setLoading={setLoading}
            type={'OrderAppraisal'}
            categories={AppraisalDocumentCategories}
          />
          <div className="text-center mt-4">
            <Button
              color="gray"
              onClick={sendDocuments}
              loading={action === 'sendDocuments'}
              disabled={extraDocs.length === 0}
            >
              <>Upload Documents to {typeTitle}</>
            </Button>
          </div>
        </div>
      )
    }
    if (menu === 'Pay / Invoice') {
      return (
        <div className="">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 shadow1 p-4">
            {Object.keys(inputs).map((key, index) => {
              let input = inputs[key]
              if (input.visible === false) return null
              let cn = key === 'isSendInvoice' ? 'md:col-span-2' : 'md-col-span-1'
              return (
                <div className={cn} key={index}>
                  <RenderInput input={input} Key={key} onChange={onChangeLoanInputFields} />
                </div>
              )
            })}
          </div>
          <div className="text-center mt-4">
            <Button
              color="gray"
              onClick={sendPayOrInvoice}
              loading={action === 'sendPayOrInvoice'}
              className="min-w-[200px]"
            >
              {inputs['isSendInvoice'].value === true ? 'Send Invoice' : 'Pay Now'}
            </Button>
          </div>
        </div>
      )
    }
  }

  return (
    <Modal isOpen title={`${typeTitle} - ${appraisalFileNumber}`} titleOkay="" onClose={() => onClose(false)}>
      <div className="text-gray-600 text-md overflow-hidden min-w-[750px] max-w-2xl relative">
        <LayoutLoading show={loading} />
        <div className="">
          <div className="border-b mb-2 italic font-bold">Progress Status</div>
          {renderHistory()}
        </div>
        <div className="rounded p-2 border-[4px] border-shade-blue/50">
          <ButtonGroup
            title={['Notification Logs', 'Pay / Invoice', 'Documents']}
            onChange={(key) => {
              setMenu(key)
            }}
            value={menu}
          />
        </div>
        <div className="mt-4">{renderContent()}</div>
      </div>
    </Modal>
  )
}
