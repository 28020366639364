import { LayoutLoading } from 'components/LayoutLoading'
import { COMPANY_TITLE } from 'config'
import { useEffect, useState } from 'react'
import { getAdminConfig } from 'services'
import { formatDate, replaceBoldTexts } from 'utils'

export const Agreement = () => {
  const [loading, setLoading] = useState(false)
  const [template, setTemplate] = useState('')

  useEffect(() => {
    setLoading(true)
    getAdminConfig('textConfig').then((data) => {
      let content: string = data.brokerAgreementTemplate
      content = replaceBoldTexts(content)
      content = content.replaceAll('{TODAY}', formatDate())
      content = content.replaceAll('{COMPANY_TITLE}', COMPANY_TITLE)
      setTemplate(content)
      setLoading(false)
    })
  }, [])

  const renderSection = (index: number | string, content: string) => {
    return (
      <span className="flex gap-2">
        <span>{index}.</span>
        <span>{content}</span>
      </span>
    )
  }

  return (
    <p className="text-stone-800">
      <LayoutLoading show={loading} />
      {template.split('\n').map((paragraph) => {
        if (!paragraph) return <br />
        const params = paragraph.split('. ')
        if (params.length < 2 || params[0].length > 10) return <p dangerouslySetInnerHTML={{ __html: paragraph }}></p>
        return (
          <div className={`ml-${(params[0].split('.').length - 1) * 3}`}>{renderSection(params[0], params[1])}</div>
        )
      })}
    </p>
  )
}
