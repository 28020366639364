import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { InputType } from 'config'
import React, { useEffect, useMemo, useState } from 'react'
import { Button, Checkbox } from 'stories/components'
import { InputConvert, InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

const additionalDocs = [{ id: 0, name: 'Unsigned Loan Application' }]

export const DrawDocuments = ({
  documents: _documents,
  onSubmit,
  onBack,
  onChange,
  showHistory,
  inputs: _inputs,
}: {
  documents: Array<any>
  onSubmit: Function
  onBack: Function
  onChange: Function
  showHistory: Function
  inputs: Record<string, InputType>
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [documents, setDocuments] = useState<Array<any>>([])
  const [inputs, setInputs] = useState<Record<string, InputType>>(_inputs)

  const downloadOptionInput = inputs.downloadOption

  useEffect(() => {
    setIsLoading(false)
    const totalDocs = [..._documents, ...additionalDocs]
    totalDocs?.map((document: any) => {
      document.checked = true
    })
    setDocuments(totalDocs || [])
  }, [_documents])

  const onUpdate = (item: any, key: string, value: any) => {
    const newData = cloneDeep(documents)
    const index = documents.findIndex((v) => v.id == item.id)
    if (index == -1) return
    newData[index] = {
      ...item,
      [key]: value,
    }

    setDocuments(newData)
  }

  const onCheckAll = (value: boolean) => {
    const newData = cloneDeep(documents)
    newData.map((item: any) => {
      item.checked = value
    })
    setDocuments(newData)
  }

  const _onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].error = InputValidate({ ...newInputs[key], value })
    newInputs[key].value = value
    setInputs(newInputs)
    onChange(key, value)
  }

  const onDrawDocument = async () => {
    const key = 'downloadOption'
    let hasError

    const newInputs = cloneDeep(inputs)
    newInputs[key].error = InputValidate(newInputs[key])
    if (newInputs[key].error) hasError = true

    setInputs(newInputs)
    if (hasError) return

    setIsLoading(true)
    const ids = documents.filter((item) => item.checked && item.id).map((item) => item.id)
    const additionalDocs = documents.filter((item) => item.checked && !item.id).map((item) => item.name)
    await onSubmit(ids, additionalDocs)
    setIsLoading(false)
    onBack()
  }

  const isAllChecked = useMemo(() => {
    return !!documents.length && documents.filter((v) => v.checked).length == documents.length
  }, [documents])

  return (
    <div className="GenerateDocument-container relative">
      <LayoutLoading show={isLoading} />
      <div className="text-shade-blue flex items-center mb-2 font-bold">
        <ChevronLeftIcon className="w-4 h-4"></ChevronLeftIcon>
        <span className="hover:underline cursor-pointer" onClick={() => onBack()}>
          Back
        </span>
      </div>
      <h2 className="text-2xl font-bold flex items-center mb-3">Draw Documents</h2>
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="px-3 py-3 w-[20px]">
              <Checkbox
                id={`check-no-all`}
                fontClass="!font-semibold !text-gray-700 !text-xs"
                title="No"
                checked={isAllChecked}
                onChange={(v) => onCheckAll(v)}
              />
            </th>
            <th scope="col" className="px-3 py-3">
              File
            </th>
          </tr>
        </thead>
        <tbody>
          {documents.length ? (
            documents.map((item: any, index) => {
              return (
                <React.Fragment key={`file-${item.id}`}>
                  <tr className={`border-b dark:bg-gray-800 dark:border-gray-700`} key={index}>
                    <td scope="row" className="px-3 py-1 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                      <div>
                        <Checkbox
                          id={`check-${index + 1}`}
                          title={`${index + 1}`}
                          checked={item.checked}
                          onChange={(v) => onUpdate(item, 'checked', v)}
                        />
                      </div>
                    </td>

                    <td scope="row" className="px-3 py-1 font-medium text-gray-900 dark:text-white">
                      {item.name}
                    </td>
                  </tr>
                </React.Fragment>
              )
            })
          ) : (
            <tr>
              <td colSpan={6}>
                <p className="text-sm text-center">--- No data ---</p>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="mt-6">
        <RenderInput
          input={downloadOptionInput}
          Key={'downloadOption'}
          onChange={_onChange}
          showHistory={showHistory}
        />
      </div>
      <div className="text-center mt-6">
        <Button onClick={onDrawDocument} loading={isLoading}>
          Draw documents
        </Button>
      </div>
    </div>
  )
}
