export const SIGNIN_USER_REQUEST = 'SIGNIN_USER_REQUEST'
export const AUTH_TOKEN_SET = 'AUTH_TOKEN_SET'
export const AUTH_PROFILE_UPDATE = 'AUTH_PROFILE_UPDATE'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'

export const ON_APPLICATION_ERROR = 'ON_APPLICATION_ERROR'

export const USER_PERMISSIONS_SET = 'USER_PERMISSIONS_SET'
export const USER_ATTETION_VIEWED = 'USER_ATTETION_VIEWED'

// Vendor Reducer
export const SET_VENDOR = 'SET_VENDOR'

// Header Reducer
export const SET_HEADER = 'SET_HEADER'
export const SET_MENU = 'SET_MENU'

//Audit Log Types
export const CLOSE_AUDIT_LOG = 'CLOSE_AUDIT_LOG'
export const OPEN_AUDIT_LOG = 'OPEN_AUDIT_LOG'
export const LOADED_AUDIT_LOG = 'LOADED_AUDIT_LOG'

//Loan Types
export const LOAN_SET_DATA = 'LOAN_SET_DATA'
export const LOAN_SET_ALL_DATA = 'LOAN_SET_ALL_DATA'
export const LOAN_SET_GROUP_DATA = 'LOAN_SET_GROUP_DATA'

// Loan Global Status
export const LOAN_SET_GLOBAL_STATUS = 'LOAN_SET_GLOBAL_STATUS'

//Borrower Types
export const BORROWER_SET_ALL_DATA = 'BORROWER_SET_ALL_DATA'
export const BORROWER_SET_GROUP_DATA = 'BORROWER_SET_GROUP_DATA'
export const BORROWER_REMOVE_CO_BORROWER = 'BORROWER_REMOVE_CO_BORROWER'

//Step Types
export const SET_OVERVIEW_STEP = 'SET_OVERVIEW_STEP'
export const SET_APPLICATION_STEP = 'SET_APPLICATION_STEP'

// LoanDetail Types
export const SET_LOAN_DETAIL = 'SET_LOAN_DETAIL'

// Loan LTV MAX LIMITS
export const SET_LOAN_LTV_MAX_LIMIT = 'SET_LOAN_LTV_MAX_LIMIT'
export const SET_LOAN_TIER_LTV_MAX_LIMIT = 'SET_LOAN_TIER_LTV_MAX_LIMIT'

// Global Configuration
export const SET_GLOBAL_CONFIG = 'SET_GLOBAL_CONFIG'
