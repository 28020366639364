import { FieldOrigin, ILoanFieldPos } from 'config/loan.input.visibility.type'

export const requiredFields = [
  { fieldOrigin: FieldOrigin.BorrowerInformation, fieldKey: 'firstName' },
  { fieldOrigin: FieldOrigin.BorrowerInformation, fieldKey: 'lastName' },
  { fieldOrigin: FieldOrigin.BorrowerInformation, fieldKey: 'email' },
  { fieldOrigin: FieldOrigin.BorrowerInformation, fieldKey: 'phone' },
  // { fieldOrigin: FieldOrigin.BorrowerInformation, fieldKey: 'presentAddress' },
  { fieldOrigin: FieldOrigin.LoanStructure, fieldKey: 'subjectPropertyAddress' },
]

export const ignoredFields: ILoanFieldPos[] = [
  { fieldOrigin: FieldOrigin.LoanStructure, fieldKey: 'borrowerFirstName' },
  { fieldOrigin: FieldOrigin.LoanStructure, fieldKey: 'borrowerLastName' },
]

export const loanProposedMonthlyFields = [
  'proposedMonthlyTaxes',
  'proposedMonthlyInsurance',
  'proposedMonthlyRent',
  'proposedMonthlyHoaDues',
]

export const isMonthlyInput = ({ fieldOrigin, fieldKey }: { fieldOrigin: FieldOrigin; fieldKey: string }) =>
  fieldOrigin == FieldOrigin.LoanStructure && loanProposedMonthlyFields.includes(fieldKey)
