import { type InputType, COMPANY_NAME_FC } from 'config'

export enum CreditRequestType {
  Universal = 'universal',
  OnHand = 'onhand',
}

export enum BorrowerSeperator {
  BORROWER = 'borrower',
  CO_BORROWER = 'coBorrower',
  CO_BORROWER2 = 'coBorrower2',
  CO_BORROWER3 = 'coBorrower3',
}

export const CoBorrowers: Array<string> = [
  BorrowerSeperator.CO_BORROWER,
  BorrowerSeperator.CO_BORROWER2,
  BorrowerSeperator.CO_BORROWER3,
]

const noScore = 'If there is no credit score for this value, then just type 000.'

export const defaultInputs = (): Record<string, InputType> => {
  return {
    creditRequestType: {
      inputType: 'select',
      title: 'Credit Request Type',
      options: {
        [CreditRequestType.Universal]: `${COMPANY_NAME_FC} to Pull Credit`,
        [CreditRequestType.OnHand]: 'Credit Report on Hand',
      },
      hasDefaultOption: true,
      required: true,
    },
    creditFileKey: {
      inputType: 'file',
      title: 'Please upload your credit score pdf to input your scores.',
      span: 2,
      required: true,
    },
    equifax: {
      inputType: 'text',
      type: 'creditScore',
      tooltip: noScore,
      title: 'Equifax Score',
      required: true,
    },
    experian: {
      inputType: 'text',
      type: 'creditScore',
      tooltip: noScore,
      title: 'Experian Score',
      required: true,
    },
    transunion: {
      inputType: 'text',
      type: 'creditScore',
      tooltip: noScore,
      title: 'Transunion Score',
      required: true,
    },
    priceScore: {
      inputType: 'select',
      title: '',
      options: [],
      hasDefaultOption: true,
    },
  }
}
