import { GlobalConfigType, setApplicationStep } from 'actions'
import cloneDeep from 'clone-deep'
import { FieldOrigin, LoanFieldProps } from 'config'
import { store } from 'reducers'
import { InputValidate } from 'utils'
import { removeInvisibleFields } from 'utils/loan'

import { BorrowerSeperator, CoBorrowers } from '../CreditScore/constants'
import { stepApplicationKeys } from '../logic'
import { defaultInputs } from './constants'

const disableKeys = ['firstName', 'middleName', 'lastName', 'borrowerType', 'residency']

export const visibleBorrowerFieldsLogic = (borrowerSeperator: string) => {
  const { borrower } = store.getState()

  let visibleFields: string[] = [
    'residency',
    'firstName',
    'middleName',
    'lastName',
    'email',
    'ssn',
    'phone',
    'dob',
    'presentAddress',
    'mailingAddress',
    'ownership',
    'ownedRentedYears',
    'maritalStatus',
  ]
  if (borrowerSeperator === BorrowerSeperator.BORROWER) {
    visibleFields.push('hasEntityTitle')
    visibleFields.push('titleVesting')
    if (borrower['borrower'].hasEntityTitle) {
      if (borrower[borrowerSeperator].borrowerType !== 'Individual') {
        visibleFields.push('borrowerPosition')
      }
      visibleFields.push('borrowerType')
      visibleFields.push('ownershipPercentage')
      visibleFields.push('entityTitle')
      visibleFields.push('entityAddress')
      visibleFields.push('entityTaxID')
      visibleFields.push('entityState')
    }
  }

  if (CoBorrowers.includes(borrowerSeperator)) {
    if (borrower['borrower'].hasEntityTitle) {
      if (borrower[borrowerSeperator].borrowerType !== 'Individual') {
        visibleFields.push('borrowerPosition')
      }
      visibleFields.push('ownershipPercentage')
    }
  }

  if (borrower[borrowerSeperator].ownedRentedYears === '1') {
    visibleFields.push('formerAddress')
    visibleFields.push('formerOwnership')
    visibleFields.push('formerOwnedRentedYears')
  }

  return removeInvisibleFields(FieldOrigin.BorrowerInformation, visibleFields)
}

export const borrowerInfoValidate = (
  borrowerSeperator: string,
  visibles: any,
  fields: any,
  validateOnly = false,
  updateStore = false,
  afterRemoved = false,
) => {
  try {
    const { borrower, step, globalConfigReducer } = store.getState()
    const loanFieldProperties = globalConfigReducer[GlobalConfigType.LoanFields] as LoanFieldProps
    if (((loanFieldProperties as any)['visibility'] || {})[FieldOrigin.BorrowerInformation] === false) {
      if (validateOnly) return true
      else return {}
    }
    if (validateOnly) {
      visibles = visibleBorrowerFieldsLogic(borrowerSeperator)
      fields = defaultInputs()
    }
    const fieldProps = loanFieldProperties[FieldOrigin.BorrowerInformation]

    let success = true
    fields = cloneDeep(fields)
    Object.keys(fields).map((key) => {
      if (visibles.indexOf(key) !== -1) {
        const error = InputValidate({ ...fields[key], value: borrower[borrowerSeperator][key] })
        fields[key].error = error
        if (error.length > 0) {
          success = false
        }
        if (fieldProps && fieldProps[key] && fieldProps[key].tooltip) fields[key].tooltip = fieldProps[key].tooltip
      }
    })

    if (borrowerSeperator === 'borrower') {
      if (borrower[borrowerSeperator].hasEntityTitle === true) {
        if (borrower[borrowerSeperator].borrowerType === 'Individual') {
          fields['borrowerType'].error = `Can not be 'Individual' when Has Entity Name.`
          success = false
        }
      }
      disableKeys.map((key) => {
        fields[key].disabled = true
        fields[key].tooltip = ['Loan Structure Input Field!', fields[key].tooltip].filter((v) => !!v).join('\n\n')
      })
    }

    if (afterRemoved) success = false
    if (updateStore) {
      let application = cloneDeep(step.application)
      const key = stepApplicationKeys[borrowerSeperator].borrower
      application[key] = success ? 1 : -1
      store.dispatch(setApplicationStep(application))
    }
    if (validateOnly) return success

    console.log('Before Finish', fields['titleVesting'].value)
    return fields
  } catch (e) {}
}
